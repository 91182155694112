
export const DEFAULT_SCORE = 0;
export const DEFAULT_EVALUATION_TEXT = "Your CV will be evaluated shortly.";

export const JOB_TITLES_EN: string[] = [
  "Software Engineer",
  "Data Scientist",
  "Product Manager",
  "Graphic Designer",
  "Sales Manager",
  "Financial Analyst",
  "Human Resources Manager",
  "Marketing Specialist",
  "Civil Engineer",
  "Nurse Practitioner",
  "Doctor",
  "Project Manager",
  "Quality Assurance Engineer",
  "System Administrator",
  "Network Engineer",
  "Mechanical Engineer",
  "Electrical Engineer",
  "Database Administrator",
  "Business Analyst",
  "Operations Manager",
  "Web Developer",
  "UX/UI Designer",
  "Cybersecurity Analyst",
  "DevOps Engineer",
  "Content Writer",
  "SEO Specialist",
  "Chief Executive Officer (CEO)",
  "Chief Financial Officer (CFO)",
  "Legal Counsel",
  "Supply Chain Manager",
  "Pharmacist",
  "Software Developer",
  "Machine Learning Engineer",
  "Product Owner",
  "Art Director",
  "Account Manager",
  "Investment Banker",
  "Recruitment Specialist",
  "Digital Marketing Manager",
  "Structural Engineer",
  "Physician Assistant",
  "Dentist",
  "Scrum Master",
  "Test Automation Engineer",
  "IT Support Specialist",
  "Cloud Engineer",
  "Manufacturing Engineer",
  "Biomedical Engineer",
  "Data Engineer",
  "Business Development Manager",
  "Operations Coordinator",
  "Front-End Developer",
  "Visual Designer",
  "Information Security Analyst",
  "Site Reliability Engineer",
  "Technical Writer",
  "Social Media Manager",
  "Chief Operating Officer (COO)",
  "Chief Technology Officer (CTO)",
  "Compliance Officer",
  "Logistics Coordinator",
  "Registered Nurse",
  "Architect",
  "Game Developer",
  "Environmental Engineer",
  "Data Architect",
  "Product Marketing Manager",
  "Supply Chain Analyst",
  "Human Resources Specialist",
  "Public Relations Manager",
  "Technical Support Engineer",
  "Mobile Developer",
  "Speech-Language Pathologist",
  "Chiropractor",
  "Clinical Research Coordinator",
  "Medical Laboratory Technician",
  "Environmental Scientist",
  "Economist",
  "Real Estate Agent",
  "UX Researcher",
  "Software Architect",
  "Customer Service Manager",
  "AI Engineer",
  "Marketing Analyst",
  "Biostatistician",
  "Chemical Engineer",
  "Industrial Designer",
  "Accountant",
  "Research Scientist",
  "Patent Attorney",
  "Orthodontist",
  "Laboratory Manager",
  "Junior Software Engineer",
  "Senior Software Engineer",
  "Lead Software Engineer",
  "Junior Data Scientist",
  "Senior Data Scientist",
  "Lead Data Scientist",
  "Junior Product Manager",
  "Senior Product Manager",
  "Lead Product Manager",
  "Junior Graphic Designer",
  "Senior Graphic Designer",
  "Lead Graphic Designer",
  "Junior Sales Manager",
  "Senior Sales Manager",
  "Lead Sales Manager",
  "Junior Financial Analyst",
  "Senior Financial Analyst",
  "Lead Financial Analyst",
  "Junior Human Resources Manager",
  "Senior Human Resources Manager",
  "Lead Human Resources Manager",
  "Junior Marketing Specialist",
  "Senior Marketing Specialist",
  "Lead Marketing Specialist",
  "Junior Civil Engineer",
  "Senior Civil Engineer",
  "Lead Civil Engineer",
  "Junior Nurse Practitioner",
  "Senior Nurse Practitioner",
  "Lead Nurse Practitioner",
  "Junior Doctor",
  "Senior Doctor",
  "Lead Doctor",
  "Junior Project Manager",
  "Senior Project Manager",
  "Lead Project Manager",
  "Junior Quality Assurance Engineer",
  "Senior Quality Assurance Engineer",
  "Lead Quality Assurance Engineer",
  "Junior System Administrator",
  "Senior System Administrator",
  "Lead System Administrator",
  "Junior Network Engineer",
  "Senior Network Engineer",
  "Lead Network Engineer",
  "Junior Mechanical Engineer",
  "Senior Mechanical Engineer",
  "Lead Mechanical Engineer",
  "Junior Electrical Engineer",
  "Senior Electrical Engineer",
  "Lead Electrical Engineer",
  "Junior Database Administrator",
  "Senior Database Administrator",
  "Lead Database Administrator",
  "Junior Business Analyst",
  "Senior Business Analyst",
  "Lead Business Analyst",
  "Junior Operations Manager",
  "Senior Operations Manager",
  "Lead Operations Manager",
  "Junior Web Developer",
  "Senior Web Developer",
  "Lead Web Developer",
  "Junior UX/UI Designer",
  "Senior UX/UI Designer",
  "Lead UX/UI Designer",
  "Junior Cybersecurity Analyst",
  "Senior Cybersecurity Analyst",
  "Lead Cybersecurity Analyst",
  "Junior DevOps Engineer",
  "Senior DevOps Engineer",
  "Lead DevOps Engineer",
  "Junior Content Writer",
  "Senior Content Writer",
  "Lead Content Writer",
  "Junior SEO Specialist",
  "Senior SEO Specialist",
  "Lead SEO Specialist",
  "Junior Software Developer",
  "Senior Software Developer",
  "Lead Software Developer",
  "Junior Machine Learning Engineer",
  "Senior Machine Learning Engineer",
  "Lead Machine Learning Engineer",
  "Junior Product Owner",
  "Senior Product Owner",
  "Lead Product Owner",
  "Junior Art Director",
  "Senior Art Director",
  "Lead Art Director",
  "Junior Account Manager",
  "Senior Account Manager",
  "Lead Account Manager",
  "Junior Investment Banker",
  "Senior Investment Banker",
  "Lead Investment Banker",
  "Junior Recruitment Specialist",
  "Senior Recruitment Specialist",
  "Lead Recruitment Specialist",
  "Junior Digital Marketing Manager",
  "Senior Digital Marketing Manager",
  "Lead Digital Marketing Manager",
  "Junior Structural Engineer",
  "Senior Structural Engineer",
  "Lead Structural Engineer",
  "Junior Physician Assistant",
  "Senior Physician Assistant",
  "Lead Physician Assistant",
  "Junior Dentist",
  "Senior Dentist",
  "Lead Dentist",
  "Junior Scrum Master",
  "Senior Scrum Master",
  "Lead Scrum Master",
  "Junior Test Automation Engineer",
  "Senior Test Automation Engineer",
  "Lead Test Automation Engineer",
  "Junior IT Support Specialist",
  "Senior IT Support Specialist",
  "Lead IT Support Specialist",
  "Junior Cloud Engineer",
  "Senior Cloud Engineer",
  "Lead Cloud Engineer",
  "Junior Manufacturing Engineer",
  "Senior Manufacturing Engineer",
  "Lead Manufacturing Engineer",
  "Junior Biomedical Engineer",
  "Senior Biomedical Engineer",
  "Lead Biomedical Engineer",
  "Junior Data Engineer",
  "Senior Data Engineer",
  "Lead Data Engineer",
  "Junior Business Development Manager",
  "Senior Business Development Manager",
  "Lead Business Development Manager",
  "Junior Operations Coordinator",
  "Senior Operations Coordinator",
  "Lead Operations Coordinator",
  "Junior Front-End Developer",
  "Senior Front-End Developer",
  "Lead Front-End Developer",
  "Junior Visual Designer",
  "Senior Visual Designer",
  "Lead Visual Designer",
  "Junior Information Security Analyst",
  "Senior Information Security Analyst",
  "Lead Information Security Analyst",
  "Junior Site Reliability Engineer",
  "Senior Site Reliability Engineer",
  "Lead Site Reliability Engineer",
  "Junior Technical Writer",
  "Senior Technical Writer",
  "Lead Technical Writer",
  "Junior Social Media Manager",
  "Senior Social Media Manager",
  "Lead Social Media Manager",
  "Junior Compliance Officer",
  "Senior Compliance Officer",
  "Lead Compliance Officer",
  "Junior Logistics Coordinator",
  "Senior Logistics Coordinator",
  "Lead Logistics Coordinator",
  "Junior Registered Nurse",
  "Senior Registered Nurse",
  "Lead Registered Nurse",
  "Junior Architect",
  "Senior Architect",
  "Lead Architect",
  "Junior Game Developer",
  "Senior Game Developer",
  "Lead Game Developer",
  "Junior Environmental Engineer",
  "Senior Environmental Engineer",
  "Lead Environmental Engineer",
  "Junior Data Architect",
  "Senior Data Architect",
  "Lead Data Architect",
  "Junior Product Marketing Manager",
  "Senior Product Marketing Manager",
  "Lead Product Marketing Manager",
  "Junior Supply Chain Analyst",
  "Senior Supply Chain Analyst",
  "Lead Supply Chain Analyst",
  "Junior Human Resources Specialist",
  "Senior Human Resources Specialist",
  "Lead Human Resources Specialist",
  "Junior Public Relations Manager",
  "Senior Public Relations Manager",
  "Lead Public Relations Manager",
  "Junior Technical Support Engineer",
  "Senior Technical Support Engineer",
  "Lead Technical Support Engineer",
  "Junior Mobile Developer",
  "Senior Mobile Developer",
  "Lead Mobile Developer",
  "Junior UX Researcher",
  "Senior UX Researcher",
  "Lead UX Researcher",
  "Junior Software Architect",
  "Senior Software Architect",
  "Lead Software Architect",
  "Junior Customer Service Manager",
  "Senior Customer Service Manager",
  "Lead Customer Service Manager",
  "Junior AI Engineer",
  "Senior AI Engineer",
  "Lead AI Engineer",
  "Junior Marketing Analyst",
  "Senior Marketing Analyst",
  "Lead Marketing Analyst",
  "Junior Biostatistician",
  "Senior Biostatistician",
  "Lead Biostatistician",
  "Junior Chemical Engineer",
  "Senior Chemical Engineer",
  "Lead Chemical Engineer",
  "Junior Industrial Designer",
  "Senior Industrial Designer",
  "Lead Industrial Designer",
  "Junior Accountant",
  "Senior Accountant",
  "Lead Accountant",
  "Junior Research Scientist",
  "Senior Research Scientist",
  "Lead Research Scientist",
  "Junior Patent Attorney",
  "Senior Patent Attorney",
  "Lead Patent Attorney",
  "Junior Orthodontist",
  "Senior Orthodontist",
  "Lead Orthodontist",
  "Junior Laboratory Manager",
  "Senior Laboratory Manager",
  "Lead Laboratory Manager",
].sort();

export const JOB_TITLES_TR: string[] = [
  "Stajyer Yazılım Mühendisi",
  "Yazılım Mühendisi",
  "Kıdemli Yazılım Mühendisi",
  "Baş Yazılım Mühendisi",
  "Stajyer Veri Bilimcisi",
  "Veri Bilimcisi",
  "Kıdemli Veri Bilimcisi",
  "Baş Veri Bilimcisi",
  "Stajyer Ürün Yöneticisi",
  "Ürün Yöneticisi",
  "Kıdemli Ürün Yöneticisi",
  "Baş Ürün Yöneticisi",
  "Stajyer Grafik Tasarımcı",
  "Grafik Tasarımcı",
  "Kıdemli Grafik Tasarımcı",
  "Baş Grafik Tasarımcı",
  "Stajyer Satış Müdürü",
  "Satış Müdürü",
  "Kıdemli Satış Müdürü",
  "Baş Satış Müdürü",
  "Stajyer Finansal Analist",
  "Finansal Analist",
  "Kıdemli Finansal Analist",
  "Baş Finansal Analist",
  "Stajyer İnsan Kaynakları Müdürü",
  "İnsan Kaynakları Müdürü",
  "Kıdemli İnsan Kaynakları Müdürü",
  "Baş İnsan Kaynakları Müdürü",
  "Stajyer Pazarlama Uzmanı",
  "Pazarlama Uzmanı",
  "Kıdemli Pazarlama Uzmanı",
  "Baş Pazarlama Uzmanı",
  "Stajyer İnşaat Mühendisi",
  "İnşaat Mühendisi",
  "Kıdemli İnşaat Mühendisi",
  "Baş İnşaat Mühendisi",
  "Stajyer Hemşire Uygulayıcı",
  "Hemşire Uygulayıcı",
  "Kıdemli Hemşire Uygulayıcı",
  "Baş Hemşire Uygulayıcı",
  "Stajyer Doktor",
  "Doktor",
  "Kıdemli Doktor",
  "Baş Doktor",
  "Stajyer Proje Yöneticisi",
  "Proje Yöneticisi",
  "Kıdemli Proje Yöneticisi",
  "Baş Proje Yöneticisi",
  "Stajyer Kalite Güvence Mühendisi",
  "Kalite Güvence Mühendisi",
  "Kıdemli Kalite Güvence Mühendisi",
  "Baş Kalite Güvence Mühendisi",
  "Stajyer Sistem Yöneticisi",
  "Sistem Yöneticisi",
  "Kıdemli Sistem Yöneticisi",
  "Baş Sistem Yöneticisi",
  "Stajyer Ağ Mühendisi",
  "Ağ Mühendisi",
  "Kıdemli Ağ Mühendisi",
  "Baş Ağ Mühendisi",
  "Stajyer Makine Mühendisi",
  "Makine Mühendisi",
  "Kıdemli Makine Mühendisi",
  "Baş Makine Mühendisi",
  "Stajyer Elektrik Mühendisi",
  "Elektrik Mühendisi",
  "Kıdemli Elektrik Mühendisi",
  "Baş Elektrik Mühendisi",
  "Stajyer Veritabanı Yöneticisi",
  "Veritabanı Yöneticisi",
  "Kıdemli Veritabanı Yöneticisi",
  "Baş Veritabanı Yöneticisi",
  "Stajyer İş Analisti",
  "İş Analisti",
  "Kıdemli İş Analisti",
  "Baş İş Analisti",
  "Stajyer Operasyon Yöneticisi",
  "Operasyon Yöneticisi",
  "Kıdemli Operasyon Yöneticisi",
  "Baş Operasyon Yöneticisi",
  "Stajyer Web Geliştirici",
  "Web Geliştirici",
  "Kıdemli Web Geliştirici",
  "Baş Web Geliştirici",
  "Stajyer UX/UI Tasarımcısı",
  "UX/UI Tasarımcısı",
  "Kıdemli UX/UI Tasarımcısı",
  "Baş UX/UI Tasarımcısı",
  "Stajyer Siber Güvenlik Analisti",
  "Siber Güvenlik Analisti",
  "Kıdemli Siber Güvenlik Analisti",
  "Baş Siber Güvenlik Analisti",
  "Stajyer DevOps Mühendisi",
  "DevOps Mühendisi",
  "Kıdemli DevOps Mühendisi",
  "Baş DevOps Mühendisi",
  "Stajyer İçerik Yazarı",
  "İçerik Yazarı",
  "Kıdemli İçerik Yazarı",
  "Baş İçerik Yazarı",
  "Stajyer SEO Uzmanı",
  "SEO Uzmanı",
  "Kıdemli SEO Uzmanı",
  "Baş SEO Uzmanı",
  "Stajyer Yazılım Geliştirici",
  "Yazılım Geliştirici",
  "Kıdemli Yazılım Geliştirici",
  "Baş Yazılım Geliştirici",
  "Stajyer Makine Öğrenmesi Mühendisi",
  "Makine Öğrenmesi Mühendisi",
  "Kıdemli Makine Öğrenmesi Mühendisi",
  "Baş Makine Öğrenmesi Mühendisi",
  "Stajyer Ürün Sahibi",
  "Ürün Sahibi",
  "Kıdemli Ürün Sahibi",
  "Baş Ürün Sahibi",
  "Stajyer Sanat Yönetmeni",
  "Sanat Yönetmeni",
  "Kıdemli Sanat Yönetmeni",
  "Baş Sanat Yönetmeni",
  "Stajyer Hesap Yöneticisi",
  "Hesap Yöneticisi",
  "Kıdemli Hesap Yöneticisi",
  "Baş Hesap Yöneticisi",
  "Stajyer Yatırım Bankacısı",
  "Yatırım Bankacısı",
  "Kıdemli Yatırım Bankacısı",
  "Baş Yatırım Bankacısı",
  "Stajyer İşe Alım Uzmanı",
  "İşe Alım Uzmanı",
  "Kıdemli İşe Alım Uzmanı",
  "Baş İşe Alım Uzmanı",
  "Stajyer Dijital Pazarlama Müdürü",
  "Dijital Pazarlama Müdürü",
  "Kıdemli Dijital Pazarlama Müdürü",
  "Baş Dijital Pazarlama Müdürü",
  "Stajyer Yapı Mühendisi",
  "Yapı Mühendisi",
  "Kıdemli Yapı Mühendisi",
  "Baş Yapı Mühendisi",
  "Stajyer Hekim Asistanı",
  "Hekim Asistanı",
  "Kıdemli Hekim Asistanı",
  "Baş Hekim Asistanı",
  "Stajyer Diş Hekimi",
  "Diş Hekimi",
  "Kıdemli Diş Hekimi",
  "Baş Diş Hekimi",
  "Stajyer Scrum Master",
  "Scrum Master",
  "Kıdemli Scrum Master",
  "Baş Scrum Master",
  "Stajyer Test Otomasyon Mühendisi",
  "Test Otomasyon Mühendisi",
  "Kıdemli Test Otomasyon Mühendisi",
  "Baş Test Otomasyon Mühendisi",
  "Stajyer BT Destek Uzmanı",
  "BT Destek Uzmanı",
  "Kıdemli BT Destek Uzmanı",
  "Baş BT Destek Uzmanı",
  "Stajyer Bulut Mühendisi",
  "Bulut Mühendisi",
  "Kıdemli Bulut Mühendisi",
  "Baş Bulut Mühendisi",
  "Stajyer Üretim Mühendisi",
  "Üretim Mühendisi",
  "Kıdemli Üretim Mühendisi",
  "Baş Üretim Mühendisi",
  "Stajyer Biyomedikal Mühendisi",
  "Biyomedikal Mühendisi",
  "Kıdemli Biyomedikal Mühendisi",
  "Baş Biyomedikal Mühendisi",
  "Stajyer Veri Mühendisi",
  "Veri Mühendisi",
  "Kıdemli Veri Mühendisi",
  "Baş Veri Mühendisi",
  "Stajyer İş Geliştirme Müdürü",
  "İş Geliştirme Müdürü",
  "Kıdemli İş Geliştirme Müdürü",
  "Baş İş Geliştirme Müdürü",
  "Stajyer Operasyon Koordinatörü",
  "Operasyon Koordinatörü",
  "Kıdemli Operasyon Koordinatörü",
  "Baş Operasyon Koordinatörü",
  "Stajyer Ön Uç Geliştirici",
  "Ön Uç Geliştirici",
  "Kıdemli Ön Uç Geliştirici",
  "Baş Ön Uç Geliştirici",
  "Stajyer Görsel Tasarımcı",
  "Görsel Tasarımcı",
  "Kıdemli Görsel Tasarımcı",
  "Baş Görsel Tasarımcı",
  "Stajyer Bilgi Güvenliği Analisti",
  "Bilgi Güvenliği Analisti",
  "Kıdemli Bilgi Güvenliği Analisti",
  "Baş Bilgi Güvenliği Analisti",
  "Stajyer Site Güvenilirlik Mühendisi",
  "Site Güvenilirlik Mühendisi",
  "Kıdemli Site Güvenilirlik Mühendisi",
  "Baş Site Güvenilirlik Mühendisi",
  "Stajyer Teknik Yazar",
  "Teknik Yazar",
  "Kıdemli Teknik Yazar",
  "Baş Teknik Yazar",
  "Stajyer Sosyal Medya Müdürü",
  "Sosyal Medya Müdürü",
  "Kıdemli Sosyal Medya Müdürü",
  "Baş Sosyal Medya Müdürü",
  "Stajyer Uyum Görevlisi",
  "Uyum Görevlisi",
  "Kıdemli Uyum Görevlisi",
  "Baş Uyum Görevlisi",
  "Stajyer Lojistik Koordinatörü",
  "Lojistik Koordinatörü",
  "Kıdemli Lojistik Koordinatörü",
  "Baş Lojistik Koordinatörü",
  "Stajyer Kayıtlı Hemşire",
  "Kayıtlı Hemşire",
  "Kıdemli Kayıtlı Hemşire",
  "Baş Kayıtlı Hemşire",
  "Stajyer Mimar",
  "Mimar",
  "Kıdemli Mimar",
  "Baş Mimar",
  "Stajyer Oyun Geliştirici",
  "Oyun Geliştirici",
  "Kıdemli Oyun Geliştirici",
  "Baş Oyun Geliştirici",
  "Stajyer Çevre Mühendisi",
  "Çevre Mühendisi",
  "Kıdemli Çevre Mühendisi",
  "Baş Çevre Mühendisi",
  "Stajyer Veri Mimarı",
  "Veri Mimarı",
  "Kıdemli Veri Mimarı",
  "Baş Veri Mimarı",
  "Stajyer Ürün Pazarlama Müdürü",
  "Ürün Pazarlama Müdürü",
  "Kıdemli Ürün Pazarlama Müdürü",
  "Baş Ürün Pazarlama Müdürü",
  "Stajyer Tedarik Zinciri Analisti",
  "Tedarik Zinciri Analisti",
  "Kıdemli Tedarik Zinciri Analisti",
  "Baş Tedarik Zinciri Analisti",
  "Stajyer İnsan Kaynakları Uzmanı",
  "İnsan Kaynakları Uzmanı",
  "Kıdemli İnsan Kaynakları Uzmanı",
  "Baş İnsan Kaynakları Uzmanı",
  "Stajyer Halkla İlişkiler Müdürü",
  "Halkla İlişkiler Müdürü",
  "Kıdemli Halkla İlişkiler Müdürü",
  "Baş Halkla İlişkiler Müdürü",
  "Stajyer Teknik Destek Mühendisi",
  "Teknik Destek Mühendisi",
  "Kıdemli Teknik Destek Mühendisi",
  "Baş Teknik Destek Mühendisi",
  "Stajyer Mobil Geliştirici",
  "Mobil Geliştirici",
  "Kıdemli Mobil Geliştirici",
  "Baş Mobil Geliştirici",
  "Stajyer UX Araştırmacısı",
  "UX Araştırmacısı",
  "Kıdemli UX Araştırmacısı",
  "Baş UX Araştırmacısı",
  "Stajyer Yazılım Mimarı",
  "Yazılım Mimarı",
  "Kıdemli Yazılım Mimarı",
  "Baş Yazılım Mimarı",
  "Stajyer Müşteri Hizmetleri Müdürü",
  "Müşteri Hizmetleri Müdürü",
  "Kıdemli Müşteri Hizmetleri Müdürü",
  "Baş Müşteri Hizmetleri Müdürü",
  "Stajyer Yapay Zeka Mühendisi",
  "Yapay Zeka Mühendisi",
  "Kıdemli Yapay Zeka Mühendisi",
  "Baş Yapay Zeka Mühendisi",
  "Stajyer Pazarlama Analisti",
  "Pazarlama Analisti",
  "Kıdemli Pazarlama Analisti",
  "Baş Pazarlama Analisti",
  "Stajyer Biyostatistikçi",
  "Biyostatistikçi",
  "Kıdemli Biyostatistikçi",
  "Baş Biyostatistikçi",
  "Stajyer Kimya Mühendisi",
  "Kimya Mühendisi",
  "Kıdemli Kimya Mühendisi",
  "Baş Kimya Mühendisi",
  "Stajyer Endüstriyel Tasarımcı",
  "Endüstriyel Tasarımcı",
  "Kıdemli Endüstriyel Tasarımcı",
  "Baş Endüstriyel Tasarımcı",
  "Stajyer Muhasebeci",
  "Muhasebeci",
  "Kıdemli Muhasebeci",
  "Baş Muhasebeci",
  "Stajyer Araştırma Bilimcisi",
  "Araştırma Bilimcisi",
  "Kıdemli Araştırma Bilimcisi",
  "Baş Araştırma Bilimcisi",
  "Stajyer Patent Avukatı",
  "Patent Avukatı",
  "Kıdemli Patent Avukatı",
  "Baş Patent Avukatı",
  "Stajyer Ortodontist",
  "Ortodontist",
  "Kıdemli Ortodontist",
  "Baş Ortodontist",
  "Stajyer Laboratuvar Müdürü",
  "Laboratuvar Müdürü",
  "Kıdemli Laboratuvar Müdürü",
  "Baş Laboratuvar Müdürü",
].sort();

export const JOB_TITLES_DE: string[] = [
  "Junior Software-Ingenieur",
  "Software-Ingenieur",
  "Senior Software-Ingenieur",
  "Leitender Software-Ingenieur",
  "Junior Datenwissenschaftler",
  "Datenwissenschaftler",
  "Senior Datenwissenschaftler",
  "Leitender Datenwissenschaftler",
  "Junior Produktmanager",
  "Produktmanager",
  "Senior Produktmanager",
  "Leitender Produktmanager",
  "Junior Grafikdesigner",
  "Grafikdesigner",
  "Senior Grafikdesigner",
  "Leitender Grafikdesigner",
  "Junior Vertriebsleiter",
  "Vertriebsleiter",
  "Senior Vertriebsleiter",
  "Leitender Vertriebsleiter",
  "Junior Finanzanalyst",
  "Finanzanalyst",
  "Senior Finanzanalyst",
  "Leitender Finanzanalyst",
  "Junior Personalmanager",
  "Personalmanager",
  "Senior Personalmanager",
  "Leitender Personalmanager",
  "Junior Marketing-Spezialist",
  "Marketing-Spezialist",
  "Senior Marketing-Spezialist",
  "Leitender Marketing-Spezialist",
  "Junior Bauingenieur",
  "Bauingenieur",
  "Senior Bauingenieur",
  "Leitender Bauingenieur",
  "Junior Fachkrankenpfleger",
  "Fachkrankenpfleger",
  "Senior Fachkrankenpfleger",
  "Leitender Fachkrankenpfleger",
  "Junior Arzt",
  "Arzt",
  "Senior Arzt",
  "Leitender Arzt",
  "Junior Projektmanager",
  "Projektmanager",
  "Senior Projektmanager",
  "Leitender Projektmanager",
  "Junior Qualitätssicherungsingenieur",
  "Qualitätssicherungsingenieur",
  "Senior Qualitätssicherungsingenieur",
  "Leitender Qualitätssicherungsingenieur",
  "Junior Systemadministrator",
  "Systemadministrator",
  "Senior Systemadministrator",
  "Leitender Systemadministrator",
  "Junior Netzwerkingenieur",
  "Netzwerkingenieur",
  "Senior Netzwerkingenieur",
  "Leitender Netzwerkingenieur",
  "Junior Maschinenbauingenieur",
  "Maschinenbauingenieur",
  "Senior Maschinenbauingenieur",
  "Leitender Maschinenbauingenieur",
  "Junior Elektroingenieur",
  "Elektroingenieur",
  "Senior Elektroingenieur",
  "Leitender Elektroingenieur",
  "Junior Datenbankadministrator",
  "Datenbankadministrator",
  "Senior Datenbankadministrator",
  "Leitender Datenbankadministrator",
  "Junior Business-Analyst",
  "Business-Analyst",
  "Senior Business-Analyst",
  "Leitender Business-Analyst",
  "Junior Betriebsleiter",
  "Betriebsleiter",
  "Senior Betriebsleiter",
  "Leitender Betriebsleiter",
  "Junior Webentwickler",
  "Webentwickler",
  "Senior Webentwickler",
  "Leitender Webentwickler",
  "Junior UX/UI-Designer",
  "UX/UI-Designer",
  "Senior UX/UI-Designer",
  "Leitender UX/UI-Designer",
  "Junior Cybersicherheitsanalyst",
  "Cybersicherheitsanalyst",
  "Senior Cybersicherheitsanalyst",
  "Leitender Cybersicherheitsanalyst",
  "Junior DevOps-Ingenieur",
  "DevOps-Ingenieur",
  "Senior DevOps-Ingenieur",
  "Leitender DevOps-Ingenieur",
  "Junior Content Writer",
  "Content Writer",
  "Senior Content Writer",
  "Leitender Content Writer",
  "Junior SEO-Spezialist",
  "SEO-Spezialist",
  "Senior SEO-Spezialist",
  "Leitender SEO-Spezialist",
  "Junior Softwareentwickler",
  "Softwareentwickler",
  "Senior Softwareentwickler",
  "Leitender Softwareentwickler",
  "Junior Maschinelles Lernen Ingenieur",
  "Maschinelles Lernen Ingenieur",
  "Senior Maschinelles Lernen Ingenieur",
  "Leitender Maschinelles Lernen Ingenieur",
  "Junior Produktinhaber",
  "Produktinhaber",
  "Senior Produktinhaber",
  "Leitender Produktinhaber",
  "Junior Art Director",
  "Art Director",
  "Senior Art Director",
  "Leitender Art Director",
  "Junior Kundenbetreuer",
  "Kundenbetreuer",
  "Senior Kundenbetreuer",
  "Leitender Kundenbetreuer",
  "Junior Investmentbanker",
  "Investmentbanker",
  "Senior Investmentbanker",
  "Leitender Investmentbanker",
  "Junior Rekrutierungsspezialist",
  "Rekrutierungsspezialist",
  "Senior Rekrutierungsspezialist",
  "Leitender Rekrutierungsspezialist",
  "Junior Digital Marketing Manager",
  "Digital Marketing Manager",
  "Senior Digital Marketing Manager",
  "Leitender Digital Marketing Manager",
  "Junior Bauingenieur",
  "Bauingenieur",
  "Senior Bauingenieur",
  "Leitender Bauingenieur",
  "Junior Arzthelfer",
  "Arzthelfer",
  "Senior Arzthelfer",
  "Leitender Arzthelfer",
  "Junior Zahnarzt",
  "Zahnarzt",
  "Senior Zahnarzt",
  "Leitender Zahnarzt",
  "Junior Scrum Master",
  "Scrum Master",
  "Senior Scrum Master",
  "Leitender Scrum Master",
  "Junior Testautomatisierungsingenieur",
  "Testautomatisierungsingenieur",
  "Senior Testautomatisierungsingenieur",
  "Leitender Testautomatisierungsingenieur",
  "Junior IT-Support-Spezialist",
  "IT-Support-Spezialist",
  "Senior IT-Support-Spezialist",
  "Leitender IT-Support-Spezialist",
  "Junior Cloud-Ingenieur",
  "Cloud-Ingenieur",
  "Senior Cloud-Ingenieur",
  "Leitender Cloud-Ingenieur",
  "Junior Fertigungsingenieur",
  "Fertigungsingenieur",
  "Senior Fertigungsingenieur",
  "Leitender Fertigungsingenieur",
  "Junior Biomedizinischer Ingenieur",
  "Biomedizinischer Ingenieur",
  "Senior Biomedizinischer Ingenieur",
  "Leitender Biomedizinischer Ingenieur",
  "Junior Data Engineer",
  "Data Engineer",
  "Senior Data Engineer",
  "Leitender Data Engineer",
  "Junior Business Development Manager",
  "Business Development Manager",
  "Senior Business Development Manager",
  "Leitender Business Development Manager",
  "Junior Operationskoordinator",
  "Operationskoordinator",
  "Senior Operationskoordinator",
  "Leitender Operationskoordinator",
  "Junior Front-End-Entwickler",
  "Front-End-Entwickler",
  "Senior Front-End-Entwickler",
  "Leitender Front-End-Entwickler",
  "Junior Visueller Designer",
  "Visueller Designer",
  "Senior Visueller Designer",
  "Leitender Visueller Designer",
  "Junior Informationssicherheitsanalyst",
  "Informationssicherheitsanalyst",
  "Senior Informationssicherheitsanalyst",
  "Leitender Informationssicherheitsanalyst",
  "Junior Site Reliability Engineer",
  "Site Reliability Engineer",
  "Senior Site Reliability Engineer",
  "Leitender Site Reliability Engineer",
  "Junior Technischer Redakteur",
  "Technischer Redakteur",
  "Senior Technischer Redakteur",
  "Leitender Technischer Redakteur",
  "Junior Social Media Manager",
  "Social Media Manager",
  "Senior Social Media Manager",
  "Leitender Social Media Manager",
  "Junior Compliance Officer",
  "Compliance Officer",
  "Senior Compliance Officer",
  "Leitender Compliance Officer",
  "Junior Logistik-Koordinator",
  "Logistik-Koordinator",
  "Senior Logistik-Koordinator",
  "Leitender Logistik-Koordinator",
  "Junior Krankenpfleger",
  "Krankenpfleger",
  "Senior Krankenpfleger",
  "Leitender Krankenpfleger",
  "Junior Architekt",
  "Architekt",
  "Senior Architekt",
  "Leitender Architekt",
  "Junior Spieleentwickler",
  "Spieleentwickler",
  "Senior Spieleentwickler",
  "Leitender Spieleentwickler",
  "Junior Umweltingenieur",
  "Umweltingenieur",
  "Senior Umweltingenieur",
  "Leitender Umweltingenieur",
  "Junior Data Architect",
  "Data Architect",
  "Senior Data Architect",
  "Leitender Data Architect",
  "Junior Produktmarketing-Manager",
  "Produktmarketing-Manager",
  "Senior Produktmarketing-Manager",
  "Leitender Produktmarketing-Manager",
  "Junior Supply Chain Analyst",
  "Supply Chain Analyst",
  "Senior Supply Chain Analyst",
  "Leitender Supply Chain Analyst",
  "Junior Personalwesen Spezialist",
  "Personalwesen Spezialist",
  "Senior Personalwesen Spezialist",
  "Leitender Personalwesen Spezialist",
  "Junior Öffentlichkeitsarbeit Manager",
  "Öffentlichkeitsarbeit Manager",
  "Senior Öffentlichkeitsarbeit Manager",
  "Leitender Öffentlichkeitsarbeit Manager",
  "Junior Technischer Support Ingenieur",
  "Technischer Support Ingenieur",
  "Senior Technischer Support Ingenieur",
  "Leitender Technischer Support Ingenieur",
  "Junior Mobilentwickler",
  "Mobilentwickler",
  "Senior Mobilentwickler",
  "Leitender Mobilentwickler",
  "Junior UX-Forscher",
  "UX-Forscher",
  "Senior UX-Forscher",
  "Leitender UX-Forscher",
  "Junior Software-Architekt",
  "Software-Architekt",
  "Senior Software-Architekt",
  "Leitender Software-Architekt",
  "Junior Kundenservice-Manager",
  "Kundenservice-Manager",
  "Senior Kundenservice-Manager",
  "Leitender Kundenservice-Manager",
  "Junior KI-Ingenieur",
  "KI-Ingenieur",
  "Senior KI-Ingenieur",
  "Leitender KI-Ingenieur",
  "Junior Marketing Analyst",
  "Marketing Analyst",
  "Senior Marketing Analyst",
  "Leitender Marketing Analyst",
  "Junior Biostatistiker",
  "Biostatistiker",
  "Senior Biostatistiker",
  "Leitender Biostatistiker",
  "Junior Chemieingenieur",
  "Chemieingenieur",
  "Senior Chemieingenieur",
  "Leitender Chemieingenieur",
  "Junior Industriedesigner",
  "Industriedesigner",
  "Senior Industriedesigner",
  "Leitender Industriedesigner",
  "Junior Buchhalter",
  "Buchhalter",
  "Senior Buchhalter",
  "Leitender Buchhalter",
  "Junior Forschung Wissenschaftler",
  "Forschung Wissenschaftler",
  "Senior Forschung Wissenschaftler",
  "Leitender Forschung Wissenschaftler",
  "Junior Patentanwalt",
  "Patentanwalt",
  "Senior Patentanwalt",
  "Leitender Patentanwalt",
  "Junior Kieferorthopäde",
  "Kieferorthopäde",
  "Senior Kieferorthopäde",
  "Leitender Kieferorthopäde",
  "Junior Laborleiter",
  "Laborleiter",
  "Senior Laborleiter",
  "Leitender Laborleiter",
].sort();
