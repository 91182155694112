<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t, locale } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 730, end: rectForFirst.y + 860 },
      { start: rectForFirst.y + 1550, end: rectForFirst.y + 1690 },
      { start: rectForFirst.y + 2400, end: rectForFirst.y + 2520 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height625px = 625;
    const height1250px = 1250;
    const height1875px = 1875;
    const height2500px = 2500;

    if (contentHeight <= height625px) {
      leftSection.style.minHeight = "625px";
    } else if (contentHeight > height625px && contentHeight <= height1250px) {
      leftSection.style.minHeight = "1250px";
    } else if (contentHeight > height1250px && contentHeight <= height1875px) {
      leftSection.style.minHeight = "1875px";
    } else if (contentHeight > height1875px && contentHeight <= height2500px) {
      leftSection.style.minHeight = "2500px";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};

const handleResize = () => {
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
};

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

watch(() => cvData, () => {
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
  window.addEventListener("resize", handleResize);
  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ '--resume-color': cvData.resumeColor, fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="header">
        <div class="header-spans">
          <span class="job-title">
            {{ cvData.contact?.jobTitle?.toLocaleUpperCase(locale) }}
          </span>
          <span class="name">
            {{ cvData.contact?.firstName?.toLocaleUpperCase(locale) }}
          </span>
          <span class="name">
            {{ cvData.contact?.lastName?.toLocaleUpperCase(locale) }}
          </span>
        </div>
      </div>
      <div class="line"/>
      <div class="content">
        <div class="left-section">
          <div v-if="cvData.contact" class="single-section">
            <div class="single-section-caption">{{ t('Personal').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <div v-if="cvData.contact?.email" class="single-section-content-text"><i class="fas fa-envelope" style="padding-right: 3px;"></i> {{ cvData.contact?.email }}</div>
              <div v-if="cvData.contact?.phone" class="single-section-content-text"><i class="fas fa-phone" style="padding-right: 3px;"></i> {{ cvData.contact?.phone }}</div>
              <div v-if="cvData.contact?.address || cvData.contact?.country || cvData.contact?.city" class="single-section-content-text"><i class="fas fa-map-marker-alt" style="padding-right: 3px;"></i>
                {{ cvData.contact?.city  }}{{ cvData.contact.country && cvData.contact?.city ? ', ' : '' }}{{ cvData.contact.country }}{{ cvData.contact.country && cvData.contact?.address ? ', ' : '' }}{{ cvData.contact?.address }}
              </div>
              <div v-if="cvData.contact?.birthDate" class="single-section-content-text"><i class="fa fa-birthday-cake" style="padding-right: 3px;"></i> {{ cvData.contact?.birthDate }}</div>
            </div>
          </div>
          <div v-if="cvData.skills && cvData.skills.length > 0" class="line"/>
          <div v-if="cvData.skills && cvData.skills.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Skills').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="skill in cvData.skills" :key="skill.id" class="single-section-list">
                <li class="single-section-list-item">
                  <div class="single-section-list-item-name">
                    <div class="single-section-content-text">{{ skill.name }}</div>
                  </div>
                  <div v-if="skill.level && cvData.showSkillLevels" class="single-section-list-item-level">
                    <div class="single-section-content-text">{{ skill.level }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.languages && cvData.languages.length > 0" class="line"/>
          <div v-if="cvData.languages && cvData.languages.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Languages').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="language in cvData.languages" :key="language.id" class="single-section-list">
                <li class="single-section-list-item-vertical">
                  <div class="single-section-list-item-name">
                    <div class="single-section-content-text">{{ language.name }}</div>
                  </div>
                  <div  v-if="language.level && cvData.showLanguageLevels" class="single-section-list-item-level">
                    <div class="single-section-content-text">{{ language.level }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="line"/>
          <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Hobbies').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="hobby in cvData.hobbies" :key="hobby.id" class="single-section-list">
                <li class="single-section-list-item" v-html="hobby.description">
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.honors && cvData.honors.length > 0" class="line"/>
          <div v-if="cvData.honors && cvData.honors.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('HonorsAndAwards').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="honor in cvData.honors" :key="honor.id" class="single-section-list">
                <li class="single-section-list-item" v-html="honor.description">
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.publications && cvData.publications.length > 0" class="line"/>
          <div v-if="cvData.publications && cvData.publications.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Publications').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="publication in cvData.publications" :key="publication.id" class="single-section-list">
                <li class="single-section-list-item" v-html="publication.description">
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="line"/>
          <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Exhibitions').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="exhibition in cvData.exhibitions" :key="exhibition.id" class="single-section-list">
                <li class="single-section-list-item" v-html="exhibition.description">
                </li>
              </ul>
            </div>
          </div>
          <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="line"/>
          <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Voluntaries').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content">
              <ul v-for="voluntarie in cvData.voluntaries" :key="voluntarie.id" class="single-section-list">
                <li class="single-section-list-item" v-html="voluntarie.description">
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right-section">
          <div v-if="cvData.contact?.description?.length && containsTextOutsideHtml(cvData.contact?.description)" class="single-section-summary">
            <div class="single-section-caption">{{ t('cvCreationView.Summary').toLocaleUpperCase(locale) }}</div>
            <div class="single-section-content" v-html="cvData.contact?.description"/>
            <div class="line"/>
          </div>
          <div v-if="cvData.experiences && cvData.experiences.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Experience').toLocaleUpperCase(locale) }}</div>
            <div v-for="experience in cvData.experiences" :key="experience.id" class="single-section-content">
              <div v-if="experience.title" class="single-section-content-title">{{  experience.title?.toLocaleUpperCase(locale) }}</div>
              <div class="single-section-content-information">
                <div v-if="experience.company" class="single-section-content-information-text" style="font-weight: bold;">{{  experience.company }}</div>
                <div v-if="experience.startDate || experience.endDate" class="single-section-content-information-text">,&nbsp;</div>
                <div v-if="experience.startDate" class="single-section-content-information-text">{{  experience.startDate }}</div>
                <div v-if="experience.endDate && experience.startDate" class="single-section-content-information-text"> &nbsp;-&nbsp; </div>
                <div v-if="experience.endDate" class="single-section-content-information-text">{{ experience.endDate }}</div>
                <div v-if="experience.location" class="single-section-content-information-text">, &nbsp;{{ experience.location }}</div>
              </div>
              <div v-if="experience.description?.length && containsTextOutsideHtml(experience.description)" class="single-section-content-description" v-html="experience.description"/>
            </div>
          </div>
          <div v-if="cvData.educations && cvData.educations.length > 0" class="line"/>
          <div v-if="cvData.educations && cvData.educations.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Education').toLocaleUpperCase(locale) }}</div>
            <div v-for="education in cvData.educations" :key="education.id" class="single-section-content">
              <div v-if="education.school" class="single-section-content-title">{{  education.school?.toLocaleUpperCase(locale) }}</div>
              <div v-if="education.degree" class="single-section-content-information-text" style="font-weight: bold;">{{ education.degree }}</div>
              <div v-if="education.startDate || education.endDate || education.location" class="single-section-content-information">
                <div v-if="education.startDate" class="single-section-content-information-text">{{ education.startDate }}</div>
                <div v-if="education.startDate && education.endDate" class="single-section-content-information-text">&nbsp;-&nbsp; </div>
                <div v-if="education.endDate" class="single-section-content-information-text">{{ education.endDate }}</div>
                <div v-if="education.location" class="single-section-content-information-text">, {{ education.location }}</div>
              </div>
              <div v-if="education.description?.length && containsTextOutsideHtml(education.description)" class="single-section-content-description" v-html="education.description"/>
            </div>
          </div>
          <div v-if="cvData.projects && cvData.projects.length > 0" class="line"/>
          <div v-if="cvData.projects && cvData.projects.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('Projects').toLocaleUpperCase(locale) }}</div>
            <div v-for="project in cvData.projects" :key="project.id" class="single-section-content">
              <div class="single-section-content-information">
                <div v-if="project.title" class="single-section-content-information-text" style="font-weight: bold;">{{  project.title }}</div>
                <div v-if="project.startDate || project.endDate" class="single-section-content-information-text">, &nbsp;&nbsp; </div>
                <div v-if="project.startDate" class="single-section-content-information-text">{{  project.startDate }}</div>
                <div v-if="project.startDate && project.endDate" class="single-section-content-information-text"> &nbsp;-&nbsp; </div>
                <div v-if="project.endDate" class="single-section-content-information-text">{{  project.endDate }}</div>
              </div>
              <div v-if="project.description?.length && containsTextOutsideHtml(project.description)" class="single-section-content-description" v-html="project.description"/>
            </div>
          </div>
          <div v-if="cvData.certifications && cvData.certifications.length > 0" class="line"/>
          <div v-if="cvData.certifications && cvData.certifications.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('CertificationAndCourses').toLocaleUpperCase(locale) }}</div>
            <div v-for="certification in cvData.certifications" :key="certification.id" class="single-section-content">
              <div v-if="certification.institute" class="single-section-content-title">{{  certification.institute?.toLocaleUpperCase(locale) }}</div>
              <div class="single-section-content-information">
                <div v-if="certification.course" class="single-section-content-information-text" style="font-weight: bold;">{{  certification.course }}</div>
                <div  v-if="certification.course && certification.startDate" class="single-section-content-information-text"> &nbsp;|&nbsp; </div>
                <div v-if="certification.startDate" class="single-section-content-information-text">{{ certification.startDate }}</div>
                <div v-if="certification.startDate && certification.endDate" class="single-section-content-information-text"> &nbsp;-&nbsp; </div>
                <div v-if="certification.endDate" class="single-section-content-information-text">{{ certification.endDate }}</div>
              </div>
            </div>
          </div>
          <div v-if="cvData.references && cvData.references.length > 0" class="line"/>
          <div v-if="cvData.references && cvData.references.length > 0" class="single-section">
            <div class="single-section-caption">{{ t('References').toLocaleUpperCase(locale) }}</div>
            <div v-for="reference in cvData.references" :key="reference.id" class="single-section-content">
              <div v-if="reference.name" class="single-section-content-title">{{  reference.name?.toLocaleUpperCase(locale) }}</div>
              <div v-if="reference.company" class="single-section-content-information-text" style="font-weight: bold;">{{ reference.company }}</div>
              <div class="single-section-content-information">
                <div  v-if="reference.phone" class="single-section-content-information-text">{{ reference.phone }}</div>
                <div v-if="reference.phone && reference.email" class="single-section-content-information-text"> &nbsp;-&nbsp; </div>
                <div v-if="reference.email" class="single-section-content-information-text">{{  reference.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.resume {
  width: 595px;
  max-width: 100%;
  min-height: 842px;
  background: var(--white);
  padding: 20px;
  min-height: 700px;
}
.header{
    min-height: 100px;
    justify-content: start;
    align-items: flex-start;
    text-align: start;
}
.header-spans{
    display: flex;
    flex-direction: column;
}
.job-title{
  font-size: calc(var(--global-font-size) + 10px);
  color: var(--resume-color);
}
.name{
  font-size: calc(var(--global-font-size) + 28px);
  color: var(--resume-color);
}
.line{
    display: flex;
    width: 100%;
    border: 1px solid var(--resume-color);;
    margin-top: 15px;
}

.content{
    display: flex;
    flex-direction: row;
}

.left-section{
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--resume-color);
    min-height: 625px;
    width: 200px;
    padding-right: 35px;
}
.right-section{
    display: flex;
    flex-direction: column;
    min-height: 625px;
    flex: 1;
    padding-left: 35px;
}
.single-section{
    text-align: start !important;
}
.single-section-summary{
    padding-top: 15px;
    text-align: start !important;
    font-size: calc(var(--global-font-size) + 1px);
}

.single-section-caption{
    font-size: calc(var(--global-font-size) + 5px);
    font-weight: bold;
    padding-top: 20px;
    color: var(--resume-color);
}
.single-section-content{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin: 15px;
}
.single-section-content-text{
  text-align: start !important;
  font-size: calc(var(--global-font-size) + 1px);
  padding-bottom: 5px;
}

.single-section-list{
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: calc(var(--global-font-size) + 1px) !important;
}

.single-section-list-item {
    width: 100%;
    text-align: start;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    word-break: break-word;
}
.single-section-list-item-vertical{
    width: 100%;
    flex-direction: column;
    display: flex;
    word-break: break-word;
    padding-bottom: 8px;
}
.single-section-list-item * {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.single-section-list-item-name{
    padding-right: 10px;
    width: 100%;
    font-weight: 600;
}
.single-section-list-item-level{
    width: 100%;
    text-align: start !important;
}
.name-level-border{
    align-self: center;
    justify-self: center;
    height: 20px;
    border: 0.5px solid var(--resume-color);;
}
.single-section-content-title{
    font-size: calc(var(--global-font-size) + 1px);
    font-weight: 700;
    padding-bottom: 0px;
}
.single-section-content-information{
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
    padding-top: 0px;
}
.single-section-content-information-text{
    font-size: var(--global-font-size);
    padding-bottom: 2px;
}
.single-section-content-description{
    font-size: var(--global-font-size) !important;
    padding-top: 5px;
    padding-bottom: 10px;
}
.single-section-content-description * {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>