import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

import { firebaseConfig } from "../firebaseConfig";

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

logEvent(analytics, "notification_received");

export { app, analytics };
