
<script setup lang="ts">
import {  onMounted, ref } from "vue";

import { determineUserCountry } from "@/utils/userCountry";

import ComparisonView from "./components/ComparisonView.vue";
import PlanView from "./components/PlanView.vue";

const userCountry = ref(null);

onMounted( async () => {
  userCountry.value = await determineUserCountry();
  console.log(userCountry.value);
  sessionStorage.setItem("userCountry", userCountry.value || "");
});
</script>

<template>
  <div v-if="userCountry">
    <PlanView :user-country="userCountry"/>
    <ComparisonView :user-country="userCountry"/>
  </div>
</template>

<style scoped>
.pricing-container {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }

</style>