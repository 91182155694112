<script setup lang="ts">
import { ref, watchEffect, nextTick, watch, computed, onMounted } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import UpgradePremium from "@/components/UpgradePremium.vue";
import { analyticsCategories } from "@/composables/const";
import type { CvData, Resume } from "@/composables/types";
import { useAnalytics } from "@/composables/useAnalytics";
import { createLibraryEntry, getLibraryEntries , modifyLibraryEntry } from "@/services/libraryService";
import myStore from "@/store/index";
import { createInitialCvData } from "@/utils/createCvData";
import { cloneAndTransformCvData } from "@/utils/fixBulletPoints";
import { loadPdf } from "@/utils/pdfLoader";
import { previewPdf } from "@/utils/previewPdf";
import { returnTemplateComponent } from "@/utils/templateComponent";

import ConfirmDeleteModal from "./components/ConfirmDeleteModal.vue";

const { trackEvent } = useAnalytics();
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const { user } = useAuth();
const selectedResumeId = ref("");
const showDeleteModal = ref(false);
const showUpgradePremium = ref(false);
const resumes = ref<Resume[] | null>([]);
const isFreeUser = computed(() => user.value.details?.accountStatus !== "Premium");
const loading = ref(false);

const editingResumeId = ref<string | null>(null);
const newCvName = ref("");

const modifyCvName = async (name: string, cvId: string, cvData: CvData) => {
  const updatedCvData = {
    ...cvData,
    resumeName: name,
  };

  const updateResumeNameInList = (cvId: string, newName: string) => {
    if (resumes.value) {
      const resume = resumes.value.find((r) => r.id === cvId);
      if (resume) {
        resume.cvData.resumeName = newName;
      }
    }
  };

  await modifyLibraryEntry(cvId, updatedCvData);
  store.commit("updateResumeName", { data: name });
  updateResumeNameInList(cvId, name);
  editingResumeId.value = null;
};

const handleUpgradeClick = () => {
  trackEvent("button_click", {
    category: analyticsCategories.UPGRADE,
    action: "click",
    label: "library_to_upgrade",
    value: 1,
  });
  showUpgradePremium.value = true;
};

const lastModifiedResumeId = computed(() => {
  return resumes.value && resumes.value.length ? resumes.value[0].id : null;
});

const loadPdfPreviews = async () => {
  loading.value = true;
  const maxRetries = 3;

  if (user.value.authUser?.uid) {
    try {
      const { result: resumeData } = await getLibraryEntries(user.value.authUser.uid);
      resumes.value = resumeData || [];

      await nextTick();

      for (const resume of resumes.value) {
        const canvas = document.getElementById(`canvas-${resume.id}`) as HTMLCanvasElement;
        if (canvas) {
          let retries = 0;
          let success = false;

          while (retries < maxRetries && !success) {
            try {
              const pdfDataUrl = await previewPdf(returnTemplateComponent(resume.cvData), myStore, cloneAndTransformCvData(resume.cvData), false, false);
              await loadPdf(pdfDataUrl, 1, canvas);
              success = true;
            } catch (error: unknown) {
              if (error instanceof Error) {
                if (error.name === "RenderingCancelledException") {
                  console.warn(`Rendering was canceled for resume ${resume.id}. Retrying... (${retries + 1}/${maxRetries})`);
                  retries++;
                  await new Promise((resolve) => setTimeout(resolve, 500));
                } else {
                  console.error(`Failed to load PDF for resume ${resume.id}:`, error);
                  break;
                }}
            }
          }

          if (!success) {
            console.error(`Exceeded max retries for resume ${resume.id}`);
          }
        } else {
          console.error("Canvas not found for resume", resume.id);
        }
      }
    } catch (error) {
      console.error("Failed to load library entries:", error);
    } finally {
      loading.value = false;
    }
  }
};

watchEffect(async () => {
  await loadPdfPreviews();
});

watch(resumes, async (oldResumes, newResumes) => {
  if(oldResumes?.length !== newResumes?.length){
    await loadPdfPreviews();
  }
});

watch(showDeleteModal, async () => {
  await loadPdfPreviews();
});

const updateLibrary = (newResumes: Resume[]) => {
  resumes.value = newResumes;
};

const createCv = async () => {
  try {
    const newCvData = createInitialCvData();

    if (user.value.details?.accountStatus === "Premium" && resumes.value?.length) {
      if(resumes.value?.length < 10 ){
        trackEvent("button_click", {
          category: analyticsCategories.CV_CREATION,
          action: "click",
          label: "library_to_creation_premium_user",
          value: 1,
        });
        const { result: cvData } = await createLibraryEntry(newCvData, user.value?.authUser?.uid || "");
        await store.dispatch("setEntireState", newCvData);
        router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
      }
    } else {
      if (!resumes.value?.length) {
        trackEvent("button_click", {
          category: analyticsCategories.CV_CREATION,
          action: "click",
          label: "library_to_creation_first_cv",
          value: 1,
        });
        const { result: cvData } = await createLibraryEntry(newCvData, user.value?.authUser?.uid || "");
        await store.dispatch("setEntireState", newCvData);
        router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
      } else {
        trackEvent("button_click", {
          category: analyticsCategories.CV_CREATION,
          action: "click",
          label: "library_to_upgrade",
          value: 1,
        });
        showUpgradePremium.value = true;
      }
    }
  } catch (error) {
    console.error("Error in createCv:", error);
  }
};

const editCv = async (cvData: CvData, id: string) => {
  await store.dispatch("setEntireState", cvData);
  router.push({ name: "CvCreation", params: { cvId: id } });
};

const openDeleteModal = (id: string) => {
  selectedResumeId.value = id;
  showDeleteModal.value = true;
};

const closeDeleteModal = () => {
  selectedResumeId.value = "";
  showDeleteModal.value = false;
};

const downloadCv = async (resume: Resume) => {
  if(user.value.details?.accountStatus === "Free"){
    handleUpgradeClick();
    return;
  }
  await previewPdf(returnTemplateComponent(resume.cvData), myStore, cloneAndTransformCvData(resume.cvData), true, true);
};

const duplicateCv = async (resume: Resume) => {

  const copyResume = structuredClone(resume);
  if(copyResume.cvData.resumeName.length){
    copyResume.cvData.resumeName = copyResume.cvData.resumeName + t("LibraryView.Copy");
  }
  else if(copyResume.cvData.contact?.firstName?.length){
    copyResume.cvData.resumeName = copyResume.cvData.contact?.firstName + t("LibraryView.Copy");
  }
  else{
    copyResume.cvData.resumeName =  t("LibraryView.EmptyName") + t("LibraryView.Copy");
  }
  await createLibraryEntry(copyResume.cvData, user.value?.authUser?.uid || "");
  resumes.value = [...resumes.value || [], copyResume];
};

</script>

<template>
  <div class="container">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <ConfirmDeleteModal
      v-if="showDeleteModal"
      :id="selectedResumeId"
      :resumes="resumes || []"
      @closeModal="closeDeleteModal"
      @updateLibrary="updateLibrary"
    />
    <span class="my-documents-text">{{ t('LibraryView.MyDocuments') }}</span>
    <div class="new-resume-card">
      <div class="new-resume-text-div">
        <span class="new-resume-text">{{ t('LibraryView.NewResume') }}</span>
        <span class="new-resume-exp-text">{{ t('LibraryView.NewResumeDescription') }}</span>
      </div>
      <button class="new-resume-button" @click="createCv">{{ t('LibraryView.Create') }}</button>
    </div>
    <div v-if="resumes?.length" class="cv-cards">
      <div class="line"/>
      <div v-for="resume in resumes" :key="resume.id" :class="['cv-card', { 'locked-cv': isFreeUser && resume.id !== lastModifiedResumeId, 'hidden-cards': showDeleteModal }]">
        <div class="cv-card-divs">
          <canvas v-if="!showDeleteModal" :id="'canvas-' + resume.id" class="cv-library-preview" :style="{ '--scale-factor': 1 }"></canvas>
          <div class="cv-preview-buttons">
            <button v-if="isFreeUser && resume.id !== lastModifiedResumeId" class="lock-button" @click="handleUpgradeClick">
              <svg class="locked-icon"  viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z">
                </path>
                <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z">
                </path>
              </svg>
            </button>
            <input
              v-if="editingResumeId === resume.id"
              v-model="newCvName"
              class="cv-name-input"
              @blur="modifyCvName(newCvName, resume.id || '', resume.cvData)"
              @keydown.enter="modifyCvName(newCvName, resume.id || '', resume.cvData)"
            />
            <div
              v-else
              class="cv-preview-name-container"
              @click="() => {
                editingResumeId = resume.id;
                newCvName = resume.cvData.resumeName || '';
              }">
              <span class="cv-preview-name">
                {{ resume.cvData?.resumeName?.length
                  ? resume.cvData.resumeName
                  : resume.cvData?.contact?.firstName || t('LibraryView.EmptyName') }}
              </span>
              <font-awesome-icon icon="edit" class="icon-class-v2" />
            </div>
            <button class="cv-preview-action-button" :disabled="isFreeUser && resume.id !== lastModifiedResumeId" @click="editCv(resume.cvData, resume.id)">
              <font-awesome-icon icon="edit" class="icon-class" />
              <span class="action-text">{{ t('LibraryView.Edit') }}</span>
            </button>
            <button class="cv-preview-action-button" :disabled="isFreeUser && resume.id !== lastModifiedResumeId" @click="duplicateCv(resume)">
              <font-awesome-icon :icon="['far', 'copy']" class="icon-class" />
              <span class="action-text">{{ t('LibraryView.Duplicate') }}</span>
            </button>
            <button class="cv-preview-action-button" :disabled="isFreeUser && resume.id !== lastModifiedResumeId" @click="downloadCv(resume)">
              <font-awesome-icon icon="download" class="icon-class" />
              <span class="action-text">{{ t('LibraryView.Download') }}</span>
            </button>
            <button class="cv-preview-action-button" @click="openDeleteModal(resume.id)">
              <font-awesome-icon icon="trash" class="icon-class-delete" />
              <span class="action-text-delete">{{ t('LibraryView.Delete') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <UpgradePremium
      v-if="showUpgradePremium"
      showFeatures
      :premiumReasonText="t('UpgradePremium.UpgradeToPremiumText')"
      @close="showUpgradePremium = false"
    />  </div>
</template>

<style scoped>

.cv-preview-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.cv-preview-name {
  margin-right: 0.5rem;
}

.edit-icon {
  width: 20px;
  height: 20px;
  color: #555; /* Customize the color of the icon */
}

.cv-name-input {
  display: flex;
  align-self: center;
  margin-top: 20px;
  width: 150px;
  font-size: inherit;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cv-library-preview {
  width: 245px;
  height: 341px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: auto;
  overflow: hidden;
  background: white;
  transform-origin: top center;
  transform: scale(var(--scale-factor));

}

.cv-cards{
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: flex-start;
}

.action-text{
    color: #909090;
    font-size: 19px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 21px;
}

.icon-class{
  color: #909090;
  fill: #909090;
  font-size: 25px;
  width: 25px;
  height: 23px;
}
.icon-class-v2{
  color: #909090;
  fill: #909090;
  font-size: 25px;
  width: 18px;
  height: 18px;
}

.action-text-delete{
    color: #b71c1c;
    font-size: 19px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 21px;
}

.icon-class-delete{
    color: #b71c1c;
  fill: #b71c1c;
  font-size: 25px;
  width: 25px;
  height: 23px;
}

.cv-preview-action-button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    margin-left: 30px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    gap: 20px;
    margin-bottom: 13px;
}

.cv-preview-name{
    color: #5438da;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 20px;
    align-self: flex-start;
    margin-left: 30px;
    margin-top: 20px;
}

.cv-preview-buttons{
    display: flex;
    width: 50%;
    gap: 14px;
    flex-direction: column;
}

.cv-preview-buttons button:hover {
    background-color: #ececec;
}

.cv-preview{
    width: 245px;
  height: 341px;
  border-radius: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-image: url('https://assets.api.uizard.io/api/cdn/stream/0dc81016-a879-4534-adae-657507dc4d4e.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
}

.cv-card-divs{
    display: flex;
    flex-direction: row;
}

.cv-card{
    width: 563px;
    height: 382px;
    background-color: #fafafa;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
    padding: 20px;
    position: relative;
}

.line{
  margin-top: 70px;
    margin-bottom: 70px;
    width: 90%;
  height: 2px;
  background-color: #dedede;
  border-radius: 2px;
}

.new-resume-button{
    cursor: pointer;
    width: 162px;
    height: 61px;
    padding: 0px 8px;
    border: 1px solid #aa93f3;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #d6ccfe;
    color: #030303;
    font-size: 13px;
    font-family: 'Montserrat';
    line-height: 18px;
    outline: none;
}

.new-resume-text-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    gap: 10px;
    text-align: left;
    margin-right: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  margin-top: 100px;
  margin-left: 70px;
}
.my-documents-text{
  color: #030303;
  font-size: 32px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 42px;
}
.new-resume-card{
display: flex;
  width: 563px;
  height: 130px;
  background-color: #fafafa;
  border-radius: 16px;
  border: 1px solid #aa93f3;
  box-sizing: border-box;
  margin-top: 50px;
  padding: 30px;

}

.new-resume-text{
    color: #030303;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 23px;
}
.new-resume-exp-text{
    color: #3d3d3d;
  font-size: 13px;
  font-family: 'Montserrat';
  line-height: 18px;
}

.no-pointer-events {
  pointer-events: none;
}

.hidden-cards{
visibility: hidden;
}

.locked-cv {
  opacity: 0.5;
  position: relative;
}

.locked-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #b71c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.locked-icon {
  pointer-events: none;
  height: 35px;
  width: 35px;
}

.lock-button {
  border: none;
  background: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  align-self: center;
}

.icon-class-delete {
  color: #b71c1c;
  font-size: 25px;
  width: 25px;
  height: 23px;
}

.action-text {
  color: #909090;
  font-size: 19px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
}

.action-text-delete {
  color: #b71c1c;
  font-size: 19px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #5438da;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
</style>