
export const initialStateEducation = (id: string) => {
  return {  id,
    degree: "",
    school: "",
    location:"",
    startDate: "",
    endDate: "",
    isCurrentEducation: false,
  };
};

export const initialStateExperience = (id: string) => {
  return {
    id,
    title: "",
    company: "",
    startDate: "",
    endDate: "",
    isCurrentWork: false,
    location: "",
  };
};

export const initialStateSkill= (id: string) => {
  return {
    id,
    name: "",
    level: "",
  };
};

export const initialStateLanguage= (id: string) => {
  return {
    id,
    name: "",
    level: "",
  };
};

export const initialStateReference= (id: string) => {
  return {
    id,
    name: "",
    company: "",
    phone: "",
    email: "",
  };
};

export const initialStateProject = (id: string) => {
  return {
    id,
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    isCurrentProject: false,
  };
};

export const initialStatePublication = (id: string) => {
  return {
    id,
    description: "",
  };
};

export const initialStateHonor = (id: string) => {
  return {
    id,
    description: "",
  };
};
export const initialStateHobby = (id: string) => {
  return {
    id,
    description: "",
  };
};
export const initialStateExhibition = (id: string) => {
  return {
    id,
    description: "",
  };
};
export const initialStateVoluntary = (id: string) => {
  return {
    id,
    description: "",
  };
};

export const initialStateCertification = (id: string) => {
  return {
    id,
    course: "",
    startDate: "",
    endDate: "",
    institute: "",
  };
};

