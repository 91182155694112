<script setup lang="ts">
import { onMounted, watch } from "vue";

import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const fetchContent = async () => {
  try {
    const language = locale.value === "de" ? "de" : "en";
    const response = await fetch(`https://app.cockpit.legal/api/cockpit/resources/legaldocumentshare/d30aae0321b9674a0b3018d94a2a8c91/document/render/html?language=${language}`);

    const content = await response.text();

    const lcTextElement = document.getElementById("lc-text");
    if (lcTextElement) {
      lcTextElement.innerHTML = content;
    } else {
      console.error("Element with id 'lc-text' not found.");
    }
  } catch (error) {
    console.error("Error fetching content:", error);
  }
};

watch(locale, () => {
  fetchContent();
});

onMounted(() => {
  fetchContent();
});
</script>

<template>
  <div class="policy-container">
    <div class="policy-card">
      <div id="lc-text" class="info"></div>
    </div>
  </div>
</template>

<style scoped>
.policy-container {
  max-width: 1000px;
  border-radius: 5px;
  align-self: center;
}

.policy-card{
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: left;
    background-color: #f0f0f0;
    border-radius: 26px;
    margin-top: 50px;
}
.h2{
    text-align: center;
}
.info{
    padding-top: 50px;
    margin: 50px;
    text-align: flex-start;
}
</style>