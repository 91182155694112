<script setup lang="ts">
import { ref, defineEmits } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const isVisible = ref(true);
const props = defineProps<{
  premiumReasonText?: string;
  premiumHeader?: string;
  showFeatures?: boolean
}>();

const router = useRouter();

const emit = defineEmits(["close"]);

const closeComponent = (success: boolean) => {
  isVisible.value = false;
  emit("close", success);
};
const showPayment = async () => {
  router.push({ path: "/pricing" });
};

const premiumReasonText = props.premiumReasonText ?? t("PremiumFeatureText");
const premiumHeader = props.premiumHeader ?? t("UpgradePremium.UpgradeToPremium");
const showFeatures = props.showFeatures ?? false;

</script>

<template>
  <div  class="overlay" @click.self="closeComponent(false)">
    <div class="upgrade-premium-container">
      <div class="premium-card">
        <button class="close-button" @click="closeComponent(false)">✖</button>
        <svg class="premium-icon" viewBox="0 0 576 512">
          <path d="M576 136c0 22.09-17.91 40-40 40c-.248 0-.4551-.1266-.7031-.1305l-50.52 277.9C482 468.9 468.8 480 453.3 480H122.7c-15.46 0-28.72-11.06-31.48-26.27L40.71 175.9C40.46 175.9 40.25 176 39.1 176c-22.09 0-40-17.91-40-40S17.91 96 39.1 96s40 17.91 40 40c0 8.998-3.521 16.89-8.537 23.57l89.63 71.7c15.91 12.73 39.5 7.544 48.61-10.68l57.6-115.2C255.1 98.34 247.1 86.34 247.1 72C247.1 49.91 265.9 32 288 32s39.1 17.91 39.1 40c0 14.34-7.963 26.34-19.3 33.4l57.6 115.2c9.111 18.22 32.71 23.4 48.61 10.68l89.63-71.7C499.5 152.9 496 144.1 496 136C496 113.9 513.9 96 536 96S576 113.9 576 136z"></path>
        </svg>
        <div class="premium-text">{{ premiumHeader }}</div>
        <div class="premium-reason-text">{{ premiumReasonText }}</div>
        <div v-if="showFeatures">
          <div class="side-by-side-container">
            <div class="side-by-side-features">
              <svg class="side-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
              </svg>
              <div class="side-text">{{ t("UpgradePremium.UnlimitedResumeCreation") }}</div>
            </div>
            <div class="side-by-side-features">
              <svg class="side-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
              </svg>
              <div class="side-text">{{ t("UpgradePremium.UnlimitedResumeDownload") }}</div>
            </div>
          </div>
          <div class="side-by-side-container">
            <div class="side-by-side-features">
              <svg class="side-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
              </svg>
              <div class="side-text">{{ t("UpgradePremium.UnlimitedResumeEvaluation") }}</div>
            </div>
            <div class="side-by-side-features">
              <svg class="side-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
              </svg>
              <div class="side-text">{{ t("UpgradePremium.AccessAllTemplates") }}</div>
            </div>
          </div>
          <button class="upgrade-button" @click="showPayment">{{ t("UpgradePremium.UpgradeNow") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.upgrade-premium-container {
  max-width: 877px;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 544px;
  position: absolute;
  transform: translate(-50%, -50%);
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 5px;
  z-index: 1000;
  justify-content: center;
}

.premium-card{
    flex-direction: column;
    align-items: centers;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    border-radius: 26px;
    min-height: 200px;
}
.premium-icon{
  color: #5438da;
  fill: #5438da;
  font-size: 40px;
  width: 40px;
  height: 40px;
  margin-top: 20px;
}
.premium-text{
  font-size: 32px;
  font-weight: 500;
  color: #030303;
  text-align: center;
  margin-top: 20px;
}
.premium-reason-text{
  font-size: 16px;
  color: #030303;
  text-align: center;
  padding-top: 20px;
  margin-left: 20%;
  margin-right: 20%;
  align-self: center;
  text-align: center;
}

.side-by-side-features {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 48%;
  margin-left: 20px;
}
.side-icon {
  color: #5438da;
  fill: #5438da;
  font-size: 24px;
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
.side-text {
  font-size: 16px;
  color: #030303;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
}
.side-by-side-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.upgrade-button {
  cursor: pointer;
  width: 174px;
  height: 48px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 100000px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  outline: none;
  margin-top: 40px;
  margin-bottom: 40px;
}
.close-button {
  position: absolute;
  top: 50px;
  right: 20px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #cecaca;
}
.close-button:hover {
  color: #909090;
}
</style>

