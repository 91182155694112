<template>
  <div class="verification-container">
    <p v-if="!loading">{{ t(messageKey) }}</p>
    <div v-if="loading" class="loading-spinner">{{ t("Auth.VerificationInProgress") }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();
const auth = getAuth();
const messageKey = ref("Auth.VerifyEmailPrompt");
const loading = ref(true);

onMounted(() => {
  onAuthStateChanged(auth, async (firebaseUser) => {
    if (firebaseUser) {
      await firebaseUser.reload();

      if (firebaseUser.emailVerified) {
        messageKey.value = "Auth.EmailVerifiedSuccess";
        setTimeout(() => {
          router.push({ path: "/" });
        }, 2000);
      } else {
        messageKey.value = "Auth.VerifyEmailPrompt";
        router.push({ path: "/login" });
      }
    } else {
      messageKey.value = "Auth.NotAuthenticated";
      router.push({ path: "/login" });
    }

    loading.value = false;
  });
});
</script>

  <style scoped>
  .verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
  }
  .loading-spinner {
    margin-top: 20px;
    font-size: 18px;
  }
  </style>
