<script setup lang="ts">

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

const emit = defineEmits(["closeModal", "login"]);

const closeModal = () => {
  emit("closeModal");
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
};

const login = () => {
  emit("login");
};

const pressLogin = () => {
  closeModal();
  login();
};

</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <button class="close-button" @click="closeModal">X</button>
      <div class="modal-items">
        <div class="circle-div">
          <svg class="icon-class" viewBox="0 0 128 512">
            <path d="M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z">
            </path>
          </svg>
        </div>
        <div>
          <p class="session-text">{{ t('SessionExpired') }}</p>
        </div>
        <div>
          <p class="login-text">{{ t('LoginPrompt') }}</p>
        </div>
        <button class="custom-button" @click="pressLogin()">{{ t('Login') }}</button>
      </div>

    </div>
  </div>
</template>

<style scoped>

.custom-button {
  cursor: pointer;
  width: 111px;
  height: 46px;
  padding: 0 8px;
  border: 2px solid #5438da;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.07);
  color: #737373;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  outline: none;
}

.login-text{
    color: #909090;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 21px;
  text-align: center;
}

.session-text {
  color: #030303;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  line-height: 28px;
  text-align: center;
}

.icon-class{
    color: #ff8c00;
  fill: #ff8c00;
  font-size: 77px;
  width: 77px;
  height: 72px;
  position: absolute;
}

.circle-div{
    display: flex;
    width: 137px;
  height: 137px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 9999px;
  border: 4px solid #ff8c00;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.modal-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.modal {
  position: relative;
  width: 760px;
  height: 451px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

</style>
