import type { SectionScore } from "@/composables/types";

export const skillLevelMap: Map<string, number> = new Map([
  ["Beginner", 0],
  ["Intermediate", 1],
  ["Experienced", 2],
  ["Advanced", 3],
  ["Expert", 4],
]);

export const languageLevelMap: Map<string, number> = new Map([
  ["Beginner", 0],
  ["Intermediate", 1],
  ["Experienced", 2],
  ["Advanced", 3],
  ["Expert", 4],
]);

export interface CvInputProps {
  currentStep: string;
  cvId: string;
  evaluated: boolean;
  sectionScores: SectionScore[];
}

export type AdditionalSectionKey = "certifications" | "projects" | "honors" | "languages" | "publications" | "references" | "hobbies" | "exhibitions" | "voluntaries";

export const sections: { name: AdditionalSectionKey; label: string; }[] = [
  { name: "certifications", label: "Certification & Courses" },
  { name: "projects", label: "Projects" },
  { name: "honors", label: "Honors & Awards" },
  { name: "languages", label: "Languages" },
  { name: "publications", label: "Publications" },
  { name: "references", label: "References" },
  { name: "hobbies", label: "Hobbies" },
  { name: "exhibitions", label: "Exhibitions" },
  { name: "voluntaries", label: "Voluntary Activities" },
];

export interface HTMLElementWithVNode extends HTMLElement {
  __vnode?: {
    children?: HTMLElementWithVNode[];
  };
}

export type ForbiddenZone = { start: number, end: number };