<script setup lang="ts">
import { defineProps, computed } from "vue";

import { useI18n } from "vue-i18n";

import type { CvInputProps } from "../const";

import AdditionalDetails from "./cv-input-sections/AdditionalDetails.vue";
import EducationDetails from "./cv-input-sections/EducationDetails.vue";
import ExperiencesDetails from "./cv-input-sections/ExperiencesDetails.vue";
import PersonalDetails from "./cv-input-sections/PersonalDetails.vue";
import SkillsDetails from "./cv-input-sections/SkillsDetails.vue";

const { t, locale } = useI18n();
const props = defineProps<CvInputProps>();

const findScoreDetails = (sectionNameEn: string, sectionNameTr: string, sectionNameDe: string) => {
  return computed(() => {
    let searchName = "";

    if (locale.value === "en") {
      searchName = sectionNameEn;
    } else if (locale.value === "tr") {
      searchName = sectionNameTr;
    } else if (locale.value === "de") {
      searchName = sectionNameDe;
    }

    const result = props.sectionScores.find(section => section.sectionName.includes(searchName));

    return result;
  });
};
</script>

<template>
  <div class="input-section-mask">
    <div class="input-section">

      <PersonalDetails
        v-if="currentStep === t('cvCreationView.Personal')"
        :cvId="cvId"
        :evaluated="evaluated"
        :scoreDetails="findScoreDetails('Personal Information', 'Kişisel Bilgiler', 'Persönliche Informationen').value"
      />

      <EducationDetails
        v-if="currentStep === t('cvCreationView.Education')"
        :cvId="cvId"
        :evaluated="evaluated"
        :scoreDetails="findScoreDetails('Education', 'Eğitim', 'Ausbildung').value"
      />

      <ExperiencesDetails
        v-if="currentStep === t('cvCreationView.Experiences')"
        :cvId="cvId"
        :evaluated="evaluated"
        :scoreDetails="findScoreDetails('Experience', 'Deneyim', 'Berufserfahrung').value"
      />

      <SkillsDetails
        v-if="currentStep === t('cvCreationView.Skills')"
        :cvId="cvId"
        :evaluated="evaluated"
        :scoreDetails="findScoreDetails('Skills', 'Beceriler', 'Fähigkeiten').value"
      />

      <AdditionalDetails
        v-if="currentStep === t('cvCreationView.Additional')"
        :cvId="cvId"
        :evaluated="evaluated"
        :scoreDetails="findScoreDetails('Additional Information', 'Ek Bilgiler', 'Zusätzliche Informationen').value"
      />

    </div>
  </div>
</template>

<style>
* {
  box-sizing: border-box;
}

.input-section-mask {
  width: 90%;
  overflow: hidden;
  margin: auto;
  border: 1px solid black;
  margin-bottom: 40px;
  margin-top: 40px;
}

.input-section {
  width: calc(100% - 17px);
  overflow-y: scroll;
  padding-right: 17px;
}

.input-section::-webkit-scrollbar {
  display: none;
}

.input-section {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.continue-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
}

</style>
