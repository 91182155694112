<script setup lang="ts">
import { computed } from "vue";

import { useI18n } from "vue-i18n";

// Initialize i18n
const { t } = useI18n();

// Extract the URL and surrounding text
const commissionSentence: string = t("Policies.CommissionSentences");
const urlRegex = /(https?:\/\/[^\s]+)/g;
const match: RegExpMatchArray | null = commissionSentence.match(urlRegex);

const beforeLink = computed<string>(() => match ? commissionSentence.split(match[0])[0] : commissionSentence);
const url = computed<string>(() => match ? match[0] : "");
const afterLink = computed<string>(() => match ? commissionSentence.split(match[0])[1] : "");

// Email for static email address
const email = "info@resumify-ai.com";
const emailLink = computed<string>(() => `mailto:${email}`);

// Extract email from Policies.ReachPoint key
const reachPointSentence: string = t("Policies.ReachPoint") + "info@resumify-ai.com";
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
const emailMatch: RegExpMatchArray | null = reachPointSentence.match(emailRegex);

const beforeEmail = computed<string>(() => emailMatch ? reachPointSentence.split(emailMatch[0])[0] : reachPointSentence);
const reachPointEmail = computed<string>(() => emailMatch ? emailMatch[0] : "");
const afterEmail = computed<string>(() => emailMatch ? reachPointSentence.split(emailMatch[0])[1] : "");
const reachPointEmailLink = computed<string>(() => `mailto:${reachPointEmail.value}`);
</script>

<template>
  <div class="policy-container">
    <div class="policy-card">
      <div class="header">{{ t("Policies.Imprint") }}</div>
      <div class="info-text" style="margin-top: 30px;">{{ t("Policies.Provider") }}: B.AI  Solutions UG {{ t("Policies.LimitedLiability") }}</div>
      <div class="info-text">Kolonnenstr. 8</div>
      <div class="info-text" style="padding-bottom: 20px;">10827 Berlin</div>
      <div class="info-text" style="padding-bottom: 20px;">E-Mail: <a :href="emailLink">{{ email }}</a></div>
      <div class="info-text">{{ t("Policies.RegistrationRegister") }}: HRB 266939 B</div>
      <div class="info-text">{{ t("Policies.RegisteredCourt") }}: Amtsgericht Charlottenburg (Berlin)</div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.AuthorizedRepresentatives") }}: Iris Özkaya</div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.CEO") }}: Iris Özkaya</div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.VATId") }}: DE370049952</div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.TaxNumber") }}: 3021550550</div>
      <div class="info-text">
        {{ beforeLink }}
        <a :href="url" target="_blank">{{ url }}</a>
        {{ afterLink }}
      </div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.FindEmailAddress") }}</div>
      <div class="info-text" style="padding-bottom: 20px;">{{ t("Policies.ConsumerBoardSentences") }}</div>
      <div class="info-text">
        {{ beforeEmail }}
        <a :href="reachPointEmailLink">{{ reachPointEmail }}</a>
        {{ afterEmail }}
      </div>
      <div class="info-text" style="padding-bottom: 50px;">{{ t("Policies.OfferedLanguages") }}</div>
    </div>
  </div>
</template>

<style scoped>
.policy-container {
  min-width: 877px;
  min-height: 920px;
  border-radius: 5px;
  align-self: center;
}

.policy-card{
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: left;
    background-color: #f0f0f0;
    border-radius: 26px;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.header{
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
}
.info-text{
  font-size: 18px;
  margin-left: 50px;
  margin-top: 10px;
  text-align: flex-start;

}
</style>