<script setup lang="ts">
import { onMounted, ref, computed } from "vue";

import type { AxiosResponse } from "axios";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import UpgradePremium from "@/components/UpgradePremium.vue";
import type { SectionScore } from "@/composables/types";
import { API_URL } from "@/config";
import { getLibraryEntry } from "@/services/libraryService";

import CvInputSection from "./cv-creation/CvInputs.vue";
import CvPreview from "./cv-creation/CvPreview.vue";
import ProgressSteps from "./cv-creation/ProgressSteps.vue";

type DoneCallback = () => void;
interface FeedbackResponse {
  componentsWithScores: string;
}

const { t } = useI18n();
const { user } = useAuth();

const route = useRoute();
const store = useStore();
const cvId: string = route.params.cvId as string || "defaultCvId";
const loaded = ref(false);
const evaluated = ref(false);
const sectionScores = ref<SectionScore[]>([{ sectionName: t("cvCreationView.Contact"), score: "60", evaluation: "aaaaaa" },{ sectionName: t("cvCreationView.Experiences"), score: "90", evaluation: "aaaaaa" }
  ,{ sectionName: t("cvCreationView.Education"), score: "40", evaluation: "aaaaaa" },{ sectionName: t("cvCreationView.CertificationAndCourses"), score: "80", evaluation: "aaaaaa" },{ sectionName: t("cvCreationView.Skills"), score: "20", evaluation: "aaaaaa" }]);
const generalScore = ref(0);
const generalScoreExplanation = ref("");
const creditNotEnough = ref(false);

const steps = computed(() => [
  t("cvCreationView.Personal"),
  t("cvCreationView.Education"),
  t("cvCreationView.Experiences"),
  t("cvCreationView.Skills"),
  t("cvCreationView.Additional"),
]);
const currentStep = ref(steps.value[0]);

const stepForward = () => {
  const currentIndex = steps.value.indexOf(currentStep.value);
  if (currentIndex < steps.value.length - 1) {
    currentStep.value = steps.value[currentIndex + 1];
  }
};

const stepBack = () => {
  const currentIndex = steps.value.indexOf(currentStep.value);
  if (currentIndex > 0) {
    currentStep.value = steps.value[currentIndex - 1];
  }
};

const fetchFeedback = async (cvText: string, jobTitle: string, doneCallback: DoneCallback) => {
  try {

    const csrfResponse = await axios.get(API_URL + "/csrf-token", { withCredentials: true });
    const csrfToken = csrfResponse.data.csrfToken;

    const response: AxiosResponse<FeedbackResponse> = await axios({
      method: "POST",
      url: API_URL + "/componentsFeedbackWithScores",
      data: JSON.stringify({ cvText: cvText, jobTitle: jobTitle, language: t("Locale"), uid: user.value.details?.id || "" }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    });

    if (!response.data) {
      throw new Error("Network response was not ok");
    }

    const data = await response.data;

    const componentsWithScores = JSON.parse(data.componentsWithScores);

    generalScore.value = Number(componentsWithScores.generalscore);
    generalScoreExplanation.value = componentsWithScores.evaluation;
    sectionScores.value = componentsWithScores.sections;
    evaluated.value = true;
    doneCallback();

  } catch (error: any) {
    if(error?.response?.status === 402){
      creditNotEnough.value = true;
    }

    doneCallback();
  }
};

onMounted( async () => {
  const { result: existingCv } = await getLibraryEntry(cvId);
  await store.dispatch("setEntireState", existingCv?.cvData);
  loaded.value = true;

});
</script>

<template>
  <UpgradePremium v-if="creditNotEnough"  :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <div v-if="loaded" class="cv-creation-container">
    <div class="left-side">
      <ProgressSteps
        :steps="steps"
        :currentStep="currentStep"
        :evaluated="evaluated"
        :sectionScores="sectionScores"
        @update:currentStep="currentStep = $event"
      />

      <div class="input-section">
        <CvInputSection
          :currentStep="currentStep"
          :cvId="cvId"
          :evaluated="evaluated"
          :sectionScores="sectionScores"
        />
      </div>
      <div class="button-div">
        <button
          v-if="!(currentStep===t('cvCreationView.Personal'))"
          type="button"
          class="back-button"
          @click="stepBack"
        >
          <font-awesome-icon
            icon="arrow-left"
            class="skill-icon"
          />
          <span>Back</span>
        </button>
      </div>

    </div>

    <div class="right-side">
      <CvPreview :evaluated="evaluated" :generalScore="generalScore" :generalScoreExplanation="generalScoreExplanation" @evaluate="fetchFeedback"/>
    </div>
  </div>
</template>

  <style>

.button-div{
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 30px;
  flex-direction: row;

}
.continue-button-container {
  margin-left: auto;
}

.continue-button-new {
  cursor: pointer;
  width: 150px;
  height: 42px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #5438da;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 26px;
  outline: none;
}

.back-button{
  cursor: pointer;
    width: 150px;
    height: 42px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 0;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #f0f0f0;
    color: #909090;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 26px;
    outline: none;
}

.white-icon {
  color: white;
}
.icon-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  padding: 10px;
  margin-bottom: 12px;
}

.add-more{
  color: white;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

  .cv-creation-container {
    display: flex;
  }

  .left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.right-side {
   display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: #909090;
  overflow: hidden;
}

.download-button{
  padding: 8px 16px;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  cursor: pointer;
}

  </style>