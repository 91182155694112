<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 730, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1500, end: rectForFirst.y + 1640 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const handleResize = () => {
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}
};

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="introduction">
        <div class="contact-div" style="padding-bottom: 25px;">
          <div class="dob-div">
            <span v-if="cvData.contact?.firstName" class="name-text" style="padding-right: 4px;">{{ cvData.contact?.firstName }}</span>
            <span v-if="cvData.contact?.lastName" class="name-text">{{ cvData.contact?.lastName }}</span>
          </div>
          <div v-if="cvData.contact?.jobTitle" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4h4v2h-4V4zM4 8h16v10H4V8zm0-2a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V8a2 2 0 00-2-2h-4V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2H4z" fill="#333333"/>
            </svg>
            <span class="job-text" :style="{ fontFamily: cvData.resumeFontFamily }">{{ cvData.contact?.jobTitle }}</span>
          </div>
          <div v-if="cvData.contact?.country || cvData.contact?.city" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" fill="#333333"/>
            </svg>
            <span v-if="cvData.contact?.country" class="job-text" style="padding-right: 3px;">{{ cvData.contact?.country }}</span>
            <span v-if="cvData.contact?.city" class="job-text">{{ cvData.contact?.city }}</span>
          </div>
          <div v-if="cvData.contact?.address" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 3L2 12h3v8h6v-5h2v5h6v-8h3L12 3z" fill="#333333"/>
            </svg>
            <span  class="job-text">{{ cvData.contact?.address }}</span>
          </div>

          <div  v-if="cvData.contact?.birthDate" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v15a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm0 17H5V10h14v10zm0-12H5V5h14v3z" fill="#333333"/>
            </svg>
            <span class="job-text">{{ cvData.contact?.birthDate }}</span>
          </div>

          <div v-if="cvData.contact?.email" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 2l-8 5-8-5h16zm-8 7l-8-5v10h16V8l-8 5z" fill="#333333"/>
            </svg>
            <span class="job-text">{{ cvData.contact?.email }}</span>
          </div>

          <div v-if="cvData.contact?.phone" class="dob-div">
            <svg class="icon-job" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.62 10.79a15.056 15.056 0 006.59 6.59l2.2-2.2a1 1 0 011.06-.23 11.6 11.6 0 003.62.61 1 1 0 011 1V20a1 1 0 01-1 1A16.94 16.94 0 013 5a1 1 0 011-1h2.5a1 1 0 011 1 11.6 11.6 0 00.61 3.62 1 1 0 01-.23 1.06l-2.2 2.2z" fill="#333333"/>
            </svg>
            <span class="job-text">{{ cvData.contact?.phone }}</span>
          </div>
        </div>
        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact.description)" class="introduction-div">
          <span class="introduction-text" v-html="cvData.contact?.description"></span>
        </div>
      </div>
      <div class="custom-line"> </div>
      <div class="main-div">
        <div class="left-col">
          <div v-if="cvData.experiences && cvData.experiences.length > 0" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Experience') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="experience in cvData.experiences" :key="experience.id" class="long-card">
                <span class="title-text">{{ experience.title }}</span>
                <div class="organization-div">
                  <span v-if="experience.company" class="organization-text">{{ experience.company }}</span>
                  <span v-if="experience.company && experience.location" class="organization-text">-</span>
                  <span v-if="experience.location" class="organization-text">{{ experience.location }}</span>
                </div>
                <div v-if="experience.startDate || experience.endDate" class="period-text">
                  <span v-if="experience.startDate">{{ experience.startDate }}</span>
                  <span v-if="experience.startDate && experience.endDate"> - </span>
                  <span v-if="experience.endDate">{{ experience.endDate }}</span>
                </div>
                <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-div">
                  <span class="description-text" v-html="experience.description"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.educations && cvData.educations.length > 0" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Education') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="education in cvData.educations" :key="education.id" class="long-card">
                <span class="title-text">{{ education.degree }}</span>
                <div class="organization-div">
                  <span v-if="education.school" class="organization-text">{{ education.school }}</span>
                  <span v-if="education.school && education.location" class="organization-text">-</span>
                  <span v-if="education.location" class="organization-text">{{ education.location }}</span>
                </div>
                <div v-if="education.startDate || education.endDate" class="period-text">
                  <span v-if="education.startDate">{{ education.startDate }}</span>
                  <span v-if="education.startDate && education.endDate"> - </span>
                  <span v-if="education.endDate">{{ education.endDate }}</span>
                </div>
                <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-div">
                  <span class="description-text" v-html="education.description"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.projects?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Projects') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="project in cvData.projects" :key="project.id" class="long-card">
                <span v-if="project.title" class="title-text">{{ project.title }}</span>
                <div v-if="project.startDate || project.endDate" class="period-text">
                  <span v-if="project.startDate">{{ project.startDate }}</span>
                  <span v-if="project.startDate && project.endDate"> - </span>
                  <span v-if="project.endDate">{{ project.endDate }}</span>
                </div>
                <div class="description-div">
                  <span v-if="project.description && containsTextOutsideHtml(project.description)" class="description-text" v-html="project.description"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.references?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('References') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="reference in cvData.references" :key="reference.id" class="long-card">
                <span v-if="reference.name" class="title-text">{{ reference.name }}</span>
                <span v-if="reference.company" class="title-text">{{ reference.company }}</span>
                <div class="period-text">
                  <span v-if="reference.phone">{{ reference.phone }}</span>
                  <span v-if="reference.phone && reference.email"> - </span>
                  <span v-if="reference.email">{{ reference.email }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.publications?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Publications') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="publication in cvData.publications" :key="publication.id" class="long-card">
                <div class="description-div">
                  <span class="description-text" v-html="publication.description"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.exhibitions?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Exhibitions') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id" class="long-card">
                <div class="description-div">
                  <span class="description-text" v-html="exhibition.description"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.hobbies?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Hobbies') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="hobby in cvData.hobbies" :key="hobby.id" class="long-card">
                <div class="description-div">
                  <span class="description-text" v-html="hobby.description"></span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="cvData.honors?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Honors & Awards') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="honor in cvData.honors" :key="honor.id" class="long-card">
                <div class="description-div">
                  <span class="description-text" v-html="honor.description"></span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="cvData.voluntaries?.length" class="long-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Voluntary Activities') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id" class="long-card">
                <div class="description-div">
                  <span class="description-text" v-html="voluntary.description"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="right-col">
          <div v-if="cvData.certifications && cvData.certifications.length > 0" class="short-section-div">
            <div class="short-section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Certification & Courses') }}</span>
            </div>
            <div class="long-section-cards">
              <div v-for="certification in cvData.certifications" :key="certification.id" class="short-card">
                <span class="title-text">{{ certification.course }}</span>
                <div  v-if="certification.institute" class="short-organization-div">
                  <span class="organization-text">{{ certification.institute }}</span>
                </div>
                <div v-if="certification.startDate || certification.endDate" class="period-text">
                  <span v-if="certification.startDate">{{ certification.startDate }}</span>
                  <span v-if="certification.startDate && certification.endDate"> - </span>
                  <span v-if="certification.endDate">{{ certification.endDate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.skills && cvData.skills.length > 0" class="short-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Skills') }}</span>
            </div>
            <div class="short-section-cards">
              <div v-for="skill in cvData.skills" :key="skill.id" class="short-card">
                <div class="organization-div">
                  <span v-if="skill.name" class="organization-text">{{ skill.name }}</span>
                  <span v-if="skill.name && skill.level && cvData.showSkillLevels" class="organization-text"> - </span>
                  <span v-if="skill.level && cvData.showSkillLevels" class="organization-text">{{ t(skill.level) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cvData.languages && cvData.languages.length > 0" class="short-section-div">
            <div class="section-title">
              <span class="section-title-text" :style="{color: cvData.resumeColor}">{{ t('Language') }}</span>
            </div>
            <div class="short-section-cards">
              <div v-for="language in cvData.languages" :key="language.id" class="short-card">
                <div class="organization-div">
                  <span v-if="language.name" class="organization-text">{{ language.name }}</span>
                  <span v-if="language.name && language.level && cvData.showLanguageLevels" class="organization-text"> - </span>
                  <span v-if="language.level && cvData.showLanguageLevels" class="organization-text">{{ language.level }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.resume {
  width: 595px;
  max-width: 100%;
  margin: 50px auto;
  background: var(--white);
  min-height: 700px;
}

.introduction{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 30px;
}

.contact-div{
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 150px;
  gap: 6px;
}

.name-div{
  display: flex;
  flex-direction: row;
  width: 242.5px;
  height: calc(var(--global-font-size) + 15px);
  padding-bottom: 10px;
}

.name-text{
  font-weight: 700;
  font-size: calc(var(--global-font-size) + 10px);
  color: #333333;
}

.job-title{
  display: flex;
  width: 243px;
  height: 16px;
  gap: 3px;
}

.job-title-text{
  font-weight: 700;
  font-size: calc(var(--global-font-size) + 2px);
  line-height: 16.34px;
}

.city-country-div{
  display: flex;
  flex-direction: row;
  width: 242.5px;
  height: 13px;
  gap: 5px;
}

.city-country-text{
  height: 13px;
  gap: 5px;
}

.address-div{
display: flex;
width: 242.5px;
height: 13px;
gap: 5px;
}

.address-text{
font-weight: 400;
font-size: calc(var(--global-font-size) - 1px);
}

.dob-div{
  display: flex;
  align-items: start;
  max-width: 300px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.icon-job{
  width: calc(var(--global-font-size) + 6px);
  height: calc(var(--global-font-size) + 6px);
}
.job-text{
  font-weight: 400;
  font-size: calc(var(--global-font-size) - 1px);
  margin: 0;
  padding-left: 3px;
  padding-top: 2px;
  word-wrap: break-word;
  text-align: left;
}

.email-phone-div{
  display: flex;
  flex-direction: row;
  width: 242.5px;
  height: 16px;
  gap: 5px;
}

.email-phone-text{
  font-weight: 400;
  font-size: calc(var(--global-font-size) - 1px);
  line-height: 12.6px;
}

.introduction-div{
  display: flex;
  max-width: 242.5px;
  height: 150px;
  padding: 8px 0px 0px 0px;
  gap: 10px;
}

.introduction-text{
font-weight: 400;
font-size: calc(var(--global-font-size) - 1px);
line-height: 12.6px;
}

.custom-line {
  width: 595px;
  height: 0px;
  border: 1px solid #EEEEEE;
  margin: 0 auto;
}

.main-div{
  display: flex;
  flex-direction: row;
  width: 595px;
  padding: 30px 40px 30px 40px;
  gap: 30px;
}

.left-col{
  display: flex;
  flex-direction: column;
  width: 305px;
  gap: 20px;
}

.long-section-div{
  display: flex;
  flex-direction: column;
  width: 305px;
  gap: 10px;
}

.short-section-div{
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 10px;
}

.section-title{
  display: flex;
  width: 305px;
  height: 15px;
  gap: 10px;
}

.short-section-title{
  display: flex;
  width: 180px;
  height: 15px;
  gap: 10px;
}

.section-title-text{
  font-weight: 700;
  font-size: calc(var(--global-font-size) + 1px);
  line-height: 14.98px;
}

.long-section-cards{
  display: flex;
  flex-direction: column;
  width: 305px;
  gap: 20px;

}

.short-section-cards{
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 8px;
  align-items: flex-end;
  list-style-type: disc;
}

.list-style{
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 8px;
  align-items: flex-end;
  list-style-type: disc !important;
}

.long-card{
  display: flex;
  flex-direction: column;
  width: 305px;
  gap:4px;
  text-align: left;
}

.short-card{
  display: flex;
  flex-direction: column;
  width: 180px;
  gap:4px;
  text-align: left;
}

.title-text{
  display: flex;
  font-weight: 700;
  font-size: var(--global-font-size);
  line-height: 13.62px;
}

.organization-div{
  display: flex;
  flex-direction: row;
  width: 305px;
  min-height: 13px;
  gap: 5px;
}

.short-organization-div{
  display: flex;
  flex-direction: row;
  width: 180px;
  height: 13px;
  gap: 5px;
}

.organization-text{
  font-weight: 400;
  font-size: calc(var(--global-font-size) - 1px);
  line-height: 12.6px;
}

.period-text{
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: calc(var(--global-font-size) - 2px);
  line-height: 1.2;
  color: #666666;
  gap: 4px;
}

.description-div{
  display: flex;
  width: 305px;
  padding: 2px 0px 0px 0px;
}

.description-text{
  font-weight: 400;
  font-size: calc(var(--global-font-size) - 1px);
  line-height: 12.6px;
}
.description-text *{
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.right-col{
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 20px;
}

</style>
