<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from "vue";

import DOMPurify from "dompurify";
import { FirebaseError } from "firebase/app";
import { getAuth, updatePassword } from "firebase/auth";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { show } = defineProps<{ show: boolean }>();
const emit = defineEmits(["close"]);

const newPassword = ref("");
const confirmPassword = ref("");
const errorMessage = ref<string | null>(null);
const successMessage = ref<string | null>(null);

const closePopup = () => {
  emit("close");
  newPassword.value = "";
  confirmPassword.value = "";
  errorMessage.value = null;
  successMessage.value = null;
};

const changePassword = async () => {
  errorMessage.value = null;
  successMessage.value = null;

  if (newPassword.value !== confirmPassword.value) {
    errorMessage.value = t("Account.PasswordsDoNotMatch");
    return;
  }

  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      await updatePassword(user, DOMPurify.sanitize(newPassword.value));
      successMessage.value = t("Account.PasswordChangedSuccessfully");
      closePopup();
    } catch (error: unknown) {
      if (error instanceof FirebaseError || error instanceof Error) {
        errorMessage.value = error.message;
      } else {
        errorMessage.value = t("Account.UnknownErrorOccurred");
      }
    }
  } else {
    errorMessage.value = t("Account.NoUserSignedIn");
  }
};

watch(() => show, (newVal) => {
  if (!newVal) {
    newPassword.value = "";
    confirmPassword.value = "";
    errorMessage.value = null;
    successMessage.value = null;
  }
});
</script>

<template>
  <div v-if="show" class="popup-overlay">
    <div class="popup-content">
      <button class="close-button" @click="closePopup">×</button>
      <h2>{{ t("Account.ChangePassword") }}</h2>
      <form @submit.prevent="changePassword">
        <div class="input-group">
          <label for="new-password">{{ t("Account.NewPassword") }}</label>
          <input id="new-password" v-model="newPassword" type="password" required />
        </div>
        <div class="input-group">
          <label for="confirm-password">{{ t("Account.ConfirmNewPassword") }}</label>
          <input id="confirm-password" v-model="confirmPassword" type="password" required />
        </div>
        <div class="button-group">
          <button type="submit" class="confirm-button">{{ t("Account.ChangePassword") }}</button>
          <button type="button" class="cancel-button" @click="closePopup">{{ t("Account.Cancel") }}</button>
        </div>
      </form>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <div v-if="successMessage" class="success">{{ successMessage }}</div>
    </div>
  </div>
</template>

<style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .input-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }

  .confirm-button {
    background-color: #f56565;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
  }

  .cancel-button {
    background-color: #e2e8f0;
    color: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
  }

  .confirm-button:hover {
    background-color: #c53030;
  }

  .cancel-button:hover {
    background-color: #cbd5e0;
  }

  .error {
    color: red;
    margin-top: 10px;
    text-align: center;
  }

  .success {
    color: green;
    margin-top: 10px;
    text-align: center;
  }
  </style>
