import type { SelectedTemplate } from "@/composables/types";

export const createInitialCvData = () => {return{
  contact: {},
  educations: [],
  experiences: [],
  projects: [],
  skills: [],
  certifications: [],
  honors: [],
  languages: [],
  publications: [],
  references: [],
  hobbies: [],
  voluntaries: [],
  exhibitions: [],
  showSkillLevels: true,
  showLanguageLevels: true,
  selectedTemplate: "Default" as SelectedTemplate,
  resumeColor: "grey",
  resumeName: "",
  resumeFontFamily: "Montserrat",
  resumeFontSize: 10,
};};