import type { Ref } from "vue";
import { ref } from "vue";

import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";

import { signOutUser } from "@/auth/authService";
import { API_URL } from "@/config";

import type { HttpResponse } from "./types";

const signOut = async () => {
  try {
    await signOutUser(true);
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error);
      alert(`Sign out failed: ${error.message}`);
    } else {
      console.error("Unexpected error", error);
      alert("Sign out failed due to an unexpected error.");
    }
  }
};

const useHttp = <T>(url: string, options?: AxiosRequestConfig ): HttpResponse<T> => {
  const data: Ref<T | null> = ref(null);
  const error: Ref<string | null> = ref(null);
  const loading: Ref<boolean> = ref(false);

  const execute = async (...args: unknown[]): Promise<T | null> => {
    loading.value = true;
    error.value = null;
    try {
      const csrfResponse = await axios.get(API_URL + "/csrf-token", { withCredentials: true });
      const csrfToken = csrfResponse.data.csrfToken;

      const jwt = localStorage.getItem("jwt");
      if (!jwt) {
        throw new Error("JWT token not found");
      }
      const response: AxiosResponse<T> = await axios({
        url,
        ...options,
        data: args.length > 0 ? args[0] : {},
        headers: {
          "Authorization": `Bearer ${jwt}`,
          "X-CSRF-Token": csrfToken,
          ...options?.headers,
        },
        withCredentials: true,
      });
      data.value = response.data;
      return response.data;
    } catch (err: unknown) {
      if (axios.isAxiosError(err) && err.response && err.response.status === 403) {
        await signOut();
      } else {
        error.value = err instanceof Error ? err.message : "Unknown error occurred";
        console.error("HTTP request failed:", err);
      }
      return null;
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    loading,
    execute,
  };
};

export default useHttp;
