<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();
    console.log(children[0].getBoundingClientRect());
    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <div class="top-block" :style="{ backgroundColor: cvData.resumeColor }"></div>
    <section class="resume">
      <div class="center-container">
        <div v-if="cvData.contact?.firstName || cvData.contact?.lastName" class="person-name"
             :style="{ color: cvData.resumeColor }">
          {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
        </div>
        <div class="personal-details">
          <div v-if="cvData.contact?.jobTitle" class="job-title">{{ cvData.contact?.jobTitle }}</div>
          <div v-if="cvData.contact?.phone" class="personal-details-detail">{{ cvData.contact?.phone }}</div>
          <div v-if="cvData.contact?.email" class="personal-details-detail">{{ cvData.contact?.email }}</div>
          <div v-if="cvData.contact?.city" class="personal-details-detail">{{ cvData.contact?.city }}</div>
          <div v-if="cvData.contact?.country" class="personal-details-detail">{{ cvData.contact?.country }}</div>
          <div v-if="cvData.contact?.address" class="personal-details-detail">{{ cvData.contact?.address }}</div>
          <div v-if="cvData.contact?.birthDate" class="personal-details-detail">{{ cvData.contact?.birthDate }}</div>
        </div>
        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact?.description)" class="descriptions"
             v-html="cvData.contact?.description">
        </div>
        <div :style="{ backgroundColor: cvData.resumeColor }" class="line"></div>
        <div class="side-container">
          <div class="left-side">
            <div class="vertical-adjuster">
              <div v-if="cvData.experiences && cvData.experiences.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
                <div class="section-title">{{ t('Experience') }}</div>
                <div v-for="(experience, index) in cvData.experiences" :key="experience.id">
                  <div v-if="experience.title" class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ experience.title }}</div>
                  <div class="horizontal-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                    <div v-if="experience.company" class="mini-title">{{ experience.company }}</div>
                    <div v-if="experience.company && experience.location" class="mini-title">,&nbsp;</div>
                    <div v-if="experience.location" class="mini-title">{{ experience.location }}</div>
                    <div v-if="experience.location && (experience.startDate || experience.startDate)" class="mini-title">,&nbsp;</div>
                    <div v-if="experience.startDate" class="mini-title">{{ experience.startDate }}</div>
                    <div v-if="experience.startDate && experience.endDate" class="mini-title">&nbsp;-&nbsp;</div>
                    <div v-if="experience.endDate" class="mini-title">{{ experience.endDate }}</div>
                  </div>
                  <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="descriptions"
                       v-html="experience.description">
                  </div>
                  <div v-if="index !== cvData.experiences.length - 1" class="padding-adder"></div>
                </div>
              </div>
              <div v-if="cvData.projects && cvData.projects.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Projects') }}</div>
                <div v-for="(project, index) in cvData.projects" :key="project.id">
                  <div class="horizontal-adjuster">
                    <div v-if=" project.title" class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px); ">{{ project.title }}</div>
                    <div v-if="project.title && (project.startDate || project.startDate)" class="mini-title">&nbsp;|&nbsp;</div>
                    <div v-if="project.startDate" class="mini-title">{{ project.startDate }}</div>
                    <div v-if="project.startDate && project.endDate" class="mini-title">&nbsp;-&nbsp;</div>
                    <div v-if="project.endDate" class="mini-title">{{ project.endDate }}</div>
                  </div>
                  <div v-if="project.description && containsTextOutsideHtml(project.description)" class="descriptions"
                       v-html="project.description">
                  </div>
                  <div v-if="index !== cvData.projects.length - 1" class="padding-adder"></div>
                </div>
              </div>
              <div v-if="cvData.certifications && cvData.certifications.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Certifications') }}</div>
                <div v-for="(certification, index) in cvData.certifications" :key="certification.id">
                  <div class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px); ">{{ certification.course }}</div>
                  <div class="vertical-adjuster">
                    <div v-if="certification.institute" class="mini-title">{{ certification.institute }}</div>
                    <div v-if="certification.startDate" class="mini-title"> | {{ certification.startDate }} - {{ certification.endDate }}</div>
                  </div>
                  <div v-if="index !== cvData.certifications.length - 1" class="padding-adder"></div>
                </div>
              </div>
              <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Voluntary Activities') }}</div>
                <div v-for="(voluntary) in cvData.voluntaries" :key="voluntary.id">
                  <div v-if="voluntary.description && containsTextOutsideHtml(voluntary.description)" class="descriptions"
                       v-html="voluntary.description">
                  </div>
                </div>
              </div>
              <div v-if="cvData.references && cvData.references.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('References') }}</div>
                <div v-for="(reference) in cvData.references" :key="reference.id">
                  <div class="horizontal-adjuster">
                    <div v-if="reference.name" class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px); padding-right: 5px;">{{ reference.name }}</div>
                    <div v-if="reference.company" class="mini-title">{{ reference.company }}</div>
                  </div>
                  <div class="horizontal-adjuster">
                    <div v-if="reference.phone"  class="mini-title" style="padding-right: 5px;">{{ reference.phone }}</div>
                    <div v-if="reference.email"  class="mini-title" >{{ reference.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="vertical-adjuster">
              <div v-if="cvData.educations && cvData.educations.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
                <div class="section-title">{{ t('Education') }}</div>
                <div v-for="(education, index) in cvData.educations" :key="education.id">
                  <div v-if="education.school" class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ education.school }}</div>
                  <div class="vertical-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                    <div v-if="education.degree" class="mini-title">{{ education.degree }}</div>
                    <div v-if="education.location" class="mini-title">{{ education.location }}</div>
                    <span v-if="education.startDate || education.endDate" class="mini-title">
                      <span v-if="education.startDate">{{ education.startDate }}</span>
                      <span v-if="education.startDate && education.endDate">&nbsp;-&nbsp;</span>
                      <span v-if="education.endDate">{{ education.endDate }}</span>
                    </span>
                  </div>
                  <div v-if="education.description && containsTextOutsideHtml(education.description)" class="descriptions"
                       v-html="education.description">
                  </div>
                  <div v-if="index !== cvData.educations.length - 1" class="padding-adder"></div>
                </div>
              </div>
              <div v-if="cvData.skills && cvData.skills.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
                <div class="section-title">{{ t('Skills') }}</div>
                <div v-for="skill in cvData.skills" :key="skill.id" class="horizontal-adjuster">
                  <div v-if="skill.name" class="mini-title" style="font-weight: 300; font-size: calc(var(--global-font-size) + 2px);">{{ skill.name }}</div>
                  <div v-if="skill.level && cvData.showSkillLevels" class="mini-title">{{ skill.level }}</div>
                </div>
              </div>
              <div v-if="cvData.languages && cvData.languages.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
                <div class="section-title">{{ t('Languages') }}</div>
                <div v-for="language in cvData.languages" :key="language.id" class="horizontal-adjuster">
                  <div v-if="language.name" class="mini-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ language.name }}</div>
                  <div v-if="language.level && cvData.showLanguageLevels" style="padding-left: 5px;" class="mini-title">{{ language.level }}</div>
                </div>
              </div>
              <div v-if="cvData.hobbies && cvData.hobbies.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Hobbies') }}</div>
                <div v-for="(hobby) in cvData.hobbies" :key="hobby.id">
                  <div v-if="hobby.description && containsTextOutsideHtml(hobby.description)" class="descriptions"
                       v-html="hobby.description">
                  </div>
                </div>
              </div>
              <div v-if="cvData.honors && cvData.honors.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Honors & Awards') }}</div>
                <div v-for="(honor) in cvData.honors" :key="honor.id" class="padders">
                  <div v-if="honor.description && containsTextOutsideHtml(honor.description)" class="descriptions"
                       v-html="honor.description">
                  </div>
                </div>
              </div>
              <div v-if="cvData.publications && cvData.publications.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Publications') }}</div>
                <div v-for="(publication) in cvData.publications" :key="publication.id">
                  <div v-if="publication.description && containsTextOutsideHtml(publication.description)" class="descriptions"
                       v-html="publication.description">
                  </div>
                </div>
              </div>
              <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" :style="{ color: cvData.resumeColor}" class="main-section-divs">
                <div class="section-title">{{ t('Exhibitions') }}</div>
                <div v-for="(exhibition) in cvData.exhibitions" :key="exhibition.id">
                  <div v-if="exhibition.description && containsTextOutsideHtml(exhibition.description)" class="descriptions"
                       v-html="exhibition.description">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
:root {
  --global-font-size: 10px;
}
.resume {
  width: 595px;
  max-width: 98%;
  padding-right: 1%;
  padding-left: 1%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  text-align: start !important;
  min-height: 700px;
}

.top-block {
  width: 595px;
  height: 35px;
  background-color: orange;
  box-sizing: border-box;
}
.center-container{
  display: flex;
  flex-direction: column;
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 10px;
}
.person-name {
  font-size: calc(var(--global-font-size) + 10px);
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
}
.personal-details {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  row-gap: 2px;
  font-size: calc(var(--global-font-size) + 2px);
  padding-bottom: 0px;
}
.personal-details > *:not(:last-child)::after {
  content: "|";
  margin-left: 5px;
}
.personal-details-detail {
  padding: 0 2px;
  font-weight: 600;
}

.personal-details-separator {
  margin: 0 2px;
}
.line {
  height: 2px;
  background-color: var(--resume-color);
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.left-side,
.right-side {
  display: flex;
  flex-direction: column;
}

.side-container {
  display: flex;
  width: 593px;
  flex-direction: row;
}
.left-side {
  width: 60%;
  align-items: flex-start;
}

.right-side {
  width: 34%;
  padding-left: 8%;
  align-items: flex-start;
  background-color: white;
}
.horizontal-adjuster {
  display: flex;
  flex-direction: row;
  padding-bottom: 3px;
}

.vertical-adjuster {
  display: flex;
  flex-direction: column;
}
.main-section-divs {
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding-bottom: 5px;
}
.mini-title {
    font-size: calc(var(--global-font-size) + 2px);
    color: black;
}
.section-title {
    font-size: calc(var(--global-font-size) + 6px);
    font-weight: 700;
    padding-bottom: 3px;
    padding-top: 12px;
}
.padding-adder{
    padding-bottom: 5px;
}
.padding-adder:not(:last-child) {
  padding-bottom: 0;
}
.descriptions {
    font-size: calc(var(--global-font-size) + 2px);
    color: black;
}
.job-title{
  font-weight: 600;
  font-size: calc(var(--global-font-size) + 2px);
}
.descriptions * {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.padders{
  padding-bottom: 8px;
}
  </style>

