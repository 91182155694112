import type { UserDetails } from "@/composables/types";
import useHttp from "@/composables/useHttp";
import { API_URL } from "@/config";

export const createSubscription = async (params: {
  uid: string;
  paymentMethodId: string;
  country: string;
  premiumType: string;
}) => {
  const { data, error, loading, execute } = useHttp<{ clientSecret: string, paymentIntentStatus: string, subscriptionId: string }>(
    API_URL + "/create-subscription",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  const result = await execute(params);
  return { data, error, loading, result };
};

export const createPayPalCheckoutSession = async (params: {
  uid: string;
  country: string;
  premiumType: string;
}) => {
  const { data, error, loading, execute } = useHttp<{ sessionId: string, checkoutUrl: string }>(
    API_URL + "/create-paypal-checkout-session",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  const result = await execute(params);
  return { data, error, loading, result };
};

export const cancelSubscription = async (subscriptionId: string) => {
  const { data, error, loading, execute } = useHttp<{ message: string, subscriptionId: string, updatedUserData: UserDetails }>(
    API_URL + "/cancel-subscription",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  const result = await execute({ subscriptionId });
  return { data, error, loading, result };
};
