<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();

const router = useRouter();

const goIndexPage = async () => {
  router.push({ path: "/" });
};
const teamMembers = [
  { id: "image1", name: "Burak Kiran", role: t("Co-Founder") },
  { id: "image2", name: "Atilla Okumus", role: t("Co-Founder") },
  { id: "image3", name: "Iris Özkaya", role: t("Co-Founder") },
];
</script>

<template>
  <div class="container-about-us">
    <div class="about-us-text">{{ t('AboutUs.Title') }}</div>
    <div class="about-us-description">{{ t('AboutUs.Description') }}</div>
    <div class="about-us-image"></div>
  </div>
  <div class="container-our-mission">
    <div class="our-mission-text">{{ t('AboutUs.MissionTitle') }}</div>
    <div class="our-mission-description">{{ t('AboutUs.MissionDescription') }}</div>
    <div class="horizontal-divider"></div>
  </div>
  <div class="container-cv-create">
    <div class="container-cv-create-text">
      <div class="cv-create-text">{{ t('AboutUs.CreateCVText') }}</div>
    </div>
    <div class="cv-create-image"></div>
  </div>
  <div class="team-text">{{ t('AboutUs.TeamTitle') }}</div>
  <div class="team-description">{{ t('AboutUs.TeamDescription') }}</div>
  <div class="container-team">
    <div v-for="member in teamMembers" :key="member.id" class="container-team-image">
      <div :id="member.id" class="image"></div>
      <div class="image-text">{{ member.name }}</div>
      <div class="image-subtext">{{ member.role }}</div>
    </div>
  </div>
  <div class="container-card">
    <div class="card-get-started">
      <div class="left-half">
        <div class="container-get-started">
          <div class="get-started-text">{{ t('AboutUs.GetStartedTitle') }}</div>
          <div class="get-started-description">{{ t('AboutUs.GetStartedDescription') }}</div>
          <button class="button-get-started" @click="goIndexPage">{{ t('AboutUs.GetStartedButton') }}</button>
        </div>
      </div>
      <div class="right-half"></div>
    </div>
  </div>
</template>

<style scoped>
.container-about-us {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  padding-left: 25%;
  padding-right: 25%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.about-us-text, .our-mission-text, .team-text, .get-started-text{
  color: #030303;
  font-size: 48px;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 62px;
  text-align: center;
  padding-bottom: 30px;
}
.about-us-description, .our-mission-description, .team-description, .get-started-description{
  color: #030303;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 26px;
  text-align: center;
  padding-bottom: 20px;
}
.about-us-image {
  position: relative;
  width: 400px;
  height: 400px;
  background-image: url('https://assets.api.uizard.io/api/cdn/stream/7e8b3a1d-5f7b-478f-9714-5973f8f10ff8.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container-our-mission {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  padding-left: 25%;
  padding-right: 25%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.horizontal-divider {
  position: relative;
  width: 770px;
  height: 2px;
  background-color: #f0f0f0;
  border-radius: 2px;
}
.container-cv-create {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}
.container-cv-create-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-right: 15%;
    padding-top: 70px;
    width: 80%;
}

.cv-create-text{
  color: #030303;
  font-size: 48px;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 62px;
  text-align: center;
  padding-bottom: 30px;
}
.cv-create-image {
  position: relative;
  width: 400px;
  height: 400px;
  background-image: url('https://assets.api.uizard.io/api/cdn/stream/31e9fcbe-2ffe-4df1-aaee-160fcdc7c8e6.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container-team {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 100px;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 100px;
}
.container-team-image {
  width: 160px;
  height: 161px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image {
  width: 160px;
  height: 161px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-text {
  color: #030303;
  font-size: 20px;
  line-height: 26px;
  font-family: "Montserrat";
  text-align: center;
  padding-top: 10px;
}

#image1 {
  background-image: url('@/assets/burak_photo.jpg');
}

#image2 {
  background-image: url('@/assets/ati_foto.JPG');
}

#image3 {
  background-image: url('@/assets/iris_photo.jpg');
}
.container-card {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-left: 20%;
  padding-right: 20%;
  border-radius: 26px;
}
.card-get-started {
  position: relative;
  width: 1151px;
  height: 451px;
  background-color: #f0f0f0;
  border: solid #f2f2f2;
  display: flex;
  flex-direction: row;
}

.left-half {
  background-color: #f0f0f0;
}
.left-half, .right-half {
  flex: 1;
  height: 100%;
  width: 100%;
}

.right-half {
  background-image: url('https://images.unsplash.com/photo-1483478550801-ceba5fe50e8e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwzfHxUb29scyUyQyUyMFRlY2glMkMlMjBTb2x1dGlvbnN8ZW58MXx8fHwxNzExMjQzMTIwfDA&ixlib=rb-4.0.3&q=80&w=1080');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.container-get-started{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    gap: 20px;
}
.get-started-text{
    padding-top: 20px;
}

.button-get-started {
  cursor: pointer;
  top: 951px;
  left: 59px;
  width: 150px;
  height: 40px;
  padding: 10px 20px;
  border: 0;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #5438da;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 19px;
  outline: none;
  text-align: center;
  box-sizing: border-box;
}
</style>