
<script setup lang="ts">

import { useEvaluationUtils } from "@/utils/getEvaluationScore";

defineProps<{
  generalScore: number,
  generalScoreExplanation: string
}>();

const { getScoreColor, getScoreRange } = useEvaluationUtils();

const scoreToDashArray = (score: number): string => {
  const circumference = 283;
  const filledLength = (score / 100) * circumference;

  return `${filledLength} ${circumference - filledLength}`;
};

</script>

<template>
  <div class="cv-evaluated">
    <div class="cv-evaluation-section-scores">
      <div class="score-card">
        <svg class="score-circle" viewBox="0 0 100 100">
          <circle class="circle-background" cx="50" cy="50" r="45"></circle>
          <circle
            class="circle-score"
            :stroke="getScoreColor(generalScore.toString())"
            cx="50"
            cy="50"
            r="45"
            :stroke-dasharray="scoreToDashArray(generalScore)"
            transform="rotate(-90 50 50)"
          ></circle>
          <text class="score-text" x="50" y="55" dominant-baseline="middle" text-anchor="middle">{{ Math.round(generalScore) }}%</text>
        </svg>
        <div class="score-range">{{ getScoreRange(generalScore.toString()) }}</div>
      </div>
    </div>
    <div class="explanation-div">
      <span>{{ generalScoreExplanation }}</span>
    </div>
  </div>
</template>

  <style scoped>
  .explanation-div{
    width: 60%;
  }
  .cv-evaluated{
    display: flex;
    flex-direction: row;
    width: 492px;
    min-height: 177px;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px;
}
  .cv-evaluation-section-scores {
  display: flex;
    width: 100px;
}

.score-card {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.circle-background {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 10;
}

.circle-score {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s ease;
}

.score-text {
  font-size: 24px;
  fill: #333;
}

.score-range {
  margin-top: 10px;
}

.score-range.Poor {
  color: #f21d0d;
}

.score-range.Fair {
  color: #ff4f17;
}

.score-range.Average {
  color: #ffc107;
}

.score-range.Good {
  color: #67bd6a;
}

.score-range.Excellent {
  color: #429846;
}

  </style>
