import { computed } from "vue";

import { useStore } from "vuex";

export const useAdditionalBackgroundColor = () => {
  const store = useStore();

  const certificationBackgroundColor = computed(() =>
    store.state.cvData.certifications?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const projectBackgroundColor = computed(() =>
    store.state.cvData.projects?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const languageBackgroundColor = computed(() =>
    store.state.cvData.languages?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const honorBackgroundColor = computed(() =>
    store.state.cvData.honors?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const referenceBackgroundColor = computed(() =>
    store.state.cvData.references?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const publicationBackgroundColor = computed(() =>
    store.state.cvData.publications?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const hobbyBackgroundColor = computed(() =>
    store.state.cvData.hobbies?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const exhibitionBackgroundColor = computed(() =>
    store.state.cvData.exhibitions?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  const voluntaryBackgroundColor = computed(() =>
    store.state.cvData.voluntaries?.length > 0
      ? "rgba(95, 82, 210, 0.7)"
      : "rgb(212, 212, 212)",
  );

  return {
    certificationBackgroundColor,
    projectBackgroundColor,
    languageBackgroundColor,
    honorBackgroundColor,
    referenceBackgroundColor,
    publicationBackgroundColor,
    hobbyBackgroundColor,
    exhibitionBackgroundColor,
    voluntaryBackgroundColor,
  };
};
