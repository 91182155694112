import type { Education, Experience, Certification, Project } from "@/composables/types";

export const formatDate = <T extends Education | Experience | Certification | Project>(
  event: Event,
  target: T,
  sectionKey: keyof Pick<T, "startDate" | "endDate">,
) => {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, "");

  if (value.length > 2) {
    value = `${value.slice(0, 2)}/${value.slice(2, 6)}`;
  }

  if (/^(0[1-9]|1[0-2])\/?([0-9]{4})?$/.test(value)) {
    target[sectionKey] = value;
  }

  input.value = value;
};
