<script setup lang="ts">
import { ref , defineEmits, defineProps } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { updateUserDetails } from "@/auth/useAuth";
import { initiaCvData } from "@/composables/const";
import { BASE_URL } from "@/config";
import { libraryEntryExist, createLibraryEntry } from "@/services/libraryService";
import { saveUserInfo, userExist } from "@/services/userService";

import { signIn, signUp, signInWithGoogle, signOutUser, sendVerificationEmail, forgotPassword } from "../auth/authService";

const { t, locale } = useI18n();

const emits = defineEmits(["update:visible"]);

const props = defineProps({
  isVisible: Boolean,
  navigateTo: String,
});
const router = useRouter();

const email = ref("");
const password = ref("");
const termsAccepted = ref(true);
const receiveUpdates = ref(true);
const view = ref("signin");
const loading = ref(false);
const verificationSent = ref(false);
const resendDisabled = ref(false);
const errorMessage = ref("");
const successMessage = ref("");
const resendDisabledPassword = ref(false);

const forgotPasswordAuth = async () => {

  errorMessage.value = "";
  successMessage.value = "";

  if (!email.value) {
    errorMessage.value = t("Auth.EnterEmail");
    return;
  }

  try {
    await forgotPassword(email.value, locale.value);
    successMessage.value = t("Auth.PasswordResetSent");

    resendDisabledPassword.value = true;

    setTimeout(() => {
      resendDisabledPassword.value = false;
    }, 60000);

  } catch (error) {
    errorMessage.value = t("Auth.ErrorSendingReset");
    console.error("Error in forgotPassword:", error);
  }
};

const setView = (newView: string) => {
  view.value = newView;
};

const routeTo = async (uid: string) => {

  if(props.navigateTo === "creation"){
    const cvExist = await libraryEntryExist(uid);
    if(cvExist){
      router.push({ path: "/library" });
    }
    else{
      const { result: cvData } = await createLibraryEntry(initiaCvData, uid);
      router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
    }

  }
  else if(props.navigateTo === "existing"){

    router.push({ name: "CvExisting" });

  }
  else if(props.navigateTo === "evaluation"){
    router.push({ name: "CvEvalutation" });
  }

};

const login = async () => {
  loading.value = true;
  try {
    const { userCredential , emailVerified } = await signIn(email.value, password.value);
    if (!emailVerified){
      loading.value = false;
      view.value = "verify";
      verificationSent.value = true;
      return;
    }
    emits("update:visible", false);
    routeTo(userCredential.user.uid);
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error);
      alert(`Login failed: ${error.message}`);
    } else {
      console.error("Unexpected error", error);
      alert("Login failed due to an unexpected error.");
    }
  }
  loading.value = false;
};

const register = async () => {
  loading.value = true;
  try {
    const userCredential = await signUp(email.value, password.value);
    const { user } = userCredential;
    const userInfo = {
      uid: user.uid,
      email: user.email || "",
      displayName: user.displayName,
      accountStatus: "Free",
      firstName: "",
      lastName: "",
      termsAndConditions: termsAccepted.value,
      updatesAndTips: receiveUpdates.value,
      evaluationCredit: 1,
      enhanceCredit: 0,
      existingCredit: 1,
      premiumType: "none",
      subscriptionStatus: "not active",
      locale: locale.value,
    };

    const { result: saveUserResponse } = await saveUserInfo(userInfo);
    if(!saveUserResponse){
      signOutUser();
    }
    resendDisabled.value = true;
    setTimeout(() => (resendDisabled.value = false), 60000);
    view.value = "verify";
    verificationSent.value = true;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error);
      alert(`Registration failed: ${error.message}`);
    } else {
      console.error("Unexpected error", error);
      alert("Registration failed due to an unexpected error.");
    }
  }
  loading.value = false;
};

const resendVerificationEmail = async () => {
  const { userCredential, emailVerified } = await signIn(email.value, password.value);
  if (userCredential && !emailVerified) {
    try {
      await sendVerificationEmail(userCredential, `${BASE_URL}/verify-account?uid=${userCredential.user.uid}`, locale.value);
      resendDisabled.value = true;
      setTimeout(() => (resendDisabled.value = false), 20000);
      console.log(resendDisabled.value);
    } catch (error) {
      resendDisabled.value = true;
      setTimeout(() => (resendDisabled.value = false), 20000);
    }
  }
  else{
    return;
  }
};

const closeModal = () => {
  emits("update:visible", false);
  view.value = "signin";
};

const authenticateWithSocialMedia = async (str: string) => {
  if (str === "Google") {
    try {
      const userCredential = await signInWithGoogle();

      const { user } = userCredential;
      const userInfo = {
        uid: user.uid,
        email: user.email || "",
        displayName: user.displayName,
        accountStatus: "Free",
        firstName: "",
        lastName: "",
        termsAndConditions: termsAccepted.value,
        updatesAndTips: receiveUpdates.value,
        evaluationCredit: 1,
        enhanceCredit: 0,
        existingCredit: 1,
        premiumType: "none",
        subscriptionStatus: "not active",
        locale: locale.value,
      };

      const { result: doesUserExist } = await userExist(user.uid);
      if (!doesUserExist?.exists) {
        const { result: saveUserResponse } = await saveUserInfo(userInfo);
        if(!saveUserResponse){
          signOutUser();
        }
        updateUserDetails(userInfo);
      }

      emits("update:visible", false);
      routeTo(user.uid);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error);
        alert(`Login failed: ${error.message}`);
      } else {
        console.error("Unexpected error", error);
        alert("Login failed due to an unexpected error.");
      }
    }
  }
};

</script>

<template>
  <div v-if="isVisible" class="modal">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <div class="modal-content auth-container">
      <div style="display: flex; justify-content: space-between;">
        <span :style="{ visibility: view !== 'forgot' ? 'hidden' : 'visible' }" class="close" @click="() => setView('signin')">←</span>
        <span class="close" @click="closeModal">&times;</span>
      </div>

      <div v-if="view === 'signin'" class="form-container">
        <p class="signUpText">{{ t("Auth.WelcomeBack") }}</p>
        <div class="social-login-buttons">
          <button class="social-button google" @click="authenticateWithSocialMedia('Google')">
            <img src="@/assets/google.svg" alt="Google" class="icon" /> Google
          </button>
          <!-- <button class="social-button facebook" @click="authenticateWithSocialMedia('Facebook')">
            <img src="@/assets/facebook.svg" alt="Facebook" class="icon" /> Facebook
          </button>
          <button class="social-button linkedin" @click="authenticateWithSocialMedia('LinkedIn')">
            <img src="@/assets/linkedin.svg" alt="LinkedIn" class="icon" /> LinkedIn
          </button> -->
        </div>
        <p class="orText">{{ t("Auth.OrSignWithEmail") }}</p>
        <input v-model="email" type="email" :placeholder="t('Auth.YourEmail')" />
        <input v-model="password" type="password" :placeholder="t('Auth.YourPassword')" />

        <button class="accountButton" @click="login">{{ t("Auth.Login") }}</button>

        <p class="alreadyText">{{ t("Auth.DontHaveAccount") }}
          <span class="link" @click="setView('signup')">{{ t("Auth.SignUp") }}</span>
        </p>
        <span class="link-forget" @click="setView('forgot')">{{ t("Auth.ForgotPassword") }}</span>
      </div>

      <div v-else-if="view === 'verify'" class="form-container">
        <p class="alreadyText">{{ t("Auth.VerificationEmailSentMessage") }}</p>
        <p class="alreadyText">{{ t("Auth.CheckEmailInstructions") }}</p>
        <button :disabled="resendDisabled" class="accountButton" @click="resendVerificationEmail()">
          {{ t("Auth.ResendVerificationEmail") }}
        </button>
        <p v-if="resendDisabled" class="alreadyText">{{ t("Auth.ResendInProgress") }}</p>
      </div>

      <div v-else-if="view === 'forgot'" class="form-container">
        <p class="signUpText">{{ t("Auth.ForgotPassword") }}</p>
        <p class="orText">{{ t("Auth.ForgotText") }}</p>
        <input v-model="email" type="email" :placeholder="t('Auth.YourEmail')" />
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        <button :disabled="resendDisabledPassword" class="accountButton" @click="forgotPasswordAuth()">
          {{ t("ContactUs.Send") }}
        </button>
        <p v-if="resendDisabledPassword" class="alreadyText">{{ t("Auth.ResendInProgress") }}</p>
      </div>

      <div v-else class="form-container">
        <p class="signUpText">{{ t("Auth.CreateYourAccount") }}</p>
        <div class="social-login-buttons">
          <button class="social-button google" @click="authenticateWithSocialMedia('Google')">
            <img src="@/assets/google.svg" alt="Google" class="icon" /> Google
          </button>
          <!-- <button class="social-button facebook" @click="authenticateWithSocialMedia('Facebook')">
            <img src="@/assets/facebook.svg" alt="Facebook" class="icon" /> Facebook
          </button>
          <button class="social-button linkedin" @click="authenticateWithSocialMedia('LinkedIn')">
            <img src="@/assets/linkedin.svg" alt="LinkedIn" class="icon" /> LinkedIn
          </button> -->
        </div>
        <p class="orText">{{ t("Auth.OrSignUpWithEmail") }}</p>
        <input v-model="email" type="email" :placeholder="t('Auth.YourEmail')" />
        <input v-model="password" type="password" :placeholder="t('Auth.YourPassword')" />
        <div class="checkbox-container">
          <label for="terms">{{ t("Auth.ByCLicking") }}<a href="/terms-of-service">{{ t("Auth.TermsOfService") }}</a> {{ t("Auth.And") }} <a href="/privacy-policy">{{ t("Auth.PrivacyPolicy") }}</a></label>
        </div>
        <button class="accountButton" @click="register">{{ t("Auth.CreateAnAccount") }}</button>
        <p class="alreadyText">{{ t("Auth.AlreadyAccount") }}
          <span class="link" @click="setView('signin')">{{ t("Auth.SignIn") }}</span>
        </p>
      </div>

    </div>
  </div>
</template>

<style scoped>

.error-message{
  color: red;
  font-size: 12px;
}

.success-message{
  color: green;
  font-size: 12px;
}

.link-forget{
    color: #5438da;
    cursor: pointer;
    font-size: 12px;
}

.link-forget:hover {
  text-decoration: underline;
}

.link-forget:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
  pointer-events: none;
}

.auth-container {
    max-width: 487px;
  width: 100%;
  height: 595px;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fafafa;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

}

.signUpText{
    color: '#030303';
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 28px;
    height: 10px;
}

.alreadyText{
    color: '#858585';
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 16px;
    text-align: 'center';
    margin-bottom: 20px;
}
.orText{
    color: '#858585';
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 16px;
    text-align: 'center';
    margin-bottom: 30px;
    margin-top: 30px;
}

.toggle-buttons {
  margin-bottom: 20px;
}

.toggle-buttons button {
  padding: 10px 20px;
  border: 1px solid transparent;
  background-color: #c79dd7;
  margin-right: 5px;
  cursor: pointer;
}

.toggle-buttons button.active {
  background-color: #9a14ed;
  border-color: #badbcc;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

input {
  width: 368px;
  height: 42px;
  padding: 0px 8px;
  margin-bottom: 20px;
  border: 1px solid #909090;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat' ;
  font-weight: 400;
  line-height: 19px;
  outline: none;
  align-self: center;
  justify-self: center;

}

.accountButton{
    width: 368px;
    height: 40px;
    padding: 10px;
    background-color: #5438da;
    color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 19px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.accountButton:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

button:hover {
  background-color: #0056b3;
}

.link{
    color: #5438da;
    cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.checkbox-container {
  display: flex;
  align-items: center;
  width: 368px;
  justify-content: flex-start;
}

.checkbox-container input[type="checkbox"] {
    color: #909090;
    fill: #909090;
    width: 18px;
    height: 22px;
    margin-right: 20px;
}

.checkbox-container label {
 font-size: 12px;
 font-family: 'Montserrat';
 line-height: 16px;
 text-align: center;
 margin-bottom: 18px;
}

.checkbox-container label a {
  color: #5438da;
  text-decoration: underline;
}

.checkbox-container label a:hover {
  text-decoration: underline;
}

.social-login-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 10px;
}

.social-button {
width: 118px;
    height: 42px;
    border: 1px solid #909090;
  padding: 0px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #000000;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
}

.social-button .icon {
  height: 20px;
}

.google {
  background-color: #f7f5ff;
}

.facebook {
  background-color: #f7f5ff;
}

.linkedin {
  background-color: #f7f5ff;
}

.social-button:hover {
  opacity: 0.9;
  background-color: #cecece;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #5438da;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
</style>
