import { createI18n } from "vue-i18n";

import de from "./locales/de.json";
import en from "./locales/en.json";
import tr from "./locales/tr.json";

const messages = {
  en,
  de,
  tr,
};

const getUserLocale = (): string => {
  const userLocale = navigator.language || "en";
  const language = userLocale.split("-")[0];

  if (["de", "tr"].includes(language)) {
    return language;
  }

  return "en";
};

const i18n = createI18n({
  legacy: false,
  locale: getUserLocale(),
  fallbackLocale: "en",
  messages,
});

export default i18n;
