<script setup lang="ts">
import { ref, computed } from "vue";

import Fuse from "fuse.js";
import { useI18n } from "vue-i18n";

import HomeFaq from "@/views/home-view/HomeFaq.vue";
const name = ref("");
const email = ref("");
const message = ref("");

const { t } = useI18n();
const sendEmail = () => {
  if (!name.value && !email.value && !message.value) {
    window.location.href = "mailto:info@resumify-ai.com";
  } else {
    const mailtoLink = `mailto:info@resumify-ai.com?subject=Message from ${name.value}&body=Name: ${name.value}%0AEmail: ${email.value}%0A%0AMessage: ${message.value}`;
    window.location.href = mailtoLink;
  }
};
interface FAQ {
  questionKey: string;
  answerKey: string;
  showAnswer: boolean;
}

const faqsData = computed(() => [
  { questionKey: t("faqs.serviceOffered"), answerKey: t("faqs.serviceOfferedAnswer"), showAnswer: false },
  { questionKey: t("faqs.howToUpgrade"), answerKey: t("faqs.howToUpgradeAnswer"), showAnswer: false },
  { questionKey: t("faqs.paymentMethods"), answerKey: t("faqs.paymentMethodsAnswer"), showAnswer: false },
  { questionKey: t("faqs.cancelSubscription"), answerKey: t("faqs.cancelSubscriptionAnswer"), showAnswer: false },
  { questionKey: t("faqs.premiumTemplates"), answerKey: t("faqs.premiumTemplatesAnswer"), showAnswer: false },
  { questionKey: t("faqs.accountToUse"), answerKey: t("faqs.accountToUseAnswer"), showAnswer: false },
  { questionKey: t("faqs.techSupport"), answerKey: t("faqs.techSupportAnswer"), showAnswer: false },
  { questionKey: t("faqs.useMultipleDevices"), answerKey: t("faqs.useMultipleDevicesAnswer"), showAnswer: false },
  { questionKey: t("faqs.dataPrivacy"), answerKey: t("faqs.dataPrivacyAnswer"), showAnswer: false },
  { questionKey: t("faqs.editAfterCreate"), answerKey: t("faqs.editAfterCreateAnswer"), showAnswer: false },
  { questionKey: t("faqs.downloadResume"), answerKey: t("faqs.downloadResumeAnswer"), showAnswer: false },
]);

const searchQuery = ref("");

const options = {
  includeScore: true,
  keys: ["questionKey", "answerKey"],
  threshold: 0.4,
  minMatchCharLength: 1,
};

const fuse = computed(() => new Fuse(faqsData.value, options));

const filteredFaqs = computed((): FAQ[] => {
  const trimmedQuery = searchQuery.value.trim();

  if (!trimmedQuery) {
    return faqsData.value;
  }
  const results = fuse.value.search(trimmedQuery);

  return results.map(result => result.item);
});

</script>
<template>
  <div class="container-faq-top">
    <div class="left-container">
      <h1 class="heading">{{ t('faqs.heading') }}</h1>
      <p class="subtext">{{ t('faqs.subtext') }}</p>
      <div class="search-bar">
        <input v-model="searchQuery" type="text" :placeholder="t('faqs.searchPlaceholder')" class="search-input">
      </div>

      <div v-if="searchQuery && filteredFaqs.length">
        <HomeFaq :key="searchQuery" :faqs="filteredFaqs" class="search-results"/>
      </div>
    </div>
    <div class="right-container">
      <div class="image-container"></div>
    </div>
  </div>

  <HomeFaq :faqs="faqsData" class="all-faqs"/>
  <div class="container-faq-bottom">
    <p class="text-below-questions">{{ t('faqs.textBelowQuestions') }}</p>
    <div class="card-below">
      <div class="contact-us">
        <div>{{ t('faqs.askViaEmail') }}</div>
        <svg class="icon-mail" viewBox="0 0 24 24" @click="sendEmail">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
        </svg>
        <div style="margin-top: 10px;">{{ t('faqs.orText') }}</div>
      </div>
      <input v-model="name" type="text" class="input-field input-field-small" :placeholder="t('faqs.firstNamePlaceholder')">
      <input v-model="email" type="email" class="input-field input-field-medium" :placeholder="t('faqs.emailPlaceholder')">
      <textarea v-model="message" class="input-field input-field-large" :placeholder="t('faqs.messagePlaceholder')" style="resize: none;"></textarea>
      <button id="sendButton" class="submit-button" @click="sendEmail">{{ t('faqs.sendButton') }}</button>
    </div>
  </div>
</template>

<style scoped>
.container-faq-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  padding-top: 50px;
  margin: auto;
}

.left-container,
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-container{
  overflow-y: auto;
  overflow-x: hidden;
}
.right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-container {
  width: 807px;
  height: 584px;
  border-radius: 8px;
  background-image: url('https://assets.api.uizard.io/api/cdn/stream/770361b4-d9d6-40ab-9ab5-071058ab247e.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.heading {
  color: #030303;
  font-size: 40px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  margin-bottom: 20px;
}

.subtext {
  color: #0d0d0d;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 21px;
  text-align: center;
  margin-bottom: 20px;
}
.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  flex-grow: 1;
  height: 49px;
  padding: 0 12px;
  padding-right: 40px;
  font-size: 14px;
  border: 1px solid #5438da;
  border-radius: 8px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235438da"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
}

.search-button {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #5438da;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  color: #5438da;
  fill: #5438da;
}

.cta-button {
  cursor: pointer;
  width: 515px;
  height: 49px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border: 1px solid #5438da;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #ffffff;
  color: #141414;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-bottom: 20px;
}

.icon {
  font-size: 21px;
  width: 21px;
  height: 21px;
  color: #141414;
  fill: #141414;
}
.faq{
    margin-bottom: 40px;
}
.all-faqs{
    padding-bottom: 60px;
}

.text-below-questions {
  color: #0d0d0d;
  font-size: 18px;
  font-family: 'Montserrat';
  line-height: 23px;
  text-align: flex-start;
  width: 70%;
}
.container-faq-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding-top: 50px;
  margin: auto;
}
.card-below {
  width: 681px;
  height: 481px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
}

.input-field {
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 24px;
    outline: none;
    margin-top: 30px;
}

.input-field-small {
    width: 211px;
    height: 36px;
    align-items: flex-start;
    position: relative;
}

.input-field-medium, .input-field-large {
    width: 407px;
    height: 36px;
}

.input-field-large {
    height: 148px;
}

.submit-button {
    cursor: pointer;
    width: 120px;
    height: 36px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #5438da;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 24px;
    outline: none;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 30%;
}
.icon-mail {
    margin-top: 20px;
    color: #5438da;
    fill: #5438da;
    width: 40px;
    height: 40px;
    font-size: 25px;
    cursor: pointer;
  }
</style>