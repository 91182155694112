import type { CvData, Contact, SectionData } from "../composables/types";

export const validateInputs = (
  cvData: CvData,
  sectionItem?: SectionData | Contact,
): string[] => {
  const emptyFields: string[] = [];

  const checkProperty = (obj: Record<string, unknown>, prefix: string) => {
    for (const key in obj) {
      const value = obj[key];
      if (value === null || value === undefined || value === "") {
        emptyFields.push(`${key}`);
      } else if (typeof value === "object" && !Array.isArray(value)) {
        checkProperty(value as Record<string, unknown>, `${prefix}${key}.`);
      }
    }
  };

  if (sectionItem) {
    if ("firstName" in sectionItem && "lastName" in sectionItem && "email" in sectionItem) {

      checkProperty(sectionItem as Contact, "contact.");
    } else {

      checkProperty(sectionItem as Record<string, unknown>, "");
    }
  } else {
    if (cvData.contact) {
      checkProperty(cvData.contact, "contact.");
    }

    const sections: { [K in keyof CvData]?: CvData[K] } = {
      educations: cvData.educations,
      experiences: cvData.experiences,
      projects: cvData.projects,
      skills: cvData.skills,
      certifications: cvData.certifications,
      honors: cvData.honors,
      hobbies: cvData.hobbies,
      languages: cvData.languages,
      publications: cvData.publications,
      references: cvData.references,
    };

    for (const section in sections) {
      const sectionData = sections[section as keyof CvData];
      if (Array.isArray(sectionData)) {
        sectionData.forEach((item, index) => {
          checkProperty(item as Record<string, unknown>, `${section}[${index}].`);
        });
      }
    }

  }

  return emptyFields;
};
