import useHttp from "@/composables/useHttp";
import { API_URL } from "@/config";

export const enhanceSummary = async (summary: string, language: string, sectionString: string, jobTitle: string, uid: string) => {
  const { data, error, loading, execute } = useHttp<{processedDates: {
    ifStartDate: boolean;
    ifEndDate: boolean;
}, enhancedSummaries: string}>(API_URL + "/enhance/Summary", {
  method: "POST",
});

  const result = await execute({ summary, language, sectionString, jobTitle, uid });
  return { data, error, loading, result };
};

export const enhanceEducation = async (summary: string, language: string, sectionString: string, uid: string) => {
  const { data, error, loading, execute } = useHttp<{enhancedSummaries: string}>(API_URL + "/enhance/Education", {
    method: "POST",
  });

  const result = await execute({ summary, language, sectionString, uid });
  return { data, error, loading, result };
};

export const enhanceExperience = async (summary: string, language: string, sectionString: string, uid: string) => {
  const { data, error, loading, execute } = useHttp<{enhancedSummaries: string}>(API_URL + "/enhance/Experience", {
    method: "POST",
  });

  const result = await execute({ summary, language, sectionString, uid });
  return { data, error, loading, result };
};

export const enhanceProject = async (summary: string, language: string, sectionString: string, uid: string) => {
  const { data, error, loading, execute } = useHttp<{enhancedSummaries: string}>(API_URL + "/enhance/Project", {
    method: "POST",
  });

  const result = await execute({ summary, language, sectionString, uid });
  return { data, error, loading, result };
};

export const enhanceSkill = async (cvText: string, skills: string[], language: string, uid: string) => {
  const { data, error, loading, execute } = useHttp<{
    skillSuggestion: string[]
  }>(API_URL + "/enhance/Skills", {
    method: "POST",
  });

  const result = await execute({ text: cvText, skills, language, uid });
  return { data, error, loading, result };
};

export const checkATS = async (cvText: string, skills: string[], language: string, jobTitle: string) => {
  const { data, error, loading, execute } = useHttp<{
    skillSuggestion: string[]
  }>(API_URL + "/Ats", {
    method: "POST",
  });

  const result = await execute({ text: cvText, skills, language, jobTitle });
  return { data, error, loading, result };
};