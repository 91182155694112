<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const name = ref("");
const email = ref("");
const message = ref("");

const sendEmail = () => {
  const mailtoLink = `mailto:info@resumify-ai.com?subject=Message from ${name.value}&body=Name: ${name.value}%0AEmail: ${email.value}%0A%0AMessage: ${message.value}`;
  window.location.href = mailtoLink;
};
</script>
<template>
  <div class="container-contact-us">
    <div class="left-side-contact-us">
      <div class="card-contact-us">
        <div class="container-card-contact-us">
          <div class="text-medium">{{ t('ContactUs.GetInTouch') }}</div>
          <div class="text-large">{{ t('ContactUs.LetsChat') }}</div>
          <div class="text-small">{{ t('ContactUs.QuestionFeedback') }}</div>
          <div class="horizontal-divider"></div>
          <input v-model="name" type="text" class="input-field input-field-small" placeholder="First name">
          <input v-model="email" type="email" class="input-field input-field-medium" placeholder="Email address">
          <textarea v-model="message" class="input-field input-field-large" placeholder="Your message" style="resize: none;"></textarea>
          <button id="sendButton" class="submit-button" @click="sendEmail">{{ t('ContactUs.Send') }}</button>
        </div>
      </div>
    </div>
    <div class="right-side-contact-us">
      <div class="image-contact-us"></div>
      <div class="image-contact-us-card">
        <button class="button-mail" onclick="window.location.href='mailto:example@example.com';">
          <svg class="icon-mail" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z">
            </path>
          </svg>
        </button>
        <div class="text-primary">Email</div>
        <div class="text-secondary">info@resumify-ai.com</div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.container-contact-us {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding-left: 10%;
    padding-right: 10%;
  }
  .left-side-contact-us, .right-side-contact-us {
    flex: 1;
    padding: 20px;
  }
  .card-contact-us {
    position: relative;
    width: 540px;
    height: 704px;
    background-color: #ffffff;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container-card-contact-us {
    width: 90%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .input-field {
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 24px;
    outline: none;
    margin-top: 30px;
}

.input-field-small {
    width: 211px;
    height: 36px;
}

.input-field-medium, .input-field-large {
    width: 407px;
    height: 36px;
}

.input-field-large {
    height: 148px;
}

.submit-button {
    cursor: pointer;
    width: 120px;
    height: 36px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #5438da;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 24px;
    outline: none;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

  .image-contact-us {
    position: relative;
    width: 574px;
    height: 473px;
    border-radius: 45.92px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    background-image: url('https://images.unsplash.com/photo-1615890932417-89da415105d2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwxNXx8Y3VzdG9tZXIlMjBzZXJ2aWNlfGVufDF8fHx8MTcxMTI0MTQzM3ww&ixlib=rb-4.0.3&q=80&w=1080');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .image-contact-us-card{
    margin-top: 40px;
    position: relative;
    width: 561px;
    height: 163px;
    background-color: #ffffff;
    border-radius: 26px;
    display: flex;
  }
  .button-mail {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 54px;
    left: 51px;
    width: 60px;
    height: 55px;
    border: 0;
    border-radius: 100000px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
    color: #5438da;
    background-color: #d3d0ff;
    outline: none;
  }
  .icon-mail {
    color: #5438da;
    fill: #5438da;
    width: 25px;
    height: 25px;
    font-size: 25px;
  }
  .text-primary {
    color: #030303;
    font-size: 24px;
    font-family: 'Montserrat';
    line-height: 31px;
    position: absolute;
    top: 58px;
    left: 120px;
    padding-left: 20px;
  }
  .text-secondary {
    color: #909090;
    font-size: 18px;
    font-family: 'Montserrat';
    line-height: 23px;
    position: absolute;
    top: 90px;
    left: 120px;
    padding-left: 20px;
  }
  .text-large {
    color: #030303;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 52px;
    padding-top: 10px;
  }
  .text-medium {
    color: #030303;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 26px;
    padding-top: 50px;
  }
  .text-small {
    color: #030303;
    font-size: 18px;
    font-family: 'Montserrat';
    line-height: 23px;
    padding-top: 10px;
  }
  .horizontal-divider {
    width: 407px;
    height: 1px;
    background-color: #909090;
    border-radius: 2px;
    margin: 20px auto;
  }
</style>