<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";

import AccountDeleteModal from "./components/AccountDeleteModal.vue";

const { t } = useI18n();

const { user } = useAuth();

const showAccountDelete = ref(false);

const showAccountDeletePopup = () => {
  showAccountDelete.value = true;
};

const closeAccountDeletePopup = () => {
  showAccountDelete.value = false;
};

</script>

<template>
  <div class="delete-account">
    <span class="delete-account-text">{{ t("Account.DeleteAccount") }}</span>
    <div class="delete-account-div">
      <div class="delete-account-texts">
        <span class="delete-explanation-text">{{ t("Account.DeleteAccountInfo") }}</span>
        <button class="delete-button" @click="showAccountDeletePopup">{{ t("Account.DeleteAccountText") }}</button>
      </div>
    </div>
    <AccountDeleteModal v-if="showAccountDelete" :id="user.details?.uid || ''" @closeModal="closeAccountDeletePopup"/>
  </div>
</template>

<style scoped>

.delete-button{
  color: #ff1744;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
  border: 0;
  cursor: pointer;
}

.delete-explanation-text{
    color: #909090;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 21px;
}

.delete-account-texts{
    display: flex;
    flex-direction: row ;
    gap: 40px;
}

.delete-account-div{
    display: flex;
    flex-direction: column;
    width: 957px;
  height: 130px;
  background-color: #f0f0f0;
  border-radius: 26px;
  align-items: flex-start;
  padding: 50px;
}

.delete-account{
    width: 65%;
    margin-top: 100px;
    align-items: center;
}

.delete-account-text{
    color: #030303;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 650;
    line-height: 31px;
    align-self: flex-start;
    display: flex;
    margin-bottom: 50px;
}

</style>