export const determineUserCountry = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    if (!response.ok) {
      return "";
    }
    const data = await response.json();
    return data.country;
  } catch (error) {
    console.error("Error fetching cc:", error);
    return "";
  }
};