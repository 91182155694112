<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const nextSection = ref<HTMLElement | null>(null);
const imageSrc =  "https://images.unsplash.com/photo-1553877522-43269d4ea984?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwxOXx8T2ZmaWNlJTJDJTIwQ1YlMkMlMjBzdWNjZXNzfGVufDF8fHx8MTcwOTA4MDY5NHww&ixlib=rb-4.0.3&q=80&w=1080";

const getStarted = () => {
  if (nextSection.value) {
    nextSection.value.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<template>
  <div class="image-container">
    <div class="padded-image" :style="{ backgroundImage: `url(${imageSrc})` }">
      <div class="middle-card">
        <p class="text">{{ t('unlockYourCareer') }}</p>
        <button class="button" @click="getStarted">{{ t('getStarted') }}</button>
      </div>
    </div>
  </div>
  <div ref="nextSection" class="next-section"></div>
</template>

<style scoped>

.image-container {
  width: 100vw;
  height: 650px;
  position: relative;
  padding-bottom: 150px;
}

.text{
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.padded-image {
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.middle-card {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 559px;
  height: 270px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #4f4f4f;
  box-shadow: 0 0 12px rgba(3,3,3,0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 80px;
  padding-bottom: 50px;
}

.button {
  cursor: pointer;
  top: 646px;
  left: 647px;
  width: 171px;
  min-height: 48px;
  padding: 0px 8px;
  border: 2px solid #5856d6;
  border-radius: 8px;
  background-color: transparent;
  color: #5856d6;
  font-size: 18px;
  line-height: 24px;
  outline: none;
}

.button:hover {
  background-color: #5856d6;
  color: white;
}
</style>