<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
import { shuffleArray } from  "@/utils/array";

import { CV_FACTS_EN, CV_FACTS_DE, CV_FACTS_TR } from "./const";
import FactAnimated from "./FactAnimated.vue";
const languageMap = computed<{ [key: string]: string }>(() => ({
  en: "en",
  de: "de",
  tr: "tr",
}));

const currentLanguage = computed(() => languageMap.value[locale.value] || locale.value);
const randomFacts = ref<string[]>([]);
const currentFactIndex = ref<number>(0);

onMounted(() => {
  const CV_FACTS: { [key: string]: string[] } = {
    en: CV_FACTS_EN,
    de: CV_FACTS_DE,
    tr: CV_FACTS_TR,
  };

  randomFacts.value = shuffleArray(CV_FACTS[currentLanguage.value]);
  setInterval(() => {
    currentFactIndex.value = (currentFactIndex.value + 1) % randomFacts.value.length;
  }, 10000);
});
</script>

<template>
  <div class="loading-page">
    <div class="loading-message">
      <div class="loading-bar"></div>
      <p class="loading-text">{{ t("cvEvaluation.FunFact") }}</p>
    </div>
    <div class="random-facts-container">
      <transition name="fade" mode="out-in">
        <!-- Display the current fact using the custom Fact component -->
        <FactAnimated :key="currentFactIndex" :fact="randomFacts[currentFactIndex]" />
      </transition>
    </div>
  </div>
</template>

  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .random-fact {
    margin-bottom: 20px;
  }

  .loading-text {

  color: black;
  font-size: 30px;
}
.loading-bar {
  width: 100%;
  height: 10px;
  background-color: #800080;
  border: 1px solid 800080;
  position: relative;
  top: 10px;
  animation: loadingAnimation 2s infinite;
}

@keyframes loadingAnimation {
  0% {
    width: 0;
  }
  10% {
    width: 10%;
  }
  20% {
    width: 20%;
  }
  30% {
    width: 30%;
  }
  40% {
    width: 40%;
  }
  50% {
    width: 50%;
  }
  60% {
    width: 60%;
  }
  75% {
    width: 75%;
  }
  85% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}
  </style>

