<template>
  <div class="password-reset-container">
    <p v-if="!loading">{{ t("Auth.PasswordResetSuccess") }}</p>
    <div v-if="loading" class="loading-spinner">{{ t("Auth.Loading") }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();
const loading = ref(true);

onMounted(() => {
  loading.value = false;

  setTimeout(() => {
    router.push({ path: "/" });
  }, 3000);
});
</script>

<style scoped>
.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
}
.loading-spinner {
  margin-top: 20px;
  font-size: 18px;
}
</style>
