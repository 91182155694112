import { ref } from "vue";

const navigationHistory = ref<string[]>([]);

export const addToHistory = (path: string) => {
  if (path) {
    navigationHistory.value.push(path);
    if (navigationHistory.value.length > 10) {
      navigationHistory.value.shift();
    }
  }
};

export const getPreviousPath = (stepsBack = 1): string | undefined => {
  return navigationHistory.value[navigationHistory.value.length - stepsBack];
};
