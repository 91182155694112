
<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { t } = useI18n();
const store = useStore();
const isLoading = ref(false);

const emit = defineEmits(["evaluate"]);

const evaluationDone = () => {
  isLoading.value = false;
};

const evaluateCV = (cvText: string, jobTitle: string) => {
  isLoading.value = true;

  emit("evaluate", cvText, jobTitle, evaluationDone );
};

</script>

<template>
  <div class="cv-evaluator">
    <div class="cv-evaluator-content">
      <span class="evaluate-text">{{ t("Evaluate-text") }}</span>
      <div class="ai-supported">
        <span class="ai-supported-text"><font-awesome-icon :icon="['fas', 'wand-magic-sparkles']" /> {{ t("Supported by AI") }}</span>
      </div>
    </div>
    <button  :disabled="isLoading" class="evaluate-button" @click="evaluateCV(JSON.stringify(store.state.cvData), store.state.cvData.contact.jobTitle || '' )">
      <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
      <span v-else>{{ t("Evaluate") }}</span>
    </button>

  </div>
</template>

  <style scoped>
  .cv-evaluator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f0ff;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 90%;
    padding: 20px;
  }

  .evaluate-text{
    font-size: medium;
    font-weight: bold;
  }

  .cv-evaluator-content {
    display: flex;
    flex-direction: column;
  }

  .ai-supported{
    margin-top: 5px;
  border: 1px solid rgb(109, 109, 109);
  border-radius: 10px;
  width: 50%;
  padding: 1px;
  display: inline-block;
  align-self: start;
  }

  .ai-supported .ai-supported-text {
    color: rgb(109, 109, 109);
    font-size: 0.8em;
    font-weight: bold;
  }

  .evaluate-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #aea8f9;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 25%;
    font-size: 16px;
  }

  .evaluate-button:hover {
    background-color: #5a52d5;
    color: white;
  }

  </style>
