<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const emit = defineEmits(["updateDescription", "closeModal", "discardEnhanced"]);

const { summaryEnhancements, currentDescription } = defineProps<{
  summaryEnhancements: string[],
  currentDescription: string
}>();

const currentIndex = ref(0);

const previous = () => {
  currentIndex.value = currentIndex.value - 1;
};

const next = () => {
  currentIndex.value = currentIndex.value + 1;
};

const discardEnhanced = (updateDescription = true) => {
  if(updateDescription) {
    emit("updateDescription", currentDescription );
  }
  emit("discardEnhanced");
  emit("closeModal");
};

const updateDescription = () => {
  if(summaryEnhancements[currentIndex.value])
  {
    emit("updateDescription", summaryEnhancements[currentIndex.value] );
    discardEnhanced(false);
  }
};

</script>

<template>
  <div class="modal-overlay" @click.stop>

    <div class="suggestion-div">
      <svg v-if="currentIndex !== 0"  class="icon"  viewBox="0 0 24 24" @click="previous">
        <path fill="none" d="M0 0h24v24H0z">
        </path>
        <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z">
        </path>
      </svg>
      <div class="enhance-text-div">
        <span class="enhance-text" v-html="summaryEnhancements[currentIndex]"/>
      </div>
      <svg v-if="summaryEnhancements.length - 1 > currentIndex"  class="icon" viewBox="0 0 24 24" @click="next">
        <path d="M0 0h24v24H0V0z" fill="none">
        </path>
        <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z">
        </path>
      </svg>
    </div>
    <p class="page-text">{{ currentIndex + 1 }} / {{ summaryEnhancements.length }}</p>
    <div class="enhance-button-div">
      <button
        type="button"
        class="discard-button"
        @click="discardEnhanced()"
      >
        {{ t('Discard') }}
      </button>
      <button
        type="button"
        class="enhance-button"
        @click="updateDescription"
      >
        {{ t('Enhance') }}
      </button>

    </div>

  </div>
</template>

  <style scoped>

  .enhance-text{
    color: #000000;
    font-size: 14px;
    line-height: 16px;
  }

  .enhance-text-div{
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
  }

  .icon{
    color: #909090;
    fill: #909090;
    font-size: 25px;
    width: 25px;
    height: 27px;
  }

.suggestion-div{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

  .modal-overlay {
    background-color: #ebe8fc;
    width: 302px;
    min-height: 147px;
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid #4f4f4f;
    border-radius: 8px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-left: 30%;
    padding: 20px;
  }

  .enhance-button-div{
    margin-top: 15px;
    display: flex;
  }

  .enhance-button {
  cursor: pointer;
  width: 130px;
  height: 45px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #5438da;
  color: #ffffff;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 24px;
  outline: none;
}

.discard-button{
  cursor: pointer;
  width: 90px;
  height: 45px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
  color: #000000;
  font-size: 13px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 15px
}

  </style>
