import type { RenderTask } from "pdfjs-dist";
import { getDocument } from "pdfjs-dist";

let currentRenderTask: RenderTask | null = null;

export const loadPdf = async (pdfDataUrl: string, pageNum: number, canvas: HTMLCanvasElement): Promise<number> => {
  try {
    if (currentRenderTask) {
      currentRenderTask.cancel();
      currentRenderTask = null;
    }

    const doc = await getDocument(pdfDataUrl).promise;
    const page = await doc.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1.5 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Unable to get canvas context");
    }

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    currentRenderTask = page.render(renderContext);
    await currentRenderTask.promise;

    currentRenderTask = null;

    return doc.numPages;
  } catch (error) {
    console.error("Error loading PDF:", error);
    throw error;
  }
};
