<script setup lang="ts">
import { ref, watch, computed } from "vue";

import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import UpgradePremium from "@/components/UpgradePremium.vue";
import {  analyticsCategories } from "@/composables/const";
import { useAnalytics } from "@/composables/useAnalytics";

import SubscriptionCancelModal from "./components/SubscriptionCancelModal.vue";
const { t, locale } = useI18n();

const { trackEvent } = useAnalytics();

const { user } = useAuth();
const currentPlan = ref<string | undefined>();
const upgradePremiumVisible = ref(false);

const showSubscriptionCancel = ref(false);

const showSubscriptionCancelPopup = () => {
  showSubscriptionCancel.value = true;
};

const closeSubscriptionCancelPopup = () => {
  showSubscriptionCancel.value = false;
};

watch(
  () => user.value.details,
  (details) => {
    if (details) {
      currentPlan.value = details.accountStatus;
    }
  },
  { immediate: true , deep: true },
);
const upgradeUser = async () => {
  try {
    if (user.value.authUser?.uid) {
      trackEvent("button_click", {
        category: analyticsCategories.UPGRADE,
        action: "click",
        label: "account_to_upgrade",
        value: 1,
      });
      upgradePremiumVisible.value = true;
    }
  } catch (error) {
    console.error("Failed to upgrade user to Premium:", error);
  }
};

const subscriptionStatusDisplay = computed(() => {
  const status = user.value.details?.subscriptionStatus;
  if (status === "active") {
    return t("Account.Active");
  }
  else if (status === "trial") {
    return t("Account.Active");
  }
  else if (status === "cancelling") {
    return t("Account.Cancelling");
  }
  return "";
});

const dateLabel = computed(() => {
  const status = user.value.details?.subscriptionStatus;
  if (status === "active") {
    return t("Account.NextPayment");
  }
  else if (status === "trial") {
    return t("Account.NextPayment");
  }
  else if (status === "cancelling") {
    return t("Account.SubscriptionEnds");
  }
  return "";
});

const formattedDate = computed(() => {
  let dateStr = "";
  const status = user.value.details?.subscriptionStatus;
  if (status === "active") {
    console.log(user.value.details);
    dateStr = user.value.details?.nextRenewalDate ?? "";
  }
  else if (status === "trial") {
    dateStr = user.value.details?.nextRenewalDate ?? "";
  }
  else if (status === "cancelling") {
    dateStr = user.value.details?.cancelAt ?? "";
  }
  if (dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale.value, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return "";
});

const totalPrice = computed(() => {
  const currency =user.value.details?.currency;
  const premiumType = user.value.details?.premiumType;
  if (currency === "eur") {
    return premiumType === "monthly" ? "9.99€" : "29.99€";
  } else if (currency === "try") {
    return premiumType === "monthly" ? "249,99TL" : "749,99TL";
  }
  return "";
});

console.log(user.value.details);

</script>

<template>
  <SubscriptionCancelModal v-if="showSubscriptionCancel" @closeModal="closeSubscriptionCancelPopup"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div v-if="user.details?.accountStatus" class="my-plan">
    <span class="my-plan-text">{{ t('Account.MyPlan') }}</span>
    <div v-if="!(user.details?.accountStatus==='Premium')" class="card-divs">
      <div class="plan-card">
        <span class="current-plan-text">{{ t('Account.CurrentPlan') }}</span>
        <span class="current-plan-value-text">{{ currentPlan }}</span>
        <span class="current-plan-explanation-text">
          {{ t('Account.CurrentPlanExplanationFree') }}
        </span>
        <span class="upgrade-plan-text">
          {{ t('Account.UpgradePlan') }}
        </span>
      </div>
      <div class="features-card">
        <div class="feature-div">
          <font-awesome-icon :icon="['fa', 'check']" class="check" />
          <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeCreation') }}</span>
        </div>
        <div class="feature-div">
          <font-awesome-icon :icon="['fa', 'check']" class="check" />
          <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeEvaluation') }}</span>
        </div>
        <div class="feature-div">
          <font-awesome-icon :icon="['fa', 'check']" class="check" />
          <span class="feature-name">{{ t('UpgradePremium.AccessAllTemplates') }}</span>
        </div>
        <div class="feature-div">
          <font-awesome-icon :icon="['fa', 'check']" class="check" />
          <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeDownload') }}</span>
        </div>
        <button class="upgrade-button" @click="upgradeUser">{{ t('Account.UpgradeNow') }}</button>
        <span class="upgrade-button-explanation">{{ t('Account.UpgradeButtonExplanation') }}</span>
      </div>
    </div>
    <div v-else class="card-divs-premium">
      <div class="premium-card-div">
        <div class="plan-card-premium">
          <span class="current-plan-text">{{ t('Account.CurrentPlan') }}</span>
          <span class="current-plan-value-text">{{ currentPlan }}</span>

          <span  class="current-plan-explanation-text">
            {{ t('Account.CurrentPlanExplanationPremium') }}
          </span>

        </div>
        <div class="features-card-premium">
          <div class="feature-div">
            <font-awesome-icon :icon="['fa', 'check']" class="check" />
            <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeCreation') }}</span>
          </div>
          <div class="feature-div">
            <font-awesome-icon :icon="['fa', 'check']" class="check" />
            <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeEvaluation') }}</span>
          </div>
          <div class="feature-div">
            <font-awesome-icon :icon="['fa', 'check']" class="check" />
            <span class="feature-name">{{ t('UpgradePremium.AccessAllTemplates') }}</span>
          </div>
          <div class="feature-div">
            <font-awesome-icon :icon="['fa', 'check']" class="check" />
            <span class="feature-name">{{ t('UpgradePremium.UnlimitedResumeDownload') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user.details?.accountStatus === 'Premium'" class="premium-account-div">
      <div class="premium-account-div-status">
        <span class="status-text-first">{{ t('Account.Status') }}</span>
        <div :class="['status-display', user.details?.subscriptionStatus === 'cancelling' ? 'cancelling-display' : 'active-display']">{{ subscriptionStatusDisplay }}</div>
      </div>
      <div class="premium-account-div-status">
        <span class="status-text">{{ dateLabel }}</span>
        <span class="status-text">{{ formattedDate }}</span>
      </div>
      <div class="premium-account-div-status">
        <span v-if="user.details?.subscriptionStatus !== 'cancelling'" class="status-text">{{ t('Account.Total') }}</span>
        <span v-if="user.details?.subscriptionStatus !== 'cancelling'" class="status-text">{{ totalPrice }}</span>
      </div>
      <button v-if="user.details?.subscriptionStatus !== 'cancelling'" class="cancel-button" @click="showSubscriptionCancelPopup">{{ t('Account.Cancel') }}</button>
    </div>
  </div>
</template>

<style scoped>

.premium-account-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  gap: 20px;
  margin-top: 30px;
  width: 929px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #909090;
  box-sizing: border-box;
  align-items: center;
  padding: 20px;
}

.premium-account-div-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
}

.status-text {
  color: #030303;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 21px;
}
.status-text-first {
  color: #030303;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 21px;
  margin-left: 12px;
}

.status-display {
  display: flex;
  width: 97px;
  height: 33px;
  padding: 0px 8px;
  border: 0;
  border-bottom: 0;
  box-sizing: border-box;
  border-radius: 100000px;
  opacity: 0.58;
  color: #000000;
  font-size: 12px;
  font-family: "Montserrat";
  line-height: 24px;
  outline: none;
  align-items: center;
  justify-content: center;
}

.cancelling-display{
  background-color: #f6d174;
}
.active-display{
  background-color: #00d500;
}

.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 36px;
  padding: 0px 8px;
  border: 1px solid #9999ff;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #6666ff;
  font-size: 14px;
  font-family: "Montserrat";
  line-height: 24px;
  outline: none;
}

.premium-card-div{
  display: flex;
  flex-direction: row;
  background-color: #f7f5ff;
  border-radius: 26px;
  width: 929px;
  max-height: 330px;
}

.upgrade-button-explanation{
    color: #5438da;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 21px;
  align-self: center;
  margin-top: 15px;
}

.upgrade-button{
    cursor: pointer;
    align-self: center;
    margin-top: 20px;
  width: 227px;
  min-height: 55px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  line-height: 21px;
  outline: none;
}

.feature-name{
    color: #909090;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 31px;
}

.check{
  color: #5438da;
  font-size: 41px;
  width: 30px;
  height: 30px;
}

.feature-div{
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 15px;
    margin-left: 50px;
}

.upgrade-plan-text{
    color: #5438da;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  align-self: center;
  margin-top: 40px;
  text-decoration: underline;
}

.current-plan-explanation-text{
    color: #909090;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 23px;
  margin-top: 40px;
  text-align: start;
}

.current-plan-text{
    color: #909090;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 31px;
  text-align: start;
}

.current-plan-value-text{
    color: #5438da;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 650;
  line-height: 31px;
  margin-top: 20px;
}

.my-plan{
align-items: center;
width: 65%;
}

.card-divs{
    flex-direction: row;
display: flex;
justify-content: space-between;
width: 957px;

}
.card-divs-premium{
  flex-direction: row;
  display: flex;
  width: 957px;

}

.features-card{
    display: flex;
    top: 389px;
    left: 692px;
    width: 580px;
    height: 400px;
    background-color: #f7f5ff;
    border-radius: 26px;
    align-items: flex-start;
    padding: 40px;
    flex-direction: column;
    text-align: start;
}

.features-card-premium{
    display: flex;
    width: 580px;
    height: 400px;
    border-radius: 26px;
    align-items: flex-start;
    text-align: start;
    padding: 40px;
    flex-direction: column;
}

.plan-card{
    display: flex;
    top: 389px;
    left: 225px;
    width: 363px;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 26px;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    text-align: start;

}

.plan-card-premium{
    display: flex;
    width: 363px;
    height: 400px;
    border-radius: 26px;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;

}

.my-plan-text{
    color: #030303;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 650;
    line-height: 31px;
    align-self: flex-start;
    display: flex;
    margin-bottom: 50px;
}

</style>