import type { Education, Experience, Project, CvSectionsForBackgroundRemove, CvData } from "@/composables/types";

const removeBackgroundColorFromHtml = (html: string): string => {

  const parser = new DOMParser();

  const doc = parser.parseFromString(html, "text/html");

  const elementsWithStyle = doc.querySelectorAll("[style]");

  elementsWithStyle.forEach((element) => {
    const style = element.getAttribute("style");
    if (style) {
      const newStyle = style
        .split(";")
        .filter((styleProp) => !styleProp.trim().startsWith("background-color"))
        .join(";");

      if (newStyle.trim()) {
        element.setAttribute("style", newStyle);
      } else {
        element.removeAttribute("style");
      }
    }
  });

  return doc.body.innerHTML;
};

export const removeBackgroundColorsFromSection = (cvData: CvData, section: CvSectionsForBackgroundRemove): CvData => {
  switch (section) {
  case "educations":
    return {
      ...cvData,
      educations: cvData.educations?.map((education: Education) => ({
        ...education,
        description: education.description ? removeBackgroundColorFromHtml(education.description) : education.description,
      })),
    };
  case "experiences":
    return {
      ...cvData,
      experiences: cvData.experiences?.map((experience: Experience) => ({
        ...experience,
        description: experience.description ? removeBackgroundColorFromHtml(experience.description) : experience.description,
      })),
    };
  case "projects":
    return {
      ...cvData,
      projects: cvData.projects?.map((project: Project) => ({
        ...project,
        description: project.description ? removeBackgroundColorFromHtml(project.description) : project.description,
      })),
    };
  case "contact":

    return {
      ...cvData,
      contact: cvData.contact && cvData.contact.description
        ? {
          ...cvData.contact,
          description: removeBackgroundColorFromHtml(cvData.contact.description),
        }
        : cvData.contact,
    };
  default:
    return cvData;
  }
};
