<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, defineProps, defineEmits } from "vue";

defineProps({
  modelValue: {
    type: String,
    default: "Roboto",
  },
  options: {
    type: Array as () => { name: string; style: string }[],
    default: () => [
      { name: "Roboto", style: "font-family: 'Roboto', sans-serif;" },
      { name: "Arial", style: "font-family: Arial, sans-serif;" },
      { name: "Helvetica", style: "font-family: Helvetica, sans-serif;" },
      { name: "Times New Roman", style: "font-family: 'Times New Roman', serif;" },
    ],
  },
});

const emit = defineEmits(["update:modelValue"]);

const isOpen = ref(false);
const dropdown = ref<HTMLElement | null>(null);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option: string) => {
  emit("update:modelValue", option);
  isOpen.value = false;
};

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <div ref="dropdown" class="dropdown" @click="toggleDropdown">
    <span>{{ modelValue }}</span>
    <svg
      v-if="!isOpen"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
    </svg>
    <ul v-show="isOpen" class="dropdown-list">
      <li
        v-for="option in options"
        :key="option.name"
        :style="option.style"
        @click.stop="selectOption(option.name)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<style>
.dropdown {
  cursor: pointer;
  width: 120px;
  height: 38px;
  padding: 0px 8px;
  border: 1px solid #909090;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #ffffff;
  color: #909090;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 19px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #909090;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
</style>
