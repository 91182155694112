import { nextTick } from "vue";
import type { Ref } from "vue";

import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";

const paddingForChild = (children: HTMLElement[], forbiddenZones: ForbiddenZone[]): void => {
  for (const child of children) {
    try {
      const rect = child.getBoundingClientRect();

      forbiddenZones.forEach(zone => {
        if (rect.top >= zone.start && rect.top <= zone.end) {
          const adjustment = zone.end - rect.top + 50;
          child.style.marginTop = `${adjustment}px`;
        }
      });

      // Recursively adjust padding for child elements
      if (child.children.length > 0) {
        paddingForChild(Array.from(child.children) as HTMLElement[], forbiddenZones);
      }
    } catch (error) {
      console.error(error);
    }
  }
};
export const adjustLayout = async (forbiddenZones: ForbiddenZone[], divManipulatorRef: Ref<HTMLElementWithVNode | null>): Promise<void> => {
  await nextTick();

  if (divManipulatorRef.value) {
    const children = Array.from(divManipulatorRef.value.children) as HTMLElement[];
    paddingForChild(children, forbiddenZones);
  }
};

