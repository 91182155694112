import type { CvData } from "@/composables/types";

export const cloneAndTransformCvData = (originalCvData: CvData): CvData => {
  const clonedCvData = JSON.parse(JSON.stringify(originalCvData)) as CvData;

  const transformDescription = (htmlContent: string): string => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = htmlContent;

    const orderedLists = tempContainer.querySelectorAll("ol");
    orderedLists.forEach((ol) => {
      const hasBulletList = Array.from(ol.children).some(
        (li) => li.getAttribute("data-list") === "bullet",
      );

      if (hasBulletList) {
        const ul = document.createElement("ul");
        ul.innerHTML = ol.innerHTML;
        ol.replaceWith(ul);
      }
    });

    return tempContainer.innerHTML;
  };

  const descriptionFields: (keyof CvData)[] = [
    "experiences",
    "educations",
    "projects",
    "exhibitions",
    "honors",
    "hobbies",
    "publications",
    "voluntaries",
  ];

  descriptionFields.forEach((field) => {
    if (Array.isArray(clonedCvData[field])) {
      (clonedCvData[field] as { description?: string }[]).forEach((item) => {
        if (item.description) {
          item.description = transformDescription(item.description);
        }
      });
    }
  });

  if (clonedCvData.contact?.description) {
    clonedCvData.contact.description = transformDescription(clonedCvData.contact.description);
  }

  return clonedCvData;
};
