import { createStore } from "vuex";

import { initiaCvData } from "@/composables/const";

import type { CvState, CvSections, SectionTypes, SectionData, SelectedTemplate } from "../composables/types";

interface UpdateItemPayload {
  section: CvSections;
  itemId: string;
  newData: SectionData;
}

const cvStore = createStore<CvState>({
  state: () => ({
    cvData: initiaCvData,
  }),
  mutations: {
    updateCvSection: (state: CvState, { section, data }: { section: CvSections, data: SectionTypes }) => {
      (state.cvData[section] as SectionTypes) = data;
    },
    updateShowSkillLevels: (state: CvState, { data }: { data: boolean }) => {
      state.cvData.showSkillLevels = data;
    },
    updateShowLanguageLevels: (state: CvState, { data }: { data: boolean }) => {
      state.cvData.showLanguageLevels = data;
    },
    updateSelectedTemplate: (state: CvState, { data }: { data: SelectedTemplate }) => {
      state.cvData.selectedTemplate = data;
    },
    updateResumeColor: (state: CvState, { data }: { data: string }) => {
      state.cvData.resumeColor = data;
    },
    updateResumeFontFamily: (state: CvState, { data }: { data: string }) => {
      state.cvData.resumeFontFamily = data;
    },
    updateResumeFontSize: (state: CvState, { data }: { data: number }) => {
      state.cvData.resumeFontSize = data;
    },
    updateResumeName: (state: CvState, { data }: { data: string }) => {
      state.cvData.resumeName = data;
    },
    addItem: (state: CvState, { section, item }: { section: CvSections, item: SectionData }) => {
      const sectionArray = state.cvData[section];
      if (Array.isArray(sectionArray)) {
        sectionArray.push(item);
      }
    },
    removeItem: (state: CvState, { section, index }: { section: CvSections, index: number }) => {
      const sectionArray = state.cvData[section];
      if (Array.isArray(sectionArray)) {
        sectionArray.splice(index, 1);
      }
    },
    updateItem: (state: CvState, { section, itemId, newData }: UpdateItemPayload) => {
      const sectionArray = state.cvData[section];
      if (Array.isArray(sectionArray)) {
        const itemIndex = sectionArray.findIndex(item => "id" in item && item.id === itemId);
        if (itemIndex !== -1) {
          sectionArray[itemIndex] = newData;
        }
      }
    },
    setEntireState: (state, newState) => {
      Object.assign(state.cvData, newState);
    },
  },
  actions: {
    addCvItem: ({ commit }, payload) => {
      commit("addItem", payload);
    },
    removeCvItem: ({ commit }, payload) => {
      commit("removeItem", payload);
    },
    updateCvItem: ({ commit }, payload: UpdateItemPayload) => {
      commit("updateItem", payload);
    },
    updateCvSection: ({ commit }, payload: { section: CvSections, data: SectionTypes }) => {
      commit("updateCvSection", payload);
    },
    updateShowSkillLevels: ({ commit }, payload: { data: boolean }) => {
      commit("updateShowSkillLevels", payload);
    },
    updateSelectedTemplate: ({ commit }, payload: { data: SelectedTemplate }) => {
      commit("updateSelectedTemplate", payload);
    },
    updateResumeColor: ({ commit }, payload: { data: string }) => {
      commit("updateResumeColor", payload);
    },
    updateResumeFontFamily: ({ commit }, payload: { data: string }) => {
      commit("updateResumeFontFamily", payload);
    },
    updateResumeFontSize: ({ commit }, payload: { data: number }) => {
      commit("updateResumeFontSize", payload);
    },
    updateResumeName: ({ commit }, payload: { data: string }) => {
      commit("updateResumeName", payload);
    },
    setEntireState: ({ commit }, newState): Promise<void> => {
      return new Promise((resolve) => {
        commit("setEntireState", newState);
        resolve();
      });
    },
  },
});

export default cvStore;
