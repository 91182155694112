import { logEvent } from "firebase/analytics";

import { analytics } from "@/auth/firebaseInit";

type AnalyticsEventParams = {
  category?: string;
  action?: string;
  label?: string;
  value?: number;
  locale?: string;
  [key: string]: any;
};

export const useAnalytics = () => {
  const trackEvent = (
    eventName: string,
    params: AnalyticsEventParams = {},
  ): void => {

    if(process.env.NODE_ENV !== "production"){
      return;
    }

    if (!analytics) {
      console.warn("Firebase Analytics is not initialized");
      return;
    }

    const defaultParams = {
      content_type: params.category || "default_category",
      action: params.action || "default_action",
      label: params.label || eventName,
      value: params.value || 1,
      locale: params.locale || "en",
    };

    const eventParams = { ...defaultParams, ...params };

    logEvent(analytics, eventName, eventParams);
  };

  return { trackEvent };
};
