<script setup lang="ts">
import { ref, computed, watch } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import {  analyticsCategories } from "@/composables/const";
import type { Education, MissingInputField , SectionScore } from "@/composables/types";
import { useAnalytics } from "@/composables/useAnalytics";
import { enhanceEducation } from "@/services/enhanceService";
import { modifyLibraryEntry } from "@/services/libraryService";
import { validateInputs } from "@/utils/validateCvDataInputs";

import  CvSectionScore  from "../../components/CvSectionScore.vue";
import DescriptionEnhanceModal from "../../components/DescriptionEnhanceModal.vue";
import EnhanceButton from "../../components/EnhanceButton.vue";
import RichTextEditor from "../../components/RichTextEditor.vue";
import { formatDate } from "../../utils/formatDate";
import { initialStateEducation } from "../../utils/initialSectionStates";
import { removeBackgroundColorsFromSection } from "../../utils/removeDescriptionBackgroundColors";

const { t } = useI18n();

const { trackEvent } = useAnalytics();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
  scoreDetails: SectionScore | undefined
}>();

const { user } = useAuth();

const showAdd = ref(true);
const isAdding = ref(false);
const store = useStore();
const showDescriptionModal = ref(false);
const enhanced = ref(false);
const isLoading = ref(false);
const missingFields = ref<string[]>([]);
const upgradePremiumVisible = ref(false);
const authModalVisible = ref(false);
const richTextEditorRef = ref<any>();
const creditNotEnough = ref(false);

const closeModal = () => {
  showDescriptionModal.value = false;
};
const openModal = () => {
  showDescriptionModal.value = true;

};

const summaryEnhancements = ref([]);

const addEducation = ref<Education>(initialStateEducation(uuidv4()));

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, removeBackgroundColorsFromSection(newcvData, "educations"));
      missingFields.value = validateInputs(store.state.cvData, addEducation.value);

    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const educations = computed({
  get: () => store.state.cvData.educations,
  set: (value) => store.commit("updateCvSection", { section: "educations", data: value }),
});

const handleCurrentEducation = () => {
  if (addEducation.value.isCurrentEducation) {
    addEducation.value.endDate = t("cvCreationView.Present");
  } else {
    addEducation.value.endDate = "";
  }
  store.dispatch("updateCvItem", {
    section: "educations",
    itemId: addEducation.value.id,
    newData: addEducation.value,
  });

};

const updateField = (fieldName: keyof Education, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "school" || fieldName === "degree" || fieldName === "location" || fieldName === "startDate" || fieldName === "endDate") {
    addEducation.value[fieldName] = DOMPurify.sanitize(value);
  } if (fieldName === "isCurrentEducation") {
    addEducation.value[fieldName] = value === "true";
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "educations",
      itemId: addEducation.value.id,
      newData: addEducation.value,
    });
  }
};

const updateDescription = (newHtmlContent: string) =>  {
  addEducation.value.description = DOMPurify.sanitize(newHtmlContent);
  store.dispatch("updateCvItem", {
    section: "educations",
    itemId: addEducation.value.id,
    newData: addEducation.value,
  });
};

const updateDescriptionEnhance = (newHtmlContent: string) =>  {
  addEducation.value.description = DOMPurify.sanitize(newHtmlContent);
  store.dispatch("updateCvItem", {
    section: "educations",
    itemId: addEducation.value.id,
    newData: addEducation.value,
  });
  setTimeout(() => {
    richTextEditorRef.value?.updateInnerHtml();
  }, 500);
};

const discardEnhanced = () => {
  enhanced.value = false;
};

const getSummaryEnhancement = async() => {
  if (!user.value.authUser) {
    authModalVisible.value = true;
    return;
  }
  if (user.value.details?.accountStatus !== "Premium") {
    trackEvent("button_click", {
      category: analyticsCategories.UPGRADE,
      action: "click",
      label: "education_enhance_to_upgrade",
      value: 1,
    });
    upgradePremiumVisible.value = true;
    return;
  }
  missingFields.value = validateInputs(store.state.cvData, addEducation.value);
  isLoading.value = true;
  discardEnhanced();
  try {
    trackEvent("button_click", {
      category: analyticsCategories.CV_ENHANCE,
      action: "click",
      label: "education_enhance",
      value: 1,
    });
    const { result: response, error: enhanceError } = await enhanceEducation(addEducation.value.description || "", t("Locale"), t("SectionStringEducation"), user.value.details.id || "");
    if (enhanceError?.value && enhanceError.value.indexOf("402") !== -1) {
      creditNotEnough.value = true;
      return;
    }

    const jsonString2 = response?.enhancedSummaries;
    const jsonObj = JSON.parse(jsonString2 || "");

    summaryEnhancements.value = jsonObj.enhanced_summaries;

    enhanced.value = true;

    openModal();

  } catch (e) {
    console.error("Error during enhancement or parsing:", e);
  } finally {
    isLoading.value = false;
  }

};

const isFieldMissing = computed(() => {
  return (field: MissingInputField) => missingFields.value.includes(field);
});

</script>

<template>
  <AuthModal v-if="authModalVisible" @update:visible="authModalVisible = $event"/>
  <UpgradePremium v-if="creditNotEnough" :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div class="education-details">
    <div class="score-section">
      <div class="details-section">
        <h2>{{ t('EducationSection.Education') }}</h2>
        <p>{{ t('EducationSection.FillOutYourAcademicBackground') }}</p>
      </div>
      <CvSectionScore v-if="scoreDetails && evaluated" :scoreDetails="scoreDetails"/>
    </div>
    <div class="action-container">
      <button
        v-if="showAdd"
        class="action-container-button"
        @click="
          () => {
            isAdding = true;
            showAdd = false;
          }
        "
      >
        {{ t('EducationSection.AddAnEducationalMilestone') }} <font-awesome-icon icon="plus" />
      </button>
      <div v-if="!showAdd">

        <div class="input-section">
          <div class="input-wrapper">
            <label for="institution">{{ t('EducationSection.AcademicInstitution') }}</label>
            <input
              id="institution"
              type="text"
              :placeholder="t('EducationSection.AcademicInstitution')"
              :value="addEducation.school"
              :class="{'error': enhanced && isFieldMissing('school')}"
              @blur="updateField('school', $event)"
            />
            <span v-if="enhanced && isFieldMissing('school')" class="error-message">{{ t("InputValidation.MissingSchool") }}</span>
          </div>

          <div class="input-wrapper">
            <label for="degree">{{ t('EducationSection.Degree') }}</label>
            <input
              id="degree"
              type="text"
              :placeholder="t('EducationSection.Degree')"
              :value="addEducation.degree"
              :class="{'error': enhanced && isFieldMissing('degree')}"
              @blur="updateField('degree', $event)"
            />
            <span v-if="enhanced && isFieldMissing('degree')" class="error-message">{{ t("InputValidation.MissingDegree") }}</span>
          </div>
        </div>
        <div class="input-section">
          <div class="input-wrapper">
            <label for="location">{{ t('EducationSection.Location') }}</label>
            <input
              id="location"
              type="text"
              :placeholder="t('EducationSection.Location')"
              :value="addEducation.location"
              :class="{'error': enhanced && isFieldMissing('location')}"
              @blur="updateField('location', $event)"
            />
            <span v-if="enhanced && isFieldMissing('location')" class="error-message">{{ t("InputValidation.MissingLocation") }}</span>
          </div>
          <div class="input-wrapper">
            <label for="dates">{{ t('StartAndEndDate') }}</label>
            <div class="date-section">
              <div style="width: 100%;">
                <input
                  id="startDate"
                  class="date-input"
                  :placeholder="t('DatePlaceholder')"
                  :value="addEducation.startDate"
                  :class="{'error': enhanced && isFieldMissing('startDate')}"
                  @blur="updateField('startDate', $event)"
                  @input="formatDate($event, addEducation, 'startDate')"
                />
                <span v-if="enhanced && isFieldMissing('startDate')" class="error-message">{{ t("InputValidation.MissingStartDate") }}</span>
              </div>
              <div style="width: 100%;">
                <input
                  id="endDate"
                  class="date-input"
                  :placeholder="t('DatePlaceholder')"
                  :value="addEducation.endDate"
                  :disabled="addEducation.isCurrentEducation"
                  :class="{'error': enhanced && isFieldMissing('endDate')}"
                  @blur="updateField('endDate', $event)"
                  @input="formatDate($event, addEducation, 'endDate')"
                />
                <span v-if="enhanced && isFieldMissing('endDate')" class="error-message">{{ t("InputValidation.MissingEndDate") }}</span>
              </div>
            </div>
            <div class="checkbox-wrapper">
              <input
                id="currentEducation"
                v-model="addEducation.isCurrentEducation"
                type="checkbox"
                class="current-education-checkbox"
                @change="() => handleCurrentEducation()"
              />
              <label for="currentEducation" class="current-education-label">{{ t('EducationSection.CurrentEducation') }}</label>
            </div>
          </div>
        </div>
        <div class="input-wrapper">
          <DescriptionEnhanceModal v-if="showDescriptionModal" :summaryEnhancements="summaryEnhancements" :currentDescription="addEducation.description || ''" @updateDescription="updateDescriptionEnhance" @closeModal="closeModal" @discardEnhanced="discardEnhanced"/>
          <label for="summary">{{ t('EducationSection.Summary') }}</label>
        </div>
        <RichTextEditor ref="richTextEditorRef" :htmlValue="addEducation.description" :enhanced="enhanced" :style="{ height: '150px' }" @updateHtmlValue="updateDescription" @openModal="openModal"/>
        <div class="buttons-div">
          <button
            type="button"
            class="submit-button"
            @click="
              () => {
                if (isAdding) {
                  store.dispatch('addCvItem', {
                    section: 'educations',
                    item: { ...addEducation, id: uuidv4() },
                  });
                  isAdding = false;
                }
                addEducation = initialStateEducation(uuidv4());
                showAdd = true;
                enhanced = false;
              }
            "
          >
            {{ isAdding ? t('EducationSection.Add') : t('EducationSection.Save') }}
          </button>
          <button
            v-if="isAdding"
            type="button"
            class="submit-button"
            @click="
              () => {
                showAdd = true;
                isAdding = false;
                enhanced = false;
                addEducation = initialStateEducation(uuidv4());
              }
            "
          >
            {{ t('EducationSection.Discard') }}
          </button>
          <EnhanceButton :isLoading="isLoading" :width="'198px'" :height="'36px'" @enhance="getSummaryEnhancement" />

        </div>
      </div>
    </div>
    <div>
      <draggable v-if="showAdd" v-model="educations" itemKey="id" class="draggable-area" tag="div">
        <template #item="{ element, index }">
          <div :key="element.id" class="education-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
            <div class="education-content">
              <label class="education-institution">{{ element.school }}</label>
              <label class="education-degree">{{ element.degree }}</label>
            </div>
            <div class="education-actions">
              <font-awesome-icon
                icon="edit"
                class="education-action-icon"
                @click="
                  () => {
                    showAdd = false;
                    addEducation = { ...element };
                  }
                "
              />
              <font-awesome-icon
                icon="trash"
                class="education-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'educations',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<style scoped>

.buttons-div{
  display: flex;
  margin-top: 18px;
}

.error-message {
  color: red;
  font-size: 13px;
}

.details-section {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-section {
  display: flex;
  flex-direction: row;
}

.drag-icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.draggable-area {
  margin-top: 25px;
}

.education-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.education-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.education-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.education-action-icon {
  color: #909090;
}

.education-institution {
  margin-left: 20px;
}

.education-degree {
  color: #909090;
  margin-right: 20px;
}

.checkbox-wrapper {
  display: flex;
  font-size: small;
  gap: 8px;
  align-self: flex-end;
  margin-right: 25%;
}

.current-education-label {
  margin: 0;
  font-size: 0.78rem;
  color: #848484 !important;
}

.current-education-checkbox ~ label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.current-education-checkbox:checked ~ label::before {
  background-color: rgb(95, 82, 210, 0.7);
}

.current-education-checkbox {
  opacity: 0;
}

.current-education-checkbox ~ label {
  position: relative;
  cursor: pointer;
}

.action-container {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

.action-container-button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.action-container-button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.action-container-button:focus {
  outline: none;
}

.education-details {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.education-details h2 {
  text-align: start;
}

.education-details p {
  text-align: start;
  color: #7d7d7d;
}

.input-section {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  margin-top: 45px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper .error {
  border: 2px solid red !important;
}

.date-section {
  gap: 30px;
  display: flex;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.input-wrapper input[type="date"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.date-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.continue-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
}

.submit-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px
}

</style>

