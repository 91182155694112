<script setup lang="ts">

import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
  userCountry: {
    type: String,
  },
});

const proFeatures = [ t("PricingView.Pro1"), t("PricingView.Pro2"), t("PricingView.Pro3"), t("PricingView.Pro4"), t("PricingView.Pro5"), t("PricingView.Pro8")];
const proFeatures2 = [ t("PricingView.Pro1"), t("PricingView.Pro2"), t("PricingView.Pro3"), t("PricingView.Pro6"), t("PricingView.Pro7"), t("PricingView.Pro8")];
const freeFeatures = [ t("PricingView.Free1"), t("PricingView.Pro3")];

</script>

<template>
  <div class="main-text">{{ t('PricingView.PlanComparison') }}</div>
  <div class="cards-container">
    <div class="card-pricing">
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Free") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceFreeTr") : t("PricingView.PriceFree") }}</span>
        <div v-for="text in freeFeatures" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-pricing-premium">
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Pro") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceProTr")  : t("PricingView.PricePro") }}<span class="month-text">{{ t("PerMonth") }}</span></span>
        <div v-for="text in proFeatures" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
        <div  class="text-icon-row">
          <svg class="icon-pricing" viewBox="0 0 512 512">
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
          </svg>
          <span>---</span>
        </div>
        <div  class="text-icon-row">
          <svg class="icon-pricing" viewBox="0 0 512 512">
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
          </svg>
          <span>---</span>
        </div>
      </div>
    </div>
    <div class="card-pricing-premium">
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Pro") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceProFourMonthsTr") : t("PricingView.PriceProFourMonths") }}<span class="month-text">{{ t("PerYear") }}</span></span>
        <div v-for="text in proFeatures2" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
        <div  class="text-icon-row">
          <svg class="icon-pricing" viewBox="0 0 512 512">
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
          </svg>
          <span>---</span>
        </div>
        <div  class="text-icon-row">
          <svg class="icon-pricing" viewBox="0 0 512 512">
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
          </svg>
          <span>---</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cards-container {
  width: 100%;
  display: flex;
  margin-top: 50px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  padding-bottom: 100px;
}
.main-text {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
    margin-top: 50px;
  }

.card-pricing {
  min-width: 360px;
  height: auto;
  top: 240px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
  align-items: flex-start;
}
.card-pricing-premium {
  min-width: 410px;
  height: auto;
  top: 240px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  align-items: flex-start;
}
.card-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  text-align: start;
}
.texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.text-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.text-icon-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  justify-content: flex-start;
}

.card-text {
  margin-top: 10px;
}
.plan-type, .plan-price {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  align-self: center;
}

.plan-price {
    color: #21c0a5;
}

.icon-pricing {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 0;
  box-sizing: border-box;
  border-radius: 50%;
  color: #ffffff;
  background-color: #5438da;
  outline: none;
  padding: 5px;
  margin-right: 20px;
}
.month-text {
    font-size: 12px;
}

</style>
