<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from "vue";

import { useI18n } from "vue-i18n";

// CV facts (texts) for different languages
import { CV_FACTS_EN, CV_FACTS_DE, CV_FACTS_TR } from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-loading/const";

const { t, locale } = useI18n();
const currentLoaderText = ref("");
const percentage = ref(0);

// Function to select random fact
const getFact = () => {
  const currentLanguage = locale.value;
  let cvFacts;
  switch (currentLanguage) {
  case "de":
    cvFacts = CV_FACTS_DE;
    break;
  case "tr":
    cvFacts = CV_FACTS_TR;
    break;
  case "en":
  default:
    cvFacts = CV_FACTS_EN;
    break;
  }
  return cvFacts[Math.floor(Math.random() * cvFacts.length)];
};

// Function to update loader text with random fact
const updateLoaderText = () => {
  currentLoaderText.value = getFact();
};

// Function to increment percentage for the circle loader
const startLoading = () => {
  const interval = setInterval(() => {
    if (percentage.value < 99) {
      percentage.value++;
    } else {
      clearInterval(interval);
    }
  }, 500);

  return interval;
};

onMounted(() => {
  updateLoaderText();
  const textInterval = setInterval(() => {
    updateLoaderText();
  }, 5000);

  const percentageInterval = startLoading();

  onBeforeUnmount(() => {
    clearInterval(textInterval);
    clearInterval(percentageInterval);
  });
});
watch(locale, () => {
  updateLoaderText();
});
</script>

<template>
  <div class="modal-content">
    <div class="loading-text framed-text">
      {{ t("ImportResume") }}
    </div>
    <!-- Circle Loader with Percentage Animation -->
    <div class="loader-container">
      <svg class="loader-svg" viewBox="0 0 200 200">
        <circle
          cx="100"
          cy="100"
          r="80"
          stroke="#e6e6e6"
          stroke-width="15"
          fill="none"
        />
        <circle
          cx="100"
          cy="100"
          r="80"
          stroke="#5438da"
          stroke-width="15"
          fill="none"
          :stroke-dasharray="2 * Math.PI * 80"
          :stroke-dashoffset="(1 - percentage / 100) * 2 * Math.PI * 80"
          style="transition: stroke-dashoffset 1s ease; transform: rotate(-90deg); transform-origin: 50% 50%;"
        />
      </svg>
      <div class="percentage-text">{{ percentage }}%</div>
    </div>

    <!-- Dynamic Loading Fact Text -->
    <div class="loading-text" style="font-weight: 600;">
      {{ currentLoaderText }}
    </div>
  </div>
</template>

<style scoped>
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  height: 400px;
  width: 500px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
}

.loader-container {
  position: relative;
  width: 160px;
  height: 160px;
  margin: auto;
  margin-top: 20px;
}

.loader-svg {
  width: 100%;
  height: 100%;
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #5438da;
  font-weight: bold;
}

.loading-text {
  margin-top: 30px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  animation: fadeIn 2s;
  color: black;
}

.framed-text {
  font-weight: bold;
  font-size: 14px;
  color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>