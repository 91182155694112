<template>
  <div class="error-page">
    <div class="error-content">
      <h1>404</h1>
      <h2>Page not found.</h2>
      <p>Oops! Looks like we couldn't find the page you're after.</p>
      <button @click="goHome">Back to home</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();
const goHome = () => {
  router.push("/");
};
</script>

  <style scoped>

  .error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    font-family: 'Montserrat';
    color: #6c757d;
  }

  .error-content {
    text-align: center;
  }

  h1 {
    font-size: 150px;
    color: #6b5b95;
    font-family: 'Montserrat';
    line-height: 195px;
    margin: 0;
  }

  h2 {
    color: #909090;
    font-size: 32px;
    font-family: 'Montserrat';
    line-height: 42px;
    margin: 0.5rem 0;
  }

  p {
    margin: 0.5rem 0 2rem;
    color: #909090;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 16px;
  }

  button {
    padding: 0.5rem 2rem;
    background-color: #aa93f3;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
  }

  button:hover {
    background-color: #a984e4;
  }
  </style>
