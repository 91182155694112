export const CV_FACTS_EN = [
  "Recruiters spend an average of 7.4 seconds reviewing a CV.",
  "Tailoring your CV to the job description can increase your chances of getting noticed.",
  "Including keywords from the job posting can help your CV pass through applicant tracking systems (ATS).",
  "The ideal CV length is typically one to two pages.",
  "Listing relevant skills and accomplishments is crucial for a standout CV.",
  "Education and work experience are important sections of a CV.",
  "Your CV should have a clear and professional format.",
  "Adding quantifiable achievements can make your CV more impactful.",
  "Networking can sometimes be more effective than submitting a CV online.",
  "Proofreading and error-checking your CV is essential to avoid mistakes.",
  "Customizing your CV for each job application is recommended.",
  "A well-written CV can help you land an interview, but it's the interview that gets you the job.",
  "Your CV should showcase your strengths and highlight your achievements.",
  "Employers often look for candidates with relevant experience and skills.",
  "A CV is not the same as a resume; it's typically longer and more detailed.",
  "In some countries, a CV is referred to as a curriculum vitae.",
  "Having an online professional presence, such as a LinkedIn profile, can complement your CV.",
  "Consider including a summary or objective statement at the beginning of your CV.",
  "Mentioning volunteer work or extracurricular activities can show your well-roundedness.",
  "Be prepared to discuss any information on your CV during an interview.",
  "Your CV should reflect your career progression and growth over time.",
  "Quantify your accomplishments with numbers and percentages where possible.",
  "Highlight any awards or honors you've received.",
  "Include relevant certifications and training on your CV.",
  "List your work experience in reverse chronological order.",
  "Use action verbs to describe your work achievements on your CV.",
  "Your CV should be neatly organized and easy to skim.",
  "Be honest and truthful on your CV; false information can be discovered later.",
  "A CV can be tailored for different industries and job roles.",
  "Consider including a list of references or stating that they are available upon request.",
  "Incorporate keywords from the job description naturally in your CV.",
  "Consider using a professional email address on your CV.",
  "Include your contact information at the top of your CV.",
  "Be concise in your descriptions; avoid lengthy paragraphs on your CV.",
  "Your CV may be the first impression a potential employer has of you.",
  "A well-designed CV can stand out visually.",
  "Networking events and job fairs are opportunities to distribute your CV.",
  "Research the company and job role before tailoring your CV for an application.",
  "Avoid including personal information such as your date of birth on your CV.",
  "Highlight any foreign language skills on your CV, especially if they're relevant to the job.",
  "Use a professional font and font size for your CV.",
  "Be consistent in formatting throughout your CV.",
  "Use bullet points to make information easy to read on your CV.",
  "Your CV can be a combination of your academic and professional achievements.",
  "Consider including a professional summary statement at the beginning of your CV.",
  "Tailor your CV to emphasize your strengths and skills that match the job requirements.",
  "Quantify your achievements with specific numbers and data on your CV.",
  "Highlight any leadership roles or responsibilities you've held.",
  "Include relevant coursework or projects if you're a recent graduate on your CV.",
  "Update your CV regularly to include new accomplishments and experiences.",
  "Be mindful of the tone and language used in your CV.",
  "Seek feedback on your CV from mentors or career advisors.",
  "Your CV should reflect your unique qualifications and experiences.",
  "A well-organized CV can help recruiters quickly find the information they need.",
  "Include a professional profile photo on your CV if it's common in your industry.",
  "A CV is often used in academic and research fields to showcase publications and presentations.",
  "Consider including a career objective to explain your goals on your CV.",
  "Highlight any volunteer work or community involvement on your CV.",
  "Your CV should be easy to read and follow a logical order.",
  "Avoid using jargon or overly technical language on your CV.",
  "Include your full name and contact details at the top of your CV.",
  "Your CV can include a section for professional memberships or affiliations.",
  "Consider using a clean and professional template for your CV.",
  "Highlight any relevant software skills or certifications on your CV.",
  "Be concise in your descriptions; focus on the most important details on your CV.",
  "Tailor your CV for each job application to match the specific requirements.",
  "Consider including a list of skills or qualifications at the beginning of your CV.",
  "Include any relevant online courses or certifications on your CV.",
  "Your CV is a reflection of your qualifications and potential as a candidate.",
  "Be prepared to discuss your CV in detail during interviews.",
  "Highlight any research or publications you've contributed to on your CV.",
  "Include a link to your professional LinkedIn profile on your CV.",
  "Use a clean and professional email address on your CV.",
  "Consider adding a brief career summary or objective statement on your CV.",
  "Mention any relevant industry awards or recognitions on your CV.",
  "Customize your CV for each job application to showcase the most relevant skills.",
  "Include your educational background and degrees earned on your CV.",
  "List any relevant coursework or training programs on your CV.",
  "Use a consistent format and style throughout your CV.",
  "Quantify your accomplishments and impact in previous roles on your CV.",
  "Highlight any professional affiliations or memberships on your CV.",
  "Be concise and clear in your descriptions; avoid unnecessary jargon on your CV.",
  "A well-organized CV can make a positive impression on recruiters.",
  "Include a call to action, such as inviting recruiters to contact you, on your CV.",
  "Proofread your CV carefully to catch any typos or errors.",
  "Tailoring your CV for each job application can improve your chances of success.",
  "Use a professional and easy-to-read font for your CV.",
  "Highlight any specialized skills or expertise on your CV.",
  "List any relevant coursework, seminars, or workshops attended on your CV.",
  "Your CV should reflect your professional growth and development.",
  "Incorporate keywords and phrases from the job description on your CV.",
  "Avoid including salary expectations or requirements on your CV.",
  "Include a professional summary or objective statement at the beginning of your CV.",
  "Consider adding a list of references or stating that they are available upon request on your CV.",
  "Proofread your CV for grammatical errors and clarity.",
  "Tailor your CV to the specific job role and industry you're applying to.",
  "Use bullet points to make your CV easy to scan and read.",
  "Highlight any volunteer or community service experience on your CV.",
  "Include a link to your portfolio or work samples if applicable on your CV.",
  "Use a consistent and professional tone throughout your CV."];

export const CV_FACTS_DE = [
  "Ihr Lebenslauf ist der erste Eindruck für Arbeitgeber.",
  "Ihr Lebenslauf sollte höchstens zwei Seiten lang sein.",
  "Ihre Kontaktdaten müssen aktuell und korrekt sein.",
  "Listen Sie Ihre Ausbildung in umgekehrter chronologischer Reihenfolge auf.",
  "Rechtschreibfehler in Ihrem Lebenslauf hinterlassen einen negativen Eindruck.",
  "Fügen Sie Ihre Erfolge und Auszeichnungen hinzu.",
  "Fügen Sie eine kurze Zusammenfassung Ihrer Karriereziele hinzu.",
  "Verwenden Sie ein professionelles Foto in Ihrem Lebenslauf.",
  "Geben Sie Ihre Referenzen in Ihrem Lebenslauf an.",
  "Die Sprache in Ihrem Lebenslauf sollte formal und verständlich sein.",
  "Beschreiben Sie Ihre Berufserfahrung detailliert.",
  "Vermeiden Sie übertriebene Aussagen in Ihrem Lebenslauf.",
  "Ihr Lebenslauf sollte keine Lücken aufweisen.",
  "Aktualisieren Sie Ihren Lebenslauf für jede Bewerbung.",
  "Listen Sie Ihre Computerkenntnisse auf.",
  "Fügen Sie Ihre ehrenamtlichen Tätigkeiten und Praktika hinzu.",
  "Geben Sie Ihre Fremdsprachenkenntnisse an.",
  "Nennen Sie in Ihrem Lebenslauf auch Ihre Hobbys.",
  "Ihr Lebenslauf sollte in einer gut lesbaren Schriftart verfasst sein.",
  "Ihr Lebenslauf sollte eine chronologische Struktur haben.",
  "Fügen Sie relevante Zertifikate hinzu.",
  "Geben Sie professionelle Referenzen an.",
  "Persönliche Angaben in Ihrem Lebenslauf sollten von unnötigen Details befreit sein.",
  "Speichern Sie Ihren Lebenslauf als PDF-Datei.",
  "Senden Sie Ihren Lebenslauf mit einem Anschreiben.",
  "Das Format Ihres Lebenslaufs sollte ordentlich und sauber sein.",
  "Halten Sie Ihren Lebenslauf durch regelmäßige Aktualisierungen aktuell.",
  "Erklären Sie Ihre Verantwortlichkeiten in Ihren Berufserfahrungen.",
  "Heben Sie Ihre Stärken in Ihrem Lebenslauf hervor.",
  "Überprüfen Sie Ihren Lebenslauf, bevor Sie sich bewerben.",
  "Verwenden Sie Schlüsselwörter aus der Stellenanzeige in Ihrem Lebenslauf.",
  "Fügen Sie Ihre professionellen Social-Media-Konten hinzu.",
  "Fügen Sie referenzen hinzu, die zur gewünschten Position passen.",
  "Geben Sie Ihre beruflichen Entwicklungen in Ihrem Lebenslauf an.",
  "Heben Sie Ihre Erfolge in Ihren Berufserfahrungen hervor.",
  "Erstellen Sie einen auffälligen Einstieg in Ihren Lebenslauf.",
  "Vermeiden Sie unnötige Informationen in Ihrem Lebenslauf.",
  "Erstellen Sie einen speziellen Lebenslauf für die gewünschte Position.",
  "Stellen Sie sicher, dass alle Informationen in Ihrem Lebenslauf korrekt sind.",
  "Erklären Sie Ihre Berufserfahrungen klar und verständlich.",
  "Geben Sie die Schulen und Studiengänge an, die Sie absolviert haben.",
  "Speichern Sie Ihren Lebenslauf mit einem aktuellen Datum.",
  "Geben Sie die Daten Ihrer Berufserfahrungen an.",
  "Lassen Sie Ihren Lebenslauf von einem Freund durchsehen.",
  "Überprüfen Sie Ihren Lebenslauf auf Rechtschreibfehler.",
  "Senden Sie Ihren Lebenslauf mit einem Anschreiben.",
  "Passen Sie Ihren Lebenslauf an die Position an, für die Sie sich bewerben.",
  "Ordnen Sie Ihre Berufserfahrungen in umgekehrter chronologischer Reihenfolge.",
  "Stellen Sie sicher, dass die Informationen in Ihrem Lebenslauf aktuell und korrekt sind.",
];

export const CV_FACTS_TR = [
  "CV'niz işverenlerin ilk izlenimidir.",
  "CV'niz en fazla iki sayfa olmalıdır.",
  "İletişim bilgileriniz güncel ve doğru olmalıdır.",
  "Eğitim bilgilerinizi ters kronolojik sırayla listeleyin.",
  "CV'nizdeki yazım hataları olumsuz bir izlenim bırakır.",
  "Başarılarınızı ve ödüllerinizi ekleyin.",
  "Kariyer hedefinizi belirten kısa bir özet ekleyin.",
  "CV'nizde profesyonel bir fotoğraf kullanın.",
  "Referanslarınızı CV'nizde belirtin.",
  "CV'nizde kullanılan dil resmi ve anlaşılır olmalıdır.",
  "İş deneyimlerinizi detaylı bir şekilde açıklayın.",
  "CV'nizde abartılı ifadelerden kaçının.",
  "CV'nizde boşluklar olmamalıdır.",
  "İş başvurunuza özel olarak CV'nizi güncelleyin.",
  "Bilgisayar becerilerinizi listeleyin.",
  "Gönüllü çalışmalarınızı ve stajlarınızı ekleyin.",
  "Yabancı dil bilgilerinizi belirtin.",
  "CV'nizde hobilerinize de yer verin.",
  "CV'niz okunaklı bir yazı tipi ile yazılmalıdır.",
  "CV'nizde kronolojik bir düzen olmalıdır.",
  "İlgili sertifikalarınızı ekleyin.",
  "Profesyonel referanslar sağlayın.",
  "CV'nizde kişisel bilgiler gereksiz detaylardan arındırılmalıdır.",
  "CV'nizi bir PDF dosyası olarak kaydedin.",
  "İş başvurusu yaparken CV'nizi ön yazı ile gönderin.",
  "CV'nizin formatı düzenli ve temiz olmalıdır.",
  "CV'nizi sık sık güncelleyerek güncel tutun.",
  "İş deneyimlerinizdeki sorumluluklarınızı detaylandırın.",
  "CV'nizde güçlü yönlerinizi vurgulayın.",
  "İş başvurusu yapmadan önce CV'nizi gözden geçirin.",
  "CV'nizde iş ilanında belirtilen anahtar kelimelere yer verin.",
  "Profesyonel sosyal medya hesaplarınızı ekleyin.",
  "Başvuracağınız pozisyona uygun referanslar ekleyin.",
  "CV'nizde kariyer gelişimlerinizi belirtin.",
  "İş deneyimlerinizde başarılarınızı vurgulayın.",
  "CV'nizi okuyacak kişinin dikkatini çekecek bir giriş yapın.",
  "CV'nizde gereksiz bilgilerden kaçının.",
  "Başvuracağınız iş için özel bir CV hazırlayın.",
  "CV'nizdeki tüm bilgilerin doğru olduğundan emin olun.",
  "İş deneyimlerinizi net ve anlaşılır bir şekilde açıklayın.",
  "CV'nizde mezun olduğunuz okulları ve bölümleri belirtin.",
  "İş başvurusu yaparken CV'nizi güncel bir tarihle kaydedin.",
  "CV'nizdeki iş deneyimlerinizi tarihleriyle belirtin.",
  "İş başvurusu yapmadan önce CV'nizi bir arkadaşınıza okutun.",
  "CV'nizdeki yazım hatalarını kontrol edin.",
  "İş başvurusu yaparken CV'nizi ön yazı ile birlikte gönderin.",
  "CV'nizi iş başvurusu yapacağınız pozisyona göre özelleştirin.",
  "İş deneyimlerinizi ters kronolojik sırayla sıralayın.",
  "CV'nizdeki bilgilerin güncel ve doğru olduğundan emin olun.",
];
