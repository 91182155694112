import { useI18n } from "vue-i18n";

import type { SectionScore } from "@/composables/types";

export const useEvaluationUtils = () => {
  const { t, locale } = useI18n();

  const getScoreColor = (score: string): string => {
    const percentage = parseInt(score);
    if (percentage >= 0 && percentage < 20) {
      return "#f21d0d";
    } else if (percentage >= 20 && percentage < 40) {
      return "#ff4f17";
    } else if (percentage >= 40 && percentage < 60) {
      return "#ffc107";
    } else if (percentage >= 60 && percentage < 80) {
      return "#67bd6a";
    } else {
      return "#429846";
    }
  };

  const getScoreRange = (score: string): string => {
    const percentage = parseInt(score);
    if (percentage >= 0 && percentage < 20) {
      return t("cvEvaluation.Poor");
    } else if (percentage >= 20 && percentage < 40) {
      return t("cvEvaluation.Fair");
    } else if (percentage >= 40 && percentage < 60) {
      return t("cvEvaluation.Average");
    } else if (percentage >= 60 && percentage < 80) {
      return t("cvEvaluation.Good");
    } else {
      return t("cvEvaluation.Excellent");
    }
  };

  const scoreOutOfHundred = (scoreDetails: SectionScore | undefined) => {

    if(locale.value === "en"){
      switch (scoreDetails?.sectionName) {
      case "Personal Information":
        return parseFloat(scoreDetails?.score) > 10;

      case "Education":
        return parseFloat(scoreDetails?.score) > 15;

      case "Experience":
        return parseFloat(scoreDetails?.score) > 35;

      case "Skills":
        return parseFloat(scoreDetails?.score) > 25;

      case "Additional Information":
        return parseFloat(scoreDetails?.score) > 15;

      default:
        return false;
      }
    }
    if(locale.value === "de"){
      switch (scoreDetails?.sectionName) {
      case "Persönliche Informationen":
        return parseFloat(scoreDetails?.score) > 10;

      case "Ausbildung":
        return parseFloat(scoreDetails?.score) > 15;

      case "Berufserfahrung":
        return parseFloat(scoreDetails?.score) > 35;

      case "Fähigkeiten":
        return parseFloat(scoreDetails?.score) > 25;

      case "Zusätzliche Informationen":
        return parseFloat(scoreDetails?.score) > 15;

      default:
        return false;
      }
    }
    if(locale.value === "tr"){

      switch (scoreDetails?.sectionName) {
      case "Kişisel Bilgiler":
        return parseFloat(scoreDetails?.score) > 10;

      case "Eğitim":
        return parseFloat(scoreDetails?.score) > 15;

      case "Deneyim":
        return parseFloat(scoreDetails?.score) > 35;

      case "Beceriler":
        return parseFloat(scoreDetails?.score) > 25;

      case "Ek Bilgiler":
        return parseFloat(scoreDetails?.score) > 15;

      default:
        return false;
      }
    }
    return false;

  };

  return {
    getScoreColor,
    getScoreRange,
    scoreOutOfHundred,
  };
};
