<script setup lang="ts">
import { ref, computed, watch } from "vue";

import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import AuthModal from "@/components/AuthModal.vue";
import ProcessPayment from "@/components/ProcessPayment.vue";
import SessionExpired from "@/components/SessionExpired.vue";

import HomeCards from "./HomeCards.vue";
import HomeFacts from "./HomeFacts.vue";
import HomeFaq from "./HomeFaq.vue";
import HomeFeedback from "./HomeFeedbacks.vue";
import HomeImage from "./HomeImage.vue";
import HomeInfo from "./HomeInfo.vue";

const { t, locale } = useI18n();

const route = useRoute();
const router = useRouter();

const modalAuthenticationVisible = ref(false);
const authenticationNavigate = ref("");

const showSessionExpiredModal = ref(route.query.sessionExpired === "true");
const processStatus = ref(route.query.process || null);
const showPaymentModal = ref(false);
const startProcessing = ref(false);
const processingFailed = ref(false);
const processingSuccess = ref(false);

watch(
  () => route.query,
  (newQuery) => {
    processStatus.value = newQuery.process || null;
    showSessionExpiredModal.value = newQuery.sessionExpired === "true";

    // Update modal state based on processStatus
    if (processStatus.value === "success") {
      showPaymentModal.value = true;
      startProcessing.value = false;
      processingSuccess.value = true;
      processingFailed.value = false;
    } else if (processStatus.value === "failed") {
      showPaymentModal.value = true;
      startProcessing.value = false;
      processingSuccess.value = false;
      processingFailed.value = true;
    } else {
      showPaymentModal.value = false;
    }
  },
  { immediate: true },
);

const closePaymentModal = () => {
  showPaymentModal.value = false;

  router.replace({
    query: {
      ...route.query,
      process: undefined,
    },
  });
};

const faqsData = computed(() => {
  locale.value;
  return [
    { questionKey: t("faqs.serviceOffered"), answerKey: t("faqs.serviceOfferedAnswer"), showAnswer: false },
    { questionKey: t("faqs.howToUpgrade"), answerKey: t("faqs.howToUpgradeAnswer"), showAnswer: false },
    { questionKey: t("faqs.paymentMethods"), answerKey: t("faqs.paymentMethodsAnswer"), showAnswer: false },
    { questionKey: t("faqs.cancelSubscription"), answerKey: t("faqs.cancelSubscriptionAnswer"), showAnswer: false },
    { questionKey: t("faqs.premiumTemplates"), answerKey: t("faqs.premiumTemplatesAnswer"), showAnswer: false },
  ];
});
const closeModal = () => {
  showSessionExpiredModal.value = false;
};

const login = () => {
  modalAuthenticationVisible.value = true;
};

</script>

<template>
  <div class="container">
    <ProcessPayment
      v-if="showPaymentModal"
      :startProcessing="startProcessing"
      :processingFailed="processingFailed"
      :processingSuccess="processingSuccess"
      @close="closePaymentModal"
    />
    <SessionExpired v-if="showSessionExpiredModal" @closeModal="closeModal" @login="login"/>
    <AuthModal
      :isVisible="modalAuthenticationVisible"
      :navigateTo="authenticationNavigate"
      @update:visible="modalAuthenticationVisible = $event"
    />
    <div>
      <HomeImage />
      <HomeCards/>
      <HomeInfo/>
      <HomeFeedback/>
      <!-- <HomeFacts/> -->
      <HomeFaq :faqs="faqsData"/>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

</style>