import { ref } from "vue";

import type { User as FirebaseUser } from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import type { UserDetails } from "@/composables/types";
import { getUserInfo } from "@/services/userService";

const user = ref({
  authUser: null as FirebaseUser | null,
  details: null as UserDetails | null,
});
const auth = getAuth();

const waitForJWT = () => {
  return new Promise((resolve) => {
    const checkJWT = () => {
      const jwt = localStorage.getItem("jwt");
      if (jwt) {
        resolve(jwt);
      } else {
        setTimeout(checkJWT, 100);
      }
    };
    checkJWT();
  });
};

onAuthStateChanged(auth, async (firebaseUser) => {
  if(!firebaseUser?.emailVerified){
    user.value.authUser = null;
    return;
  }
  user.value.authUser = firebaseUser;
  if (firebaseUser) {
    await waitForJWT();
    const { result: userData } = await getUserInfo(firebaseUser.uid);
    user.value.details = userData;
  }
});

export const useAuth = () => {
  return { user };
};

export const updateUserDetails = (userDetails: UserDetails) => {
  user.value.details = userDetails;
};

export const getCurrentUser = () =>  {
  return new Promise<FirebaseUser | null>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

