import type { SelectedTemplate } from "./types";

export const initiaCvData = {
  contact: {},
  educations: [],
  experiences: [],
  projects: [],
  skills: [],
  certifications: [],
  honors: [],
  languages: [],
  publications: [],
  references: [],
  hobbies: [],
  voluntaries: [],
  exhibitions: [],
  showSkillLevels: true,
  showLanguageLevels: true,
  selectedTemplate: "Default" as SelectedTemplate,
  resumeColor: "grey",
  resumeName: "",
  resumeFontFamily: "Montserrat",
  resumeFontSize: 10,
};

export const analyticsCategories = {
  CV_CREATION: "cv_creation",
  CV_EVALUATION: "cv_evaluation",
  CV_EXISTING: "cv_existing",
  CV_ENHANCE: "cv_enhnace",
  UPGRADE: "upgrade",
  AUTH: "auth",
};