<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { deleteUserAccount } from "@/auth/authService";
import { useAuth } from "@/auth/useAuth";
import { deleteUser } from "@/services/userService";

const { t } = useI18n();
const { user } = useAuth();
const router = useRouter();

const inputValue = ref("");
const deleteSuccess = ref(false);
const showTemporaryMessage = ref(false);
const userMail = user?.value?.details?.email;

const { id } = defineProps<{id: string}>();
const emit = defineEmits(["closeModal"]);

const closeModal = () => {
  emit("closeModal");
};

const confirmDelete = async (): Promise<void> => {
  await deleteUser(id);
  await deleteUserAccount();

  deleteSuccess.value = true;
  showTemporaryMessage.value = true;

  await new Promise<void>((resolve) => {
    setTimeout(() => {
      showTemporaryMessage.value = false;
      resolve();
    }, 10000);
  });

  router.push({ path: "/" });
  closeModal();
};

</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div v-if="!deleteSuccess">
        <button class="close-button" @click="closeModal">X</button>
        <div class="modal-inputs">
          <p>{{ t("DeleteSure") }}</p>
          <input v-model="inputValue" type="text" :placeholder="t('DeleteConfirm')" />
          <button :disabled="inputValue.toLowerCase() !== t('Delete')" @click="confirmDelete">{{ t('Delete') }}</button>
        </div>
      </div>
      <div v-else-if="showTemporaryMessage">
        <div class="process-card">
          <div class="inner-card">
            <svg class="let-go-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none">
              </path>
              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5-1-1h-5l-1 1H5v2h14V4z">
              </path>
            </svg>
          </div>
        </div>
        <div class="process-text">{{ t('Account.SorryToSeeYouGo') }}</div>
        <div class="detail-text">{{ t('Account.DeletedAccountInfo') }}{{ userMail }}</div>
        <div class="loading-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <div class="detail-text">{{ t('Account.RedirectHome') }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal-inputs{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  width: 600px;
  height: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  align-self: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
}

button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.7;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.process-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.let-go-icon {
    color: #e74c3c;
    fill: #e74c3c;
    width: 72px;
    height: 85px;
    z-index: 1;
}
.inner-card{
  width: 137px;
  height: 137px;
  background-color: rgba(0,0,0,0);
  border-radius: 9999px;
  border: 4px solid #e74c3c;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-text {
    color: #030303;
    font-size: 24px;
    font-family: 'Montserrat';
    line-height: 28px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.detail-text {
    color: #909090;
    font-size: 18px;
    font-family: 'Montserrat';
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-dots {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 20px auto;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5438da;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 -20px;
  animation: rotate 1.5s linear infinite;

}

.loading-dots .dot:nth-child(1) {
  transform: rotate(0deg) translateX(20px);
  animation-delay: 0s;
}
.loading-dots .dot:nth-child(2) {
  transform: rotate(45deg) translateX(20px);
  animation-delay: 0.15s;
}
.loading-dots .dot:nth-child(3) {
  transform: rotate(90deg) translateX(20px);
  animation-delay: 0.3s;
}
.loading-dots .dot:nth-child(4) {
  transform: rotate(135deg) translateX(20px);
  animation-delay: 0.45s;
}
.loading-dots .dot:nth-child(5) {
  transform: rotate(180deg) translateX(20px);
  animation-delay: 0.6s;
}
.loading-dots .dot:nth-child(6) {
  transform: rotate(225deg) translateX(20px);
  animation-delay: 0.75s;
}
.loading-dots .dot:nth-child(7) {
  transform: rotate(270deg) translateX(20px);
  animation-delay: 0.9s;
}
.loading-dots .dot:nth-child(8) {
  transform: rotate(315deg) translateX(20px);
  animation-delay: 1.05s;
}

</style>
