<script setup lang="ts">
import { defineProps, computed } from "vue";

import { useI18n } from "vue-i18n";

import { getActualScore } from "@/utils/scoreAdjustments";
const { t, locale } = useI18n();

const props = defineProps<{
    steps: string[];
    currentStep: string;
    evaluated: boolean;
    sectionScores: {
    sectionName: string;
    score: string;
}[]
  }>();

// burdaki computed kodu bozuyo olabilir
const stepMap = computed(() => {
  if (locale.value === "tr") {
    return {
      [t("cvCreationView.Personal")]: "Kişisel Bilgiler",
      [t("cvCreationView.Education")]: "Eğitim",
      [t("cvCreationView.Experiences")]: "Deneyim",
      [t("cvCreationView.Skills")]: "Beceriler",
      [t("cvCreationView.Additional")]: "Ek Bilgiler",
    };
  } else if (locale.value === "de") {
    return {
      [t("cvCreationView.Personal")]: "Persönliche Informationen",
      [t("cvCreationView.Education")]: "Ausbildung",
      [t("cvCreationView.Experiences")]: "Berufserfahrung",
      [t("cvCreationView.Skills")]: "Fähigkeiten",
      [t("cvCreationView.Additional")]: "Zusätzliche Informationen",
    };
  }
  return {
    [t("cvCreationView.Personal")]: "Personal Information",
    [t("cvCreationView.Education")]: "Education",
    [t("cvCreationView.Experiences")]: "Experience",
    [t("cvCreationView.Skills")]: "Skills",
    [t("cvCreationView.Additional")]: "Additional Information",
  };
});

const isStepBeforeCurrent = (index: number) => {
  return props.steps.indexOf(props.currentStep) > index;
};

const isScoreLow = (sectionName: string) => {
  const section = props.sectionScores.find(section => section.sectionName === sectionName);
  if (!section || typeof section.score === "undefined") {
    return false;
  }
  const score = parseFloat(section.score);
  const actualScore = getActualScore(score, sectionName, locale.value);
  return actualScore !== null && parseFloat(actualScore) < 60;
};

</script>

<template>
  <div class="progress-steps">
    <ul class="step-indicator">
      <li v-for="(step, index) in steps" :key="index" :class="{ 'active': currentStep === step, 'before': isStepBeforeCurrent(index) }" @click="$emit('update:currentStep', step)">
        <span :class="(isScoreLow(stepMap[step]) && evaluated ? 'excl' : 'excl-hidden')">!</span>
        <div class="step">
          <div class="step-number">{{ index + 1 }}</div>
        </div>
        <div class="step-label">{{ step }}</div>
        <div v-if="index !== 0" class="step-line" :class="{ 'before-line': isStepBeforeCurrent(index - 1) }"></div>
      </li>
    </ul>
  </div>
</template>

<style>

.excl{
color: #f21d0d;
position: relative;
font-weight: bold;
}

.excl-hidden{
  visibility: hidden;
}

.progress-steps{
    width: 100%;
}
.step-indicator {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.step-indicator li {
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.step-indicator li::before {

  content: '';
  position: absolute;
  top: 65%;
  left: 0;
  transform: translate(-50%, -500%);
  width: 100%;
  height: 2px;
  background-color: #f2f2f2;
  z-index: -1;
}

.step-indicator li::after {

  content: '';
  position: absolute;
  top: 65%;
  right: 0;
  transform: translate(50%, -500%);
  width: 100%;
  height: 2px;
  background-color: #f2f2f2;
  z-index: -1;
}

.before .step {
  background-color: rgb(95, 82, 210)}

.before .step-number {
  color: white;
}

.before-line {
  background-color: rgb(59, 43, 207);
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ccc;
  margin: auto;
}

.step-number {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  justify-self: center;
}

.step-label {
  display: block;
  font-size: 0.8em;
  font-weight: bold;
}

.active .step {
  background-image: radial-gradient(
    circle at center,
    #aea8f9 40%,
    rgba(113, 89, 224,0.2) 60%
  );
}

</style>

